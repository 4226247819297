import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiRequest } from "../../utils/request";

const token = sessionStorage.getItem("tID");

export const fetchCatagories = createAsyncThunk("user/fetchCatagories", () => {
  return apiRequest
    .get(`/gallery_category_api/get_gallery_category`, {
      headers: {
        "Content-Type": "application/json",
        get_gallery_category_api:
          process.env.REACT_APP_GET_GALLERY_CATEGORY_API,
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      if (error.response.status === 401) {
        sessionStorage.clear();
        window.location.href = "/login";
      }
    });
});

const initialState = {
  category: process.env.REACT_APP_ALL_CATEGORY,
  categories: [],
};

const categorySlice = createSlice({
  name: "Category",
  initialState,
  reducers: {
    setCategory: (state, action) => {
      state.category = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCatagories.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchCatagories.fulfilled, (state, action) => {
      state.loading = false;
      state.categories = action.payload;
      state.error = false;
    });
    builder.addCase(fetchCatagories.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const categoryAction = categorySlice.actions;

export default categorySlice;
