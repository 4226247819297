import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequest } from "../../utils/request";

const token = sessionStorage?.getItem("tID");

export const fetchReservations = createAsyncThunk(
  "reservation/fetchReservation",
  async ({
    page,
    customer_name,
    customer_phone,
    reservation_date,
    status,
    sort,
  }) => {
    return apiRequest
      .get(
        `/reservation_api/get_reservation?page=${page}&customer_name=${customer_name}&customer_phone=${customer_phone}&reservation_date=${reservation_date}&status=${status}&sort=${sort}`,
        {
          headers: {
            "Content-Type": "application/json",
            get_reservation_api: process.env.REACT_APP_GET_RESERVATION_API,
            Authorization: `Token ${token}`,
          },
        }
      )
      .then((res) => res?.data)
      .catch((error) => {
        if (error.response.status === 401) {
          sessionStorage.clear();
          window.location.href = "/login";
        }
      });
  }
);

const initialState = {
  reservations: [],
  rejectReservation: false,
  reservationID: null,
  loading: false,
  error: false,
};

const reservationSlice = createSlice({
  name: "RESERVATIONS",
  initialState,
  reducers: {
    setRejectReservation: (state, action) => {
      state.rejectReservation = action.payload.reservationModal;
      state.reservationID = action.payload.reservationID;
    },
    // setReservationID:
  },
  extraReducers: (builder) => {
    builder.addCase(fetchReservations.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchReservations.fulfilled, (state, action) => {
      state.loading = false;
      state.reservations = action.payload;
      state.error = false;
    });
    builder.addCase(fetchReservations.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const reservationAction = reservationSlice.actions;

export default reservationSlice;
