import React from "react";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
function AddServiceLevel() {
  const navigate = useNavigate();
  return (
    <div className="w-[90%] my-[20px] p-4 mx-auto bg-white flex flex-col gap-[20px] rounded-[10px] shadow-lg">
      <div className="w-[95%] mx-auto px-8 my-[20px] sm:px-0 flex items-center gap-[10px]">
        <IoArrowBack
          className="text-[24px] text-[#039674]  cursor-pointer"
          onClick={() => navigate(-1)}
        />
        <span className="text-base font-bold leading-7 text-[#039674]">
          Enter level information
        </span>
      </div>
      <div className="w-[95%] my-[20px] mx-auto flex flex-col gap-[20px]">
        <div className="my-[20px] flex flex-col gap-[10px]">
          <span className="font-bold text-[14px] text-[#039674]">
            Level Number <span className="text-red-600">*</span>
          </span>
          <input
            type="number"
            min={1}
            className="py-3 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px]"
          />
        </div>

        <div className="my-[20px] flex flex-col gap-[10px]">
          <span className="font-bold text-[14px] text-[#039674]">
            Level Menu (english)
          </span>
          <input
            type="text"
            className="py-3 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px]"
          />
        </div>

        <div className="my-[20px] flex flex-col gap-[10px]">
          <span className="font-bold text-[14px] text-[#039674]">
            Level Menu (amharic)
          </span>
          <input
            type="text"
            className="py-3 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px]"
          />
        </div>
        <div className="my-[20px] flex flex-col gap-[10px]">
          <span className="font-bold text-[14px] text-[#039674]">
            Additional Package (english)
          </span>
          <textarea
            rows={5}
            className="w-[50%] py-2 px-4 border border-gray-300 rounded-[5px] outline-none resize-none text-[14px]"
          ></textarea>
        </div>
        <div className="my-[20px] flex flex-col gap-[10px]">
          <span className="font-bold text-[14px] text-[#039674]">
            Additional Package (amharic)
          </span>
          <textarea
            rows={5}
            className="w-[50%] py-2 px-4 border border-gray-300 rounded-[5px] outline-none resize-none text-[14px]"
          ></textarea>
        </div>
      </div>

      <div className="w-[100%] flex justify-end items-center gap-[20px]">
        <button onClick={() => navigate(-1)} className="text-[14px]">
          Cancel
        </button>
        <button className="py-2 px-4 bg-[#039674] text-white flex items-center gap-[5px] rounded-[5px] text-[14px]">
          <span className="text-[14px]">Add Level</span>
        </button>
      </div>
    </div>
  );
}

export default AddServiceLevel;
