import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequest } from "../../utils/request";

const token = sessionStorage.getItem("tID");

export const fetchMenus = createAsyncThunk("menu/fetchMenus", () => {
  return apiRequest
    .get(`/menu_api/get_menu`, {
      headers: {
        "Content-Type": "application/json",
        get_menu_api: process.env.REACT_APP_GET_MENU_API,
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      if (error.response.status === 401) {
        sessionStorage.clear();
        window.location.href = "/login";
      }
    });
});

const initialState = {
  menus: [],
  loading: false,
  error: false,
};

const menuSlice = createSlice({
  name: "MENU",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchMenus.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchMenus.fulfilled, (state, action) => {
      state.loading = false;
      state.menus = action.payload;
      state.error = false;
    });
    builder.addCase(fetchMenus.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const menuAction = menuSlice.actions;

export default menuSlice;
