import React, { useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { apiRequest } from "../../utils/request";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ServerError from "../../pages/ServerError";
import Loading from "../Loading";

function EditMenu() {
  const location = useLocation();
  const { menu } = location.state;
  const token = sessionStorage.getItem("tID");
  const { id } = useParams();
  const navigate = useNavigate();
  const [titleEn, setTitleEn] = useState(menu ? menu?.name_en : "");
  const [titleAmh, setTitleAmh] = useState(menu ? menu?.name_am : "");
  const [descriptionEn, setDescriptionEn] = useState(
    menu ? menu?.description_en : ""
  );
  const [descriptionAmh, setDescriptionAmh] = useState(
    menu ? menu?.description_am : ""
  );
  const [menuImage, setMenuImage] = useState(null);
  const [popularMenu, setPopularMenu] = useState(
    menu ? menu?.popular_menu : ""
  );
  const [status, setStatus] = useState(menu ? menu?.status : "");
  const [editFiled, setEditField] = useState("");
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(false);

  const handleEditMenu = async () => {
    try {
      const menuData = new FormData();
      menuData.append("name_en", titleEn);
      menuData.append("name_am", titleAmh);
      menuData.append("description_am", descriptionAmh);
      menuData.append("description_en", descriptionEn);
      menuData.append("menu_image", menuImage);
      menuData.append("popular_menu", popularMenu);
      menuData.append("status", status);

      setLoading(true);

      await apiRequest
        .put(
          `${process.env.REACT_APP_BACKEND_URL}/menu_api/update_menu/${id}`,
          menuData,
          {
            headers: {
              get_update_menu_api: process.env.REACT_APP_GET_UPDATE_MENU_API,
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          window.location.href = "/menu";
          setLoading(false);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setLoading(true);
            sessionStorage.clear();
            window.location.href = "/login";
          }
          setLoading(false);
          toast.error(error.response.data.Messge);
        });
    } catch (error) {
      setServerError(true);
    }
  };

  if (serverError) return <ServerError />;

  return (
    <div className="w-[90%] my-[20px] mx-auto p-2">
      <ToastContainer theme="light" />
      <div className="w-[100%]  p-2">
        <div className="w-[100%] p-2 mx-auto bg-white shadow-lg rounded-[20px] ">
          <div className="w-[90%] mt-[50px] mx-auto flex justify-between items-center max-md1:flex-col max-md1:items-start max-md1:gap-[20px]">
            <div>
              <span className="w-[50%] text-[20px] text-[#039674] font-bold">
                Update Menu
              </span>
            </div>

            {loading ? (
              <Loading
                additionalStyle={"my-[20px] flex justify-center items-center"}
              />
            ) : (
              <div className="w-[50%] flex items-center justify-end max-md1:justify-start gap-[20px]">
                <button className="text-[14px]" onClick={() => navigate(-1)}>
                  Cancel
                </button>
                <button
                  onClick={handleEditMenu}
                  className="bg-[#039674] text-white py-1 px-4 text-[14px] rounded-[5px]"
                >
                  Update
                </button>
              </div>
            )}
          </div>
          <div>
            <div className="w-[100%] mx-auto p-2 min-h-[70vh]  rounded-[10px]">
              <div className="w-[95%] flex flex-col justify-between h-[100%]  mx-auto">
                <div className="w-[50%] mt-[30px]  flex flex-col gap-[10px] max-md1:w-[100%]">
                  <span className="font-bold text-[#039674]">
                    Which filed do you want update?
                  </span>
                  <select
                    onChange={(e) => setEditField(e.target.value)}
                    className="py-3 px-4 border border-gray-400 bg-transparent rounded-[5px] text-[14px] outline-none"
                  >
                    <option>Select field to edit?</option>
                    <option>English name</option>
                    <option>Amharic name</option>
                    <option>English description</option>
                    <option>Amharic description</option>
                    <option>Popular Menu</option>
                    <option>Menu Image</option>
                    <option>Status</option>
                  </select>
                </div>
                {editFiled === "English name" && (
                  <div className="w-[50%] my-[30px]  flex flex-col gap-[10px] max-md1:w-[100%]">
                    <span className="font-bold text-[#039674] text-[14px]">
                      English Name
                    </span>
                    <input
                      value={titleEn}
                      onChange={(e) => setTitleEn(e.target.value)}
                      type="text"
                      className="py-3 px-4 border border-gray-400 rounded-[5px]  text-[14px] outline-none"
                    />
                  </div>
                )}
                {editFiled === "Amharic name" && (
                  <div className="w-[50%] my-[30px]  flex flex-col gap-[10px]  max-md1:w-[100%]">
                    <span className="font-bold text-[#039674] text-[14px]">
                      Amharic Name
                    </span>
                    <input
                      value={titleAmh}
                      onChange={(e) => setTitleAmh(e.target.value)}
                      type="text"
                      className="py-3 px-4 border border-gray-400 rounded-[5px]  text-[14px] outline-none"
                    />
                  </div>
                )}
                {editFiled === "English description" && (
                  <div className="w-[50%] my-[30px]  flex flex-col gap-[10px]  max-md1:w-[100%]">
                    <span className="font-bold text-[#039674] text-[14px]">
                      English description
                    </span>
                    <textarea
                      value={descriptionEn}
                      onChange={(e) => setDescriptionEn(e.target.value)}
                      className="py-3 px-4 border border-gray-400 rounded-[5px]  text-[14px] outline-none resize-none"
                      rows={10}
                    ></textarea>
                  </div>
                )}
                {editFiled === "Amharic description" && (
                  <div className="w-[50%]  my-[30px]  flex flex-col gap-[10px]  max-md1:w-[100%]">
                    <span className="font-bold text-[#039674] text-[14px]">
                      Amharic description
                    </span>
                    <textarea
                      value={descriptionAmh}
                      onChange={(e) => setDescriptionAmh(e.target.value)}
                      className="py-3 px-4 border border-gray-400 rounded-[5px]  text-[14px] outline-none resize-none"
                      rows={10}
                    ></textarea>
                  </div>
                )}
                {editFiled === "Popular Menu" && (
                  <div className="w-[50%]  my-[30px] mt-[30px]  flex flex-col gap-[10px]  max-md1:w-[100%]">
                    <span className="font-bold text-[#039674] text-[14px]">
                      Poplular Menu
                    </span>
                    <select
                      value={popularMenu}
                      onChange={(e) => setPopularMenu(e?.target?.value)}
                      className="py-3 px-4 border border-gray-400 rounded-[5px] bg-transparent  text-[14px] outline-none"
                    >
                      <option>Make Popular</option>
                      <option value={"yes"}>Yes</option>
                      <option value={"no"}>No</option>
                    </select>
                  </div>
                )}

                {editFiled === "Menu Image" && (
                  <div className="w-[100%] my-[30px]  mx-auto flex justify-between items-center gap-[20px] max-md2:flex-col">
                    <div className="w-[50%] mx-auto flex flex-col gap-[10px] max-md2:w-[100%]">
                      <span className="font-bold text-[14px] text-[#039674]">
                        Menu Image
                      </span>
                      <label>
                        <input
                          type="file"
                          accept="image/png, image/jpeg"
                          onChange={(e) => setMenuImage(e?.target?.files?.[0])}
                          hidden
                        />
                        <div className="w-[100%] min-h-[300px] flex flex-col justify-center gap-[10px] text-[#039674]  items-center p-4 mx-auto border border-gray-400 rounded border-dashed cursor-pointer text-center">
                          <div className="flex items-center gap-[10px]">
                            <span className="text-[14px] font-bold ">
                              Upload File
                            </span>
                          </div>

                          <span className="w-[80%] text-[12px] max-lg:w-[70%]">
                            Please ensure images are of high resolution in
                            format jpg, or png. Only upload media that you have
                            the legal rights to.
                          </span>
                          <span className="w-[50%] text-[12px] max-lg:w-[70%]">
                            File size max <b>2MB</b>
                          </span>
                        </div>
                      </label>
                    </div>
                    <div className="w-[50%] mt-[20px] mx-auto flex gap-[20px]   max-md2:w-[100%]">
                      {menuImage && (
                        <img
                          className="w-[100%] h-[300px] object-contain"
                          src={URL.createObjectURL(menuImage)}
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                )}

                {editFiled === "Status" && (
                  <div className="w-[50%]  my-[30px] mt-[30px]  flex flex-col gap-[10px] max-md1:w-[100%]">
                    <span className="font-bold text-[#039674] text-[14px]">
                      Status
                    </span>
                    <select
                      value={status}
                      onChange={(e) => setStatus(e?.target?.value)}
                      className="py-3 px-4 border border-gray-400 rounded-[5px] bg-transparent  text-[14px] outline-none"
                    >
                      <option>Change status</option>
                      <option>active</option>
                      <option>inactive</option>
                    </select>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditMenu;
