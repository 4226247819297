import React, { useEffect, useState } from "react";
import { IoIosSearch } from "react-icons/io";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ServerError from "../../pages/ServerError";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers } from "../../REDUX/Slices/userSlice";
import { MdEmail } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Loading from "../Loading";

function UserTable() {
  const navigate = useNavigate();
  const userID = sessionStorage.getItem("uID");
  const dispatch = useDispatch();
  const userList = useSelector((state) => state.user);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");

  useEffect(() => {
    dispatch(fetchUsers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const findUser = userList?.users?.find((user) => user?._id === userID);
  const filterUsers = userList?.users?.filter((user) => {
    return (
      user?.username.toLowerCase()?.includes(username?.toLowerCase()) &&
      user?.email?.toLowerCase()?.includes(email.toLowerCase()) &&
      user?.status?.includes(status)
    );
  });

  if (userList?.error) return <ServerError />;
  return (
    <div className="w-[100%] max-h-[700px] overflow-y-auto hide-scroll-bar p-2 my-[30px] bg-white rounded-[10px] shadow-md">
      <ToastContainer theme="light" />
      <div className="w-[90%] my-[20px] mx-auto flex justify-between items-center">
        <div className="w-[100%] flex items-center max-md1:flex-wrap gap-[10px] max-md2:w-[100%]">
          <div className="w-[100%] flex px-4 items-center gap-[10px] border border-gray-300 rounded-[5px]">
            <IoIosSearch className="text-[24px] text-gray-500  max-lg2:text-[14px]" />
            <input
              type="text"
              onChange={(e) => setUsername(e.target.value)}
              placeholder="Search by username..."
              className="py-3 flex-1 text-[14px] outline-none  max-lg2:text-[12px]"
            />
          </div>
          <div className="w-[100%] flex px-4 items-center gap-[10px] border border-gray-300 rounded-[5px]">
            <MdEmail className="text-[18px] text-gray-500  max-lg2:text-[14px]" />
            <input
              type="text"
              placeholder="Search by email"
              onChange={(e) => setEmail(e.target.value)}
              className="py-3 flex-1 text-[14px] outline-none  max-lg2:text-[12px]"
            />
          </div>
          <div className="w-[100%] flex px-4 items-center gap-[10px] border border-gray-300 rounded-[5px]">
            <span className="hidden text-[12px] text-gray-500 max-md1:block">
              Search by status:
            </span>
            <select
              onChange={(e) => setStatus(e.target.value)}
              placeholder="Search by date"
              className="py-3 flex-1 text-[14px] outline-none  max-lg2:text-[12px] bg-transparent"
            >
              <option></option>
              <option value={"ac"}>Active</option>
              <option value={"in"}>Inactive</option>
            </select>
          </div>
        </div>
      </div>

      <div className="my-[30px] relative overflow-x-auto hide-scroll-bar">
        {userList?.loading ? (
          <Loading
            additionalStyle={"my-[30px] flex justify-center items-center"}
          />
        ) : username === "" && email === "" && status === "" ? (
          <table className="w-full  text-left rtl:text-right text-gray-500">
            <thead className="text-[14px] text-gray-700  border-b sticky top-0 z-10 bg-white h-[60px] max-lg2:text-[12px]">
              <tr>
                <th scope="col" className="px-6 py-3 whitespace-nowrap">
                  #
                </th>
                <th scope="col" className="px-6 py-3 whitespace-nowrap">
                  Profile Picture
                </th>
                <th scope="col" className="px-6 py-3 whitespace-nowrap">
                  Full Name
                </th>
                <th scope="col" className="px-6 py-3 whitespace-nowrap">
                  Username
                </th>
                <th scope="col" className="px-6 py-3 whitespace-nowrap">
                  Email
                </th>
                <th scope="col" className="px-6 py-3 whitespace-nowrap">
                  Phone Number
                </th>

                <th scope="col" className="px-6 py-3 whitespace-nowrap">
                  Role
                </th>

                <th scope="col" className="px-6 py-3 whitespace-nowrap">
                  Status
                </th>
                {findUser?.role === "Super_Admin" && (
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Action
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {userList?.users?.map((user, index) => {
                return (
                  <tr
                    className="border-b text-[12px]  whitespace-nowrap  max-[1500px]:text-[10px]"
                    key={index}
                  >
                    <td className="px-6 py-4 whitespace-nowrap">{index + 1}</td>
                    <td className="px-6 py-4 whitespace-nowrap flex justify-center items-center">
                      <div className="w-[30px] h-[30px] bg-black rounded-full max-[1500px]:w-[30px] max-[1500px]:h-[30px]">
                        <img
                          src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}/UserImages/${user?.picture}`}
                          className="w-[100%] h-[100%] object-cover rounded-full"
                          alt=""
                        />
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {user?.full_name}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {user?.username}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {user?.email}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {user?.phone}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      {user?.role === "Super_Admin" ? "Super Admin" : "Admin"}
                    </td>

                    <td className="px-6 py-4 whitespace-nowrap">
                      {user?.status === "active" ? (
                        <span className="p-2 rounded-[5px] bg-[#039674] text-white">
                          Active
                        </span>
                      ) : (
                        <span className="p-2 rounded-[5px] bg-red-600 text-white">
                          Inactive
                        </span>
                      )}
                    </td>

                    {findUser?.role === "Super_Admin" && (
                      <td className="px-6 py-4 whitespace-nowrap">
                        <button
                          onClick={() =>
                            navigate(`/users/edit-user/${user?._id}`, {
                              state: {
                                user: user,
                              },
                            })
                          }
                          className="underline"
                        >
                          Edit
                        </button>
                      </td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        ) : filterUsers?.length === 0 ? (
          <div className="w-[90%] mx-auto my-5 flex justify-center items-center">
            <span className="text-[20px] text-gray-500 font-medium">
              No result found
            </span>
          </div>
        ) : (
          <table className="w-full  text-left rtl:text-right text-gray-500">
            <thead className="text-[14px] text-gray-700  border-b sticky top-0 z-10 bg-white h-[60px] max-lg2:text-[12px]">
              <tr>
                <th scope="col" className="px-6 py-3 whitespace-nowrap">
                  #
                </th>
                <th scope="col" className="px-6 py-3 whitespace-nowrap">
                  Profile Picture
                </th>
                <th scope="col" className="px-6 py-3 whitespace-nowrap">
                  Full Name
                </th>
                <th scope="col" className="px-6 py-3 whitespace-nowrap">
                  Username
                </th>
                <th scope="col" className="px-6 py-3 whitespace-nowrap">
                  Email
                </th>
                <th scope="col" className="px-6 py-3 whitespace-nowrap">
                  Phone Number
                </th>

                <th scope="col" className="px-6 py-3 whitespace-nowrap">
                  Role
                </th>

                <th scope="col" className="px-6 py-3 whitespace-nowrap">
                  Status
                </th>
                {findUser?.role === "Super_Admin" && (
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Action
                  </th>
                )}
              </tr>
            </thead>
            <tbody>
              {filterUsers?.length === 0
                ? userList?.users?.map((user, index) => {
                    return (
                      <tr
                        className="border-b text-[12px]  whitespace-nowrap  max-[1500px]:text-[10px]"
                        key={index}
                      >
                        <td className="px-6 py-4 whitespace-nowrap">
                          {index + 1}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap flex justify-center items-center">
                          <div className="w-[30px] h-[30px] bg-black rounded-full max-[1500px]:w-[30px] max-[1500px]:h-[30px]">
                            <img
                              src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}/UserImages/${user?.picture}`}
                              className="w-[100%] h-[100%] object-cover rounded-full"
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {user?.full_name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {user?.username}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {user?.email}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {user?.phone}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {user?.role === "Super_Admin"
                            ? "Super Admin"
                            : "Admin"}
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          {user?.status === "active" ? (
                            <span className="p-2 rounded-[5px] bg-[#039674] text-white">
                              Active
                            </span>
                          ) : (
                            <span className="p-2 rounded-[5px] bg-red-600 text-white">
                              Inactive
                            </span>
                          )}
                        </td>

                        {findUser?.role === "Super_Admin" && (
                          <td className="px-6 py-4 whitespace-nowrap">
                            <button
                              onClick={() =>
                                navigate(`/users/edit-user/${user?._id}`, {
                                  state: {
                                    user: user,
                                  },
                                })
                              }
                              className="underline"
                            >
                              Edit
                            </button>
                          </td>
                        )}
                      </tr>
                    );
                  })
                : filterUsers?.map((user, index) => {
                    return (
                      <tr
                        className="border-b text-[12px]  whitespace-nowrap  max-[1500px]:text-[10px]"
                        key={index}
                      >
                        <td className="px-6 py-4 whitespace-nowrap">
                          {index + 1}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap flex justify-center items-center">
                          <div className="w-[30px] h-[30px] bg-black rounded-full max-[1500px]:w-[30px] max-[1500px]:h-[30px]">
                            <img
                              src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}/UserImages/${user?.picture}`}
                              className="w-[100%] h-[100%] object-cover rounded-full"
                              alt=""
                            />
                          </div>
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {user?.full_name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {user?.username}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {user?.email}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {user?.phone}
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          {user?.role === "Super_Admin"
                            ? "Super Admin"
                            : "Admin"}
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          {user?.status === "active" ? (
                            <span className="p-2 rounded-[5px] bg-[#039674] text-white">
                              Active
                            </span>
                          ) : (
                            <span className="p-2 rounded-[5px] bg-red-600 text-white">
                              Inactive
                            </span>
                          )}
                        </td>

                        {findUser?.role === "Super_Admin" && (
                          <td className="px-6 py-4 whitespace-nowrap">
                            <button
                              onClick={() =>
                                navigate(`/users/edit-user/${user?._id}`, {
                                  state: {
                                    user: user,
                                  },
                                })
                              }
                              className="underline"
                            >
                              Edit
                            </button>
                          </td>
                        )}
                      </tr>
                    );
                  })}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
}

export default UserTable;
