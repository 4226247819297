import React, { useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { apiRequest } from "../../utils/request";
import ServerError from "../../pages/ServerError";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function EditUser() {
  const location = useLocation();
  const { user } = location.state;
  const token = sessionStorage.getItem("tID");
  const { id } = useParams();
  const navigate = useNavigate();
  const [editFiled, setEditField] = useState("");
  const [full_name, setFullname] = useState(user ? user?.full_name : "");
  const [username, setUsername] = useState(user ? user?.username : "");
  const [email, setEmail] = useState(user ? user?.email : "");
  const [phoneNumber, setPhoneNumber] = useState(user ? user?.phone : "");
  const [profilePicure, setProfilePicture] = useState(null);
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(false);

  const handleEditUser = async () => {
    try {
      const userData = new FormData();
      userData.append("full_name", full_name);
      userData.append("username", username);
      userData.append("email", email);
      userData.append("phone", phoneNumber);
      userData.append("status", status);
      userData.append("picture", profilePicure);

      setLoading(true);

      await apiRequest
        .put(
          `${process.env.REACT_APP_BACKEND_URL}/user_api/update_user/${id}`,
          userData,
          {
            headers: {
              get_detail_users_api: process.env.REACT_APP_GET_DETAIL_USERS_API,
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          setLoading(false);
          window.location.href = "/users";
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setLoading(true);
            sessionStorage.clear();
            window.location.href = "/login";
          }
          setLoading(false);

          toast.error(error.response.data.Message);
        });
    } catch (error) {
      setLoading(false);
      setServerError(true);
    }
  };

  if (serverError) return <ServerError />;

  return (
    <div className="w-[90%] my-[20px] mx-auto p-2">
      <ToastContainer theme="light" />
      <div className="w-[100%]  p-2">
        <div className="w-[100%] p-2 mx-auto bg-white shadow-lg rounded-[20px] ">
          <div className="w-[90%] mt-[50px] mx-auto flex justify-between items-center max-md1:flex-col max-md1:items-start max-md1:gap-[20px]">
            <div>
              <span className="text-[20px] text-[#039674] font-bold">
                Update user information
              </span>
            </div>
            {loading ? (
              <div role="status">
                <svg
                  aria-hidden="true"
                  className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-[#039674]"
                  viewBox="0 0 100 101"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                    fill="currentColor"
                  />
                  <path
                    d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                    fill="currentFill"
                  />
                </svg>
                <span className="sr-only">Loading...</span>
              </div>
            ) : (
              <div className="flex items-center gap-[20px] justify-end">
                <button className="text-[14px]" onClick={() => navigate(-1)}>
                  Cancel
                </button>
                <button
                  onClick={handleEditUser}
                  className="bg-[#039674] text-white py-1 px-4 text-[14px] rounded-[5px]"
                >
                  Update
                </button>
              </div>
            )}
          </div>
          <div>
            <div className="w-[100%] mx-auto p-2 min-h-[70vh]  rounded-[10px]">
              <div className="w-[95%] flex flex-col justify-between h-[100%]  mx-auto">
                <div className="w-[50%] mt-[30px]  flex flex-col gap-[10px] max-md1:w-[100%]">
                  <span className="font-bold text-[#039674]">
                    Which filed do you want update?
                  </span>
                  <select
                    onChange={(e) => setEditField(e.target.value)}
                    className="py-3 px-4 border border-gray-400 rounded-[5px] bg-transparent text-[14px] outline-none"
                  >
                    <option>Select field to edit</option>
                    <option>Full Name</option>
                    <option>Username</option>
                    <option>Email</option>
                    <option>Phone Number</option>

                    <option>Profile Picture</option>
                    <option>Status</option>
                  </select>
                </div>
                {editFiled === "Full Name" && (
                  <div className="w-[50%] my-[30px]  flex flex-col gap-[10px] max-md1:w-[100%]">
                    <span className="font-bold text-[#039674] text-[14px]">
                      Full Name
                    </span>
                    <input
                      value={full_name}
                      onChange={(e) => setFullname(e.target.value)}
                      type="text"
                      className="py-3 px-4 border border-gray-400 rounded-[5px]  text-[14px] outline-none"
                    />
                  </div>
                )}
                {editFiled === "Username" && (
                  <div className="w-[50%] my-[30px] flex flex-col gap-[10px] max-md1:w-[100%]">
                    <span className="font-bold text-[#039674] text-[14px]">
                      Username
                    </span>
                    <input
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                      type="text"
                      className="py-3 px-4 border border-gray-400 rounded-[5px]  text-[14px] outline-none"
                    />
                  </div>
                )}
                {editFiled === "Email" && (
                  <div className="w-[50%] my-[30px] flex flex-col gap-[10px] max-md1:w-[100%]">
                    <span className="font-bold text-[#039674] text-[14px]">
                      Email
                    </span>
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      className="py-3 px-4 border border-gray-400 rounded-[5px]  text-[14px] outline-none"
                    />
                  </div>
                )}
                {editFiled === "Phone Number" && (
                  <div className="w-[50%]  my-[30px]  flex flex-col gap-[10px] max-md1:w-[100%]">
                    <span className="font-bold text-[#039674] text-[14px]">
                      Phone Number
                    </span>
                    <input
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      className="py-3 px-4 border border-gray-400 rounded-[5px]  text-[14px] outline-none resize-none"
                    />
                  </div>
                )}

                {editFiled === "Profile Picture" && (
                  <div className="w-[100%] my-[30px]  mx-auto flex justify-between items-center gap-[20px] max-md2:flex-col">
                    <div className="w-[100%] mx-auto flex flex-col gap-[10px] max-md1:w-[100%]">
                      <span className="font-bold text-[14px] text-[#039674]">
                        Profile Picure
                      </span>
                      <label>
                        <input
                          type="file"
                          accept="image/png, image/jpeg"
                          onChange={(e) => setProfilePicture(e.target.files[0])}
                          hidden
                        />
                        <div className="[w-[100%] min-h-[300px] flex flex-col justify-center gap-[10px] text-[#039674]  items-center p-4 mx-auto border border-gray-400 rounded border-dashed cursor-pointer text-center">
                          <div className="flex items-center gap-[10px]">
                            <span className="text-[14px] font-bold ">
                              Upload File
                            </span>
                          </div>

                          <span className="w-[50%] text-[12px] max-lg:w-[70%]">
                            Please ensure images are of high resolution in
                            format jpg, or png. Only upload media that you have
                            the legal rights to.
                          </span>
                          <span className="w-[50%] text-[12px] max-[1000px]:w-[70%]">
                            File size max <b>2MB</b>
                          </span>
                        </div>
                      </label>
                    </div>
                    <div className="w-[50%] mt-[20px] mx-auto flex gap-[20px] max-md2:w-[100%]">
                      {profilePicure && (
                        <img
                          className="w-[100%] h-[300px] object-cover"
                          src={URL.createObjectURL(profilePicure)}
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                )}

                {editFiled === "Status" && (
                  <div className="w-[50%]  my-[30px] mt-[30px]  flex flex-col gap-[10px] max-md1:w-[100%]">
                    <span className="font-bold text-[#039674] text-[14px]">
                      Status
                    </span>
                    <select
                      onChange={(e) => setStatus(e.target.value)}
                      className="py-3 px-4 border border-gray-400 rounded-[5px]  text-[14px] outline-none"
                    >
                      <option>Change status</option>
                      <option>active</option>
                      <option>inactive</option>
                    </select>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditUser;
