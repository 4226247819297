import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ServerError from "../../pages/ServerError";
import { apiRequest } from "../../utils/request";
import { useDispatch, useSelector } from "react-redux";
import { fetchServices } from "../../REDUX/Slices/serviceSlice";
import { reservationAction } from "../../REDUX/Slices/reservationSlice";
import ReservationJustification from "./ReservationJustification";

function EditReservation() {
  const location = useLocation();
  const { reservation } = location.state;
  const navigate = useNavigate();
  const { id } = useParams();
  const token = sessionStorage.getItem("tID");
  const dispatch = useDispatch();
  const reservationState = useSelector((state) => state?.reservations);
  const serviceState = useSelector((state) => state?.services);
  const [editFiled, setEditField] = useState("");
  const [name, setName] = useState(reservation ? reservation?.name : "");
  const [email, setEmail] = useState(reservation ? reservation?.email : "");
  const [categoryName, setCategory] = useState(
    reservation ? reservation?.category : ""
  );
  const [primaryPhone, setPrimaryPhone] = useState(
    reservation ? reservation?.primary_phone : ""
  );
  const [secondaryPhone, setSecondaryPhone] = useState(
    reservation ? reservation?.secondary_phone : ""
  );
  const [selectedPackage, setSelectedPackage] = useState(
    reservation ? reservation?.package : ""
  );
  const [guests, setGuests] = useState(
    reservation ? reservation?.num_of_ppls : ""
  );

  const [reservationDate, setReservationDate] = useState("");
  const [reservationTime, setReservationTime] = useState("");
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(false);

  useEffect(() => {
    dispatch(fetchServices());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const editReservation = async () => {
    try {
      setLoading(true);

      await apiRequest
        .put(
          `/reservation_api/update_reservation/${id}`,
          {
            category: categoryName,
            name,
            email,
            categoryName,
            primary_phone: primaryPhone,
            secondary_phone: secondaryPhone,
            package: selectedPackage,
            num_of_ppls: guests,
            reservation_date: reservationDate,
            reservation_time: reservationTime,
            status,
          },
          {
            headers: {
              get_update_reservation_api:
                process.env.REACT_APP_GET_UPDATE_RESERVATION_API,
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          setLoading(false);
          window.location.href = `/reservations/${id}`;
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setLoading(true);
            sessionStorage.clear();
            window.location.href = "/login";
          }
          setLoading(false);
          toast.error(error.response.data.Message);
        });
    } catch (error) {
      setServerError(true);
    }
  };
  if (serverError) return <ServerError />;
  return (
    <div className="w-[90%] my-[50px]  mx-auto p-2">
      <ToastContainer theme="light" />
      {reservationState?.rejectReservation && (
        <ReservationJustification editPage={true} />
      )}
      <div className="w-[100%]  p-2">
        <div className="w-[100%] p-2 mx-auto bg-white shadow-lg rounded-[20px] sm:w-[95%]">
          <div className="w-[90%] mt-[50px] mx-auto flex justify-between items-center max-sm1:flex-col max-sm1:items-start max-sm1:gap-[20px]">
            <div>
              <span className="text-[20px] text-[#039674] font-bold">
                Update Reservation
              </span>
            </div>
            <div className="flex items-center gap-[20px] justify-end">
              <button className="text-[14px]" onClick={() => navigate(-1)}>
                Cancel
              </button>
              <button
                disabled={loading}
                onClick={editReservation}
                className={
                  loading
                    ? "bg-[#039674] text-white py-1 px-4 text-[14px] rounded-[5px] cursor-not-allowed"
                    : "bg-[#039674] text-white py-1 px-4 text-[14px] rounded-[5px]"
                }
              >
                Update
              </button>
            </div>
          </div>
          <div>
            <div className="w-[100%] mx-auto p-2 min-h-[70vh]  rounded-[10px]">
              <div className="w-[95%] flex flex-col justify-between h-[100%]  mx-auto">
                <div className="w-[50%] mt-[30px]  flex flex-col gap-[10px] max-md2:w-[100%]">
                  <span className="font-bold text-[#039674]">
                    Which filed do you want update?
                  </span>
                  <select
                    onChange={(e) => setEditField(e.target.value)}
                    className="py-3 px-4 border border-gray-400 bg-transparent rounded-[5px] text-[14px] outline-none"
                  >
                    <option>Select field to edit?</option>
                    <option>Name</option>
                    <option>Email</option>
                    <option>Category</option>
                    <option>Primary phone</option>
                    <option>Secondary Phone</option>
                    <option>Package</option>
                    <option>Guests</option>
                    <option value={"reservation_date"}>Reservation Date</option>
                    <option value={"reservation_time"}>Reservation Time</option>
                    <option>Status</option>
                  </select>
                </div>
                {editFiled === "Name" && (
                  <div className="w-[50%] my-[30px] flex flex-col gap-[10px] max-md2:w-[100%]">
                    <span className="font-bold text-[#039674] text-[14px]">
                      Name
                    </span>
                    <input
                      onChange={(e) => setName(e.target.value)}
                      value={name}
                      type="text"
                      className="py-3 px-4 border border-gray-400 rounded-[5px]  text-[14px] outline-none"
                    />
                  </div>
                )}
                {editFiled === "Email" && (
                  <div className="w-[50%] my-[30px]  flex flex-col gap-[10px] max-md2:w-[100%]">
                    <span className="font-bold text-[#039674] text-[14px]">
                      Email
                    </span>
                    <input
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      className="py-3 px-4 border border-gray-400 rounded-[5px]  text-[14px] outline-none"
                    />
                  </div>
                )}

                {editFiled === "Category" && (
                  <div className="w-[50%] my-[30px]  flex flex-col gap-[10px] max-md2:w-[100%]">
                    <span className="font-bold text-[#039674] text-[14px]">
                      Category
                    </span>
                    <select
                      value={categoryName}
                      onChange={(e) => setCategory(e.target.value)}
                      className="py-3 px-4 border border-gray-400 rounded-[5px] bg-transparent  text-[14px] outline-none"
                    >
                      <option>Make Popular</option>
                      <option value={"individual"}>individual</option>
                      <option value={"organization"}>organization</option>
                    </select>
                  </div>
                )}
                {editFiled === "Primary phone" && (
                  <div className="w-[50%] my-[30px] flex flex-col gap-[10px] max-md2:w-[100%]">
                    <span className="font-bold text-[#039674] text-[14px]">
                      Primary Number
                    </span>
                    <input
                      value={primaryPhone}
                      onChange={(e) => setPrimaryPhone(e.target.value)}
                      type="text"
                      className="py-3 px-4 border border-gray-400 rounded-[5px]  text-[14px] outline-none"
                    />
                  </div>
                )}

                {editFiled === "Secondary Phone" && (
                  <div className="w-[50%] my-[30px] flex flex-col gap-[10px] max-md2:w-[100%]">
                    <span className="font-bold text-[#039674] text-[14px]">
                      Secondary Number
                    </span>
                    <input
                      value={secondaryPhone}
                      onChange={(e) => setSecondaryPhone(e.target.value)}
                      type="text"
                      className="py-3 px-4 border border-gray-400 rounded-[5px]  text-[14px] outline-none"
                    />
                  </div>
                )}

                {editFiled === "Package" && (
                  <div className="w-[50%] my-[30px]  flex flex-col gap-[10px] max-md2:w-[100%]">
                    <span className="font-bold text-[#039674] text-[14px]">
                      Package <span className="text-red-600">*</span>
                    </span>
                    <select
                      value={selectedPackage}
                      onChange={(e) => setSelectedPackage(e.target.value)}
                      className="py-3 px-4 border border-gray-400 rounded-[5px] bg-transparent text-[14px] outline-none"
                    >
                      <option></option>
                      {serviceState?.services?.map((service, index) => {
                        return (
                          <option key={index} value={service?._id}>
                            {service?.name_en}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                )}

                {editFiled === "Guests" && (
                  <div className="w-[50%] my-[30px]  flex flex-col gap-[10px] max-md2:w-[100%]">
                    <span className="font-bold text-[#039674] text-[14px]">
                      Guests
                    </span>
                    <input
                      value={guests}
                      onChange={(e) => setGuests(e.target.value)}
                      type="text"
                      className="py-3 px-4 border border-gray-400 rounded-[5px]  text-[14px] outline-none"
                    />
                  </div>
                )}

                {editFiled === "reservation_date" && (
                  <div className="w-[50%] my-[20px]  flex flex-col gap-[10px] max-md2:w-[100%]">
                    <span className="text-[#039674] font-bold text-[14px]">
                      Reservation Date <span className="text-red-600">*</span>
                    </span>
                    <input
                      type="date"
                      min={1}
                      onChange={(e) => setReservationDate(e.target.value)}
                      className="py-2 px-4 border border-gray-300 rounded-[5px] bg-transparent outline-none text-[14px]"
                    />
                  </div>
                )}

                {editFiled === "reservation_time" && (
                  <div className="w-[50%] my-[20px]  flex flex-col gap-[10px] max-md2:w-[100%]">
                    <span className="text-[#039674] font-bold text-[14px]">
                      Reservation Time <span className="text-red-600">*</span>
                    </span>
                    <input
                      type="time"
                      min={1}
                      onChange={(e) => setReservationTime(e.target.value)}
                      className="py-3 px-4 border border-gray-300 bg-transparent rounded-[5px] outline-none text-[14px]"
                    />
                  </div>
                )}

                {editFiled === "Status" && (
                  <div className="w-[50%]  my-[30px] mt-[30px] flex flex-col gap-[10px] max-md2:w-[100%]">
                    <span className="font-bold text-[#039674] text-[14px]">
                      Status
                    </span>
                    <select
                      onChange={(e) => {
                        setStatus(e.target.value);
                        if (e.target.value === "rejected") {
                          dispatch(
                            reservationAction.setRejectReservation({
                              reservationModal: true,
                              reservationID: id,
                            })
                          );
                        }
                      }}
                      className="py-3 px-4 border border-gray-400 rounded-[5px] bg-transparent  text-[14px] outline-none"
                    >
                      <option value="">Change status</option>
                      <option value={"pending"}>Pending</option>
                      <option value={"ongoing"}>Ongoing</option>
                      <option value={"accepted"}>Accepted</option>
                      <option value={"rejected"}>Rejected</option>
                    </select>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditReservation;
