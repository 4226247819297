import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ServerError from "../../pages/ServerError";
import { useDispatch, useSelector } from "react-redux";
import { fetchGalleries } from "../../REDUX/Slices/gallerySlice";
import Loading from "../Loading";
import { useState } from "react";
import { MdDelete } from "react-icons/md";
import { apiRequest } from "../../utils/request";
import { IoIosArrowBack, IoIosArrowForward } from "react-icons/io";

function GalleryList() {
  const token = sessionStorage?.getItem("tID");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const galleryState = useSelector((state) => state?.gallery);
  const categoryState = useSelector((state) => state?.category);
  const [loading, setLoading] = useState(false);
  const [pageNum, setPageNum] = useState(1);
  const [sortingNum, setSortingNum] = useState(-1);
  const [serverError, setServerError] = useState(false);

  useEffect(() => {
    dispatch(
      fetchGalleries({
        page: pageNum,
        search: categoryState?.category,
        sort: sortingNum,
      })
    );
    // eslint-disable-next-line
  }, [pageNum, categoryState, sortingNum]);

  const removeGallery = async (id) => {
    try {
      setLoading(true);
      await apiRequest
        .delete(`/gallery_api/delete_gallery/${id}`, {
          headers: {
            "Content-Type": "application/json",
            get_delete_gallery_api:
              process.env.REACT_APP_GET_DELETE_GALLERY_API,
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          setLoading(false);
          dispatch(
            fetchGalleries({
              page: pageNum,
              search: categoryState?.category,
              sort: sortingNum,
            })
          );
        })
        .catch((error) => {
          setLoading(false);

          if (error.response.status === 401) {
            sessionStorage.clear();
            window.location.href = "/login";
          }
        });
    } catch (error) {
      setLoading(false);

      setServerError(true);
    }
  };

  const handlePrevious = () => {
    if (pageNum <= 1) {
      setPageNum(1);
    } else {
      setPageNum(pageNum - 1);
    }
  };

  const handleNext = () => {
    if (pageNum >= galleryState?.galleries?.totalPages) {
      setPageNum(galleryState?.galleries?.totalPages);
    } else {
      setPageNum(pageNum + 1);
    }
  };

  if (galleryState?.error || serverError) return <ServerError />;

  return (
    <>
      <ToastContainer theme="light" />
      {galleryState?.loading ? (
        <></>
      ) : (
        galleryState?.galleries?.galleries?.length > 0 &&
        galleryState?.galleries?.totalPages && (
          <div className="w-[100%]  flex justify-end items-center mb-[20px] gap-5">
            <button
              onClick={handlePrevious}
              className="mx-1 w-[30px] h-[30px] flex justify-center items-center cursor-pointer text-white border border-[#039674] bg-[#039674] rounded-[50%] hover:bg-transparent hover:text-[#039674] transition duration-500 max-[400px]:px-2 outline-none"
            >
              <IoIosArrowBack />
            </button>
            <span className="text-gray-600 font-semibold text-[14px]">
              {galleryState?.galleries?.currentPage} of{" "}
              {galleryState?.galleries?.totalPages}
            </span>

            <button
              onClick={handleNext}
              className={
                "mx-1 w-[30px] h-[30px] flex justify-center items-center cursor-pointer text-white border border-[#039674] bg-[#039674] rounded-[50%] hover:bg-transparent hover:text-[#039674] transition duration-500 max-[400px]:px-2 outline-none"
              }
            >
              <IoIosArrowForward />
            </button>

            <div className="flex items-center gap-2">
              <select
                value={sortingNum}
                onChange={(e) => setSortingNum(e?.target?.value)}
                className="py-2 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px] font-medium"
              >
                <option value={-1}>Latest</option>
                <option value={1}>Oldest</option>
              </select>
            </div>
          </div>
        )
      )}
      <div className="w-[100%] my-[30px] grid grid-cols-4 gap-4 max-lg2:grid-cols-3 max-lg:grid-cols-2 max-sm1:grid-cols-1">
        {galleryState?.loading ? (
          <Loading
            additionalStyle={"col-span-4 flex justify-center items-center"}
          />
        ) : galleryState?.galleries?.galleries?.length !== 0 ? (
          galleryState?.galleries?.galleries?.map((image, index) => {
            return (
              <div
                onClick={() =>
                  navigate(`/gallery/edit-gallery/${image?._id}`, {
                    state: {
                      image: image,
                    },
                  })
                }
                className="col-span-1 p-2 bg-white relative group overflow-hidden cursor-pointer"
                key={index}
              >
                <img
                  src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}/GalleryImages/${image?.image}`}
                  className="object-cover w-[100%] h-[300px] rounded-[10px]"
                  alt=""
                />

                <div className="w-[100%] flex justify-center items-center">
                  <div className="w-[100%] flex justify-center items-center">
                    <button
                      disabled={loading}
                      onClick={(e) => {
                        e.stopPropagation();
                        removeGallery(image?._id);
                      }}
                      className={
                        loading
                          ? "py-2 px-4 my-[20px] bg-[#039674] text-white flex items-center gap-[5px] rounded-[5px] text-[12px] cursor-not-allowed"
                          : "py-2 px-4 my-[20px] bg-[#039674] text-white flex items-center gap-[5px] rounded-[5px] text-[12px]"
                      }
                    >
                      <MdDelete className="text-[20px]" /> Remove
                    </button>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="w-[100%] col-span-4 min-h-[100px] flex justify-center items-center bg-white rounded-[10px]">
            <span className="text-gray-500">No result found</span>
          </div>
        )}
      </div>
    </>
  );
}

export default GalleryList;
