import { MdEventNote, MdGroups2, MdOutlineLogout } from "react-icons/md";
import { PiUsersFourLight } from "react-icons/pi";
import { ImSpoonKnife } from "react-icons/im";
import { GrGallery } from "react-icons/gr";
import { TbPackages } from "react-icons/tb";
// import { BiSolidCategory } from "react-icons/bi";

export const managementLinks = [
  {
    id: "1",
    name: "Reservations",
    icon: <MdGroups2 className="text-[24px] max-lg2:text-[16px]" />,
    path: "/",
  },
  {
    id: "2",
    name: "Users",
    icon: <PiUsersFourLight className="text-[24px] max-lg2:text-[16px]" />,
    path: "/users",
  },
];

export const contentManagementLinks = [
  {
    id: "1",
    name: "Events",
    icon: <MdEventNote className="text-[24px] max-lg2:text-[16px]" />,
    path: "/events",
  },
  {
    id: "2",
    name: "Menu",
    icon: <ImSpoonKnife className="text-[24px] max-lg2:text-[16px]" />,
    path: "/menu",
  },
  {
    id: "3",
    name: "Gallery",
    icon: <GrGallery className="text-[24px] max-lg2:text-[16px]" />,
    path: "/gallery",
  },
  {
    id: "4",
    name: "Services",
    icon: <TbPackages className="text-[24px] max-lg2:text-[16px]" />,
    path: "/services",
  },
];

export const lowerLinks = [
  {
    id: "1",
    name: "Logout",
    icon: <MdOutlineLogout className="text-[24px] max-lg2:text-[16px]" />,
    path: "/login",
  },
];

export const reservation = [
  {
    id: "1",
    name: "Abebe Kebede Kasu",
    email: "Aebebe@gmail.com",
    primaryphone: "+2519886653",
    secondaryphone: "+2519228821",
    package: "Wedding, Engagement, Kilikil, Mels",
    level: "1",
    guests: "300",
    category: "Individual",
    status: "rejected",
  },
  {
    id: "2",
    name: "Abebe Kebede Kasu",
    email: "Aebebe@gmail.com",
    primaryphone: "+2519886653",
    secondaryphone: "+2519228821",
    package: "Wedding, Engagement, Kilikil, Mels",
    level: "1",
    guests: "300",
    category: "Individual",
    status: "accepted",
  },
  {
    id: "3",
    name: "Abebe Kebede Kasu",
    email: "Aebebe@gmail.com",
    primaryphone: "+2519886653",
    secondaryphone: "+2519228821",
    package: "Wedding, Engagement, Kilikil, Mels",
    level: "1",
    guests: "300",
    category: "Individual",
    status: "accepted",
  },
  {
    id: "4",
    name: "Abebe Kebede Kasu",
    email: "Aebebe@gmail.com",
    primaryphone: "+2519886653",
    secondaryphone: "+2519228821",
    package: "Wedding, Engagement, Kilikil, Mels",
    level: "1",
    guests: "300",
    category: "Individual",
    status: "accepted",
  },
  {
    id: "5",
    name: "Abebe Kebede Kasu",
    email: "Aebebe@gmail.com",
    primaryphone: "+2519886653",
    secondaryphone: "+2519228821",
    package: "Wedding, Engagement, Kilikil, Mels",
    level: "1",
    guests: "300",
    category: "Individual",
    status: "accepted",
  },
  {
    id: "6",
    name: "Abebe Kebede Kasu",
    email: "Aebebe@gmail.com",
    primaryphone: "+2519886653",
    secondaryphone: "+2519228821",
    package: "Wedding, Engagement, Kilikil, Mels",
    level: "1",
    guests: "300",
    category: "Individual",
    status: "accepted",
  },
  {
    id: "7",
    name: "Abebe Kebede Kasu",
    email: "Aebebe@gmail.com",
    primaryphone: "+2519886653",
    secondaryphone: "+2519228821",
    package: "Wedding, Engagement, Kilikil, Mels",
    level: "1",
    guests: "300",
    category: "Individual",
    status: "accepted",
  },
  {
    id: "8",
    name: "Abebe Kebede Kasu",
    email: "Aebebe@gmail.com",
    primaryphone: "+2519886653",
    secondaryphone: "+2519228821",
    package: "Wedding, Engagement, Kilikil, Mels",
    level: "1",
    guests: "300",
    category: "Individual",
    status: "accepted",
  },
  {
    id: "9",
    name: "Abebe Kebede Kasu",
    email: "Aebebe@gmail.com",
    primaryphone: "+2519886653",
    secondaryphone: "+2519228821",
    package: "Wedding, Engagement, Kilikil, Mels",
    level: "1",
    guests: "300",
    category: "Individual",
    status: "accepted",
  },
  {
    id: "10",
    name: "Abebe Kebede Kasu",
    email: "Aebebe@gmail.com",
    primaryphone: "+2519886653",
    secondaryphone: "+2519228821",
    package: "Wedding, Engagement, Kilikil, Mels",
    level: "1",
    guests: "300",
    category: "Individual",
    status: "accepted",
  },
];

export const menus = [
  {
    id: "1",
    name: "Ayb-kitifo",
    description_en:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Qui ad a cumque, eos ipsum doloribus earum optio suscipit officia dolorum.",
    description_amh:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Qui ad a cumque, eos ipsum doloribus earum optio suscipit officia dolorum.",
    menuImage: "ayb-kitifo.jpg",
    status: "Active",
  },
  {
    id: "2",
    name: "finto",
    description_en:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Qui ad a cumque, eos ipsum doloribus earum optio suscipit officia dolorum.",
    description_amh:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Qui ad a cumque, eos ipsum doloribus earum optio suscipit officia dolorum.",
    menuImage: "menu-finto.jpg",
    status: "Active",
  },
  {
    id: "3",
    name: "menu-3",
    description_en:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Qui ad a cumque, eos ipsum doloribus earum optio suscipit officia dolorum.",
    description_amh:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Qui ad a cumque, eos ipsum doloribus earum optio suscipit officia dolorum.",
    menuImage: "menu-1.jpg",
    status: "Active",
  },
];

export const events = [
  {
    id: "1",
    title_en: "Event-1",
    title_am: "Event-1",
    description_en:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Qui ad a cumque, eos ipsum doloribus earum optio suscipit officia dolorum.",
    description_amh:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Qui ad a cumque, eos ipsum doloribus earum optio suscipit officia dolorum.",
    thumbnail: "menu-1.jpg",
    video_url: "https://www.youtube.com/embed/OZ4tggdMB6k?si=FmDnEfBzJFOUvnTE",
    imageOne: "menu-1.jpg",
    imageTwo: "menu2.jpg",
    imageThree: "menu-3.jpg",
    status: "active",
  },
  {
    id: "2",
    title_en: "Event-2",
    title_am: "Event-2",
    description_en:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Qui ad a cumque, eos ipsum doloribus earum optio suscipit officia dolorum.",
    description_amh:
      "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Qui ad a cumque, eos ipsum doloribus earum optio suscipit officia dolorum.",
    thumbnail: "menu-1.jpg",
    video_url: "https://www.youtube.com/embed/OZ4tggdMB6k?si=FmDnEfBzJFOUvnTE",
    imageOne: "menu-1.jpg",
    imageTwo: "menu2.jpg",
    imageThree: "menu-3.jpg",
    status: "active",
  },
];

export const eventDetailImage = [
  { id: "1", name: "Thumbnail", image: "menu-3.jpg" },
  { id: "2", name: "Image One", image: "menu-1.jpg" },
  { id: "3", name: "Image Two", image: "menu-2.jpg" },
  { id: "4", name: "Image Three", image: "menu-3.jpg" },
];

export const galleryImages = [
  { id: "1", image: "menu-1.jpg" },
  { id: "2", image: "menu-2.jpg" },
  { id: "3", image: "menu-3.jpg" },
  { id: "4", image: "ayb-kitifo.jpg" },
];
export const users = [
  {
    id: "1",
    name: "Dagmawi Taddesse",
    username: "dagm44",
    password: "123",
    email: "dagim@gmail.com",
    phone: "+25199887744",
    role: "Super Admin",
    gender: "Male",
    status: "Active",
  },
  {
    id: "2",
    name: "Solomon Taffesse",
    username: "dagm44",
    password: "123",
    email: "sol@gmail.com",
    phone: "+25122777722",
    role: "Admin",
    gender: "Male",
    status: "Active",
  },
];
