import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { apiRequest } from "../../utils/request";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ServerError from "../../pages/ServerError";
import Loading from "../Loading";

function EventDetail() {
  const token = sessionStorage.getItem("tID");
  const { id } = useParams();
  const navigate = useNavigate();
  const [event, setEvent] = useState({});
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(false);

  useEffect(() => {
    try {
      apiRequest
        ?.get(`/event_api/get_event/${id}`, {
          headers: {
            "Content-Type": "application/json",
            get_detail_event_api: process.env.REACT_APP_GET_DETAIL_EVENT_API,
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setLoading(false);
          setEvent(res.data);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setLoading(true);
            sessionStorage.clear();
            window.location.href = "/login";
          }
          setLoading(false);
          toast.error(error.response.data.Message);
        });
    } catch (error) {
      setServerError(true);
    }
  }, [token, id]);

  if (serverError) return <ServerError />;

  return loading ? (
    <Loading additionalStyle={"flex justify-center items-center my-[20px]"} />
  ) : (
    <div className="w-[90%] my-[50px] mx-auto flex flex-col gap-[20px]">
      <ToastContainer theme="light" />
      <div className="w-[100%] bg-white p-4 flex justify-between items-center shadow-lg rounded-[10px] max-md2:flex-col-reverse max-md2:items-start max-md2 gap-[20px]">
        <div className="flex flex-col gap-[10px]">
          <div className="flex items-center gap-[10px] max-md2:flex-col max-md2:items-start">
            <span className="font-bold">English Title:</span>
            <span className="text-[14px] text-gray-500">{event?.title_en}</span>
          </div>
          <div className="flex items-center gap-[10px] max-md2:flex-col max-md2:items-start">
            <span className="font-bold">Amharic Title:</span>
            <span className="text-[14px] text-gray-500">{event?.title_am}</span>
          </div>
        </div>
        <div className="flex items-center gap-[20px]">
          {event?.status === "active" && (
            <span className="p-2 rounded-[20px] text-[12px] bg-[#039674] text-white">
              active
            </span>
          )}

          {event?.status === "inactive" && (
            <span className="p-2 rounded-[20px] text-[12px] bg-red-600 text-white">
              inactive
            </span>
          )}
          <div className="mx-auto">
            <button
              onClick={() =>
                navigate(`/events/edit-event/${event?._id}`, {
                  state: {
                    event: event,
                  },
                })
              }
              className="w-[100%] whitespace-nowrap py-2 px-4  bg-[#039674] text-white flex justify-center items-center gap-[5px] rounded-[20px]"
            >
              <span className="text-[14px] max-lg2:text-[12px]">
                Edit event
              </span>
            </button>
          </div>
        </div>
      </div>

      <div className="w-[100%] flex justify-between gap-[10px] max-md2:flex-col">
        <div className="w-[50%] bg-white shadow-lg rounded-[10px] max-md2:w-[100%]">
          <div className="w-[90%] my-[20px] mx-auto flex flex-col gap-[10px]">
            <span className="font-bold">English Description</span>
            <p className="text-[12px] text-gray-500 leading-[25px]">
              {event?.description_en}
            </p>
          </div>
        </div>
        <div className="w-[50%] bg-white  rounded-[10px] shadow-lg max-md2:w-[100%]">
          <div className="w-[90%] my-[20px] mx-auto flex flex-col gap-[10px]">
            <span className="font-bold">Amharic Description</span>
            <p className="text-[12px] text-gray-500 leading-[25px]">
              {event?.description_am}
            </p>
          </div>
        </div>
      </div>

      <div className="w-[100%] grid grid-cols-4 gap-4 max-lg2:grid-cols-3 max-md2:grid-cols-2 max-sm1:grid-cols-1">
        {event?.thumbnail && (
          <div className="bg-white p-4 rounded-[10px] shadow-lg">
            <div className="w-[100%] h-[300px] ">
              <img
                src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}/EventImages/${event?.thumbnail}`}
                className="w-[100%] h-[100%] object-cover rounded-[10px]"
                alt=""
              />
            </div>
            <div className="w-[100%] my-[10px] flex justify-center items-center">
              <span className="font-bold text-[#039674] text-center">
                Thumbnail
              </span>
            </div>
          </div>
        )}

        {event?.image_one && (
          <div className="bg-white p-4 rounded-[10px] shadow-lg">
            <div className="w-[100%] h-[300px] ">
              <img
                src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}/EventImages/${event?.image_one}`}
                className="w-[100%] h-[100%] object-cover rounded-[10px]"
                alt=""
              />
            </div>
            <div className="w-[100%] my-[10px] flex justify-center items-center">
              <span className="font-bold text-[#039674] text-center">
                Image One
              </span>
            </div>
          </div>
        )}

        {event?.image_two && (
          <div className="bg-white p-4 rounded-[10px] shadow-lg">
            <div className="w-[100%] h-[300px] ">
              <img
                src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}/EventImages/${event?.image_two}`}
                className="w-[100%] h-[100%] object-cover rounded-[10px]"
                alt=""
              />
            </div>
            <div className="w-[100%] my-[10px] flex justify-center items-center">
              <span className="font-bold text-[#039674] text-center">
                Image Two
              </span>
            </div>
          </div>
        )}

        {event?.image_three && (
          <div className="bg-white p-4 rounded-[10px] shadow-lg">
            <div className="w-[100%] h-[300px] ">
              <img
                src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}/EventImages/${event?.image_three}`}
                className="w-[100%] h-[100%] object-cover rounded-[10px]"
                alt=""
              />
            </div>
            <div className="w-[100%] my-[10px] flex justify-center items-center">
              <span className="font-bold text-[#039674] text-center">
                Image Three
              </span>
            </div>
          </div>
        )}
      </div>

      {event?.video_url && (
        <div className="w-[100%]">
          <iframe
            height="315"
            className="w-[100%] rounded-[10px]"
            src={event?.video_url}
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            sandbox="allow-scripts allow-same-origin allow-popups"
          ></iframe>
        </div>
      )}
    </div>
  );
}

export default EventDetail;
