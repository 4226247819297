import React, { useEffect, useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchCatagories } from "../../REDUX/Slices/categorySlice";
import { apiRequest } from "../../utils/request";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ServerError from "../../pages/ServerError";
import Loading from "../Loading";
function EditCategory() {
  const token = sessionStorage.getItem("tID");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const categoryState = useSelector((state) => state.category);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [editField, setEditFiled] = useState("");
  const [categoryNameEn, setCategoryNameEn] = useState("");
  const [categoryNameAm, setCategoryNameAm] = useState("");
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(false);

  useEffect(() => {
    dispatch(fetchCatagories());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterCategory = categoryState?.categories?.filter(
    (category) => category?.name_en !== "All"
  );

  const handleEditCategory = async () => {
    try {
      setLoading(true);
      await apiRequest
        .put(
          `${process.env.REACT_APP_BACKEND_URL}/gallery_category_api/update_gallery_category/${selectedCategory}`,
          { name_en: categoryNameEn, name_am: categoryNameAm, status },
          {
            headers: {
              get_update_gallery_category_api:
                process.env.REACT_APP_GET_UPDATE_GALLERY_CATEGORY_API,
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          setLoading(false);
          window.location.href = "/gallery";
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setLoading(true);
            sessionStorage.clear();
            window.location.href = "/login";
          }
          setLoading(false);
          toast.error(error.response.data.Message);
        });
    } catch (error) {
      setServerError(true);
    }
  };

  if (serverError) return <ServerError />;
  return (
    <div className="w-[90%] mx-auto my-[30px] p-2 bg-white shadow-md rounded-[10px]">
      <ToastContainer theme="light" />
      <div className="w-[90%] my-[20px] mx-auto">
        <div className="flex justify-between items-center">
          <div className="my-[20px] flex items-center gap-[10px] text-[#039674]">
            <IoArrowBack
              className="text-[24px]   cursor-pointer"
              onClick={() => navigate(-1)}
            />
            <span className="text-base font-bold">Edit Category</span>
          </div>
          {loading ? (
            <Loading
              additionalStyle={"flex justify-center items-center my-[20px]"}
            />
          ) : (
            <div className=" flex items-center gap-[20px]">
              <button onClick={() => navigate(-1)} className="text-[14px]">
                Cancel
              </button>
              <button
                onClick={handleEditCategory}
                className="py-2 px-4 bg-[#039674] text-white flex items-center gap-[5px] rounded-[5px] text-[14px]"
              >
                <span className="text-[14px]">Update</span>
              </button>
            </div>
          )}
        </div>

        <div className="w-[100%] my-[50px]">
          <div className="flex flex-col justify-between  gap-[10px]">
            <div className="w-[50%] my-[20px] flex flex-col gap-[10px] max-md1:w-[100%]">
              <span className="font-bold text-[14px] text-[#039674]">
                Category <span className="text-red-600">*</span>
              </span>
              <select
                onChange={(e) => setSelectedCategory(e.target.value)}
                className="py-2 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px]"
              >
                <option value={"-"}></option>
                {categoryState?.categories &&
                  filterCategory?.map((category, index) => {
                    return (
                      <option key={index} value={category?._id}>
                        {category?.name_en}
                      </option>
                    );
                  })}
              </select>
            </div>

            {selectedCategory !== "-" && selectedCategory !== "" && (
              <div className="w-[50%] my-[20px] flex flex-col gap-[10px] max-md1:w-[100%]">
                <span className="font-bold text-[14px] text-[#039674]">
                  Which field do you want to edit?{" "}
                  <span className="text-red-600">*</span>
                </span>
                <select
                  onChange={(e) => setEditFiled(e.target.value)}
                  className="py-2 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px]"
                >
                  <option></option>
                  <option value={"English name"}>English name</option>
                  <option value={"Amharic name"}>Amharic name</option>
                  <option value={"Status"}>Status</option>
                </select>
              </div>
            )}

            {selectedCategory !== "-" &&
              editField !== "" &&
              editField === "English name" && (
                <div className="w-[50%] my-[20px] flex flex-col gap-[10px] max-md1:w-[100%]">
                  <span className="font-bold text-[14px] text-[#039674]">
                    English Name <span className="text-red-600">*</span>
                  </span>
                  <input
                    type="text"
                    onChange={(e) => setCategoryNameEn(e.target.value)}
                    className="py-2 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px]"
                  />
                </div>
              )}

            {selectedCategory !== "-" &&
              editField !== "" &&
              editField === "Amharic name" && (
                <div className="w-[50%] my-[20px] flex flex-col gap-[10px] max-md1:w-[100%]">
                  <span className="font-bold text-[14px] text-[#039674]">
                    Amharic Name <span className="text-red-600">*</span>
                  </span>
                  <input
                    type="text"
                    onChange={(e) => setCategoryNameAm(e.target.value)}
                    className="py-2 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px]"
                  />
                </div>
              )}

            {selectedCategory !== "-" &&
              editField !== "" &&
              editField === "Status" && (
                <div className="w-[50%] my-[20px] flex flex-col gap-[10px] max-md1:w-[100%]">
                  <span className="font-bold text-[14px] text-[#039674]">
                    Status <span className="text-red-600">*</span>
                  </span>
                  <select
                    onChange={(e) => setStatus(e.target.value)}
                    className="py-2 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px]"
                  >
                    <option value={""}>Edit status</option>
                    <option value={"active"}>Active</option>
                    <option value={"inactive"}>Inactive</option>
                  </select>
                </div>
              )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditCategory;
