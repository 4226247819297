import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { fetchServices } from "../../REDUX/Slices/serviceSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiRequest } from "../../utils/request";
import ServerError from "../../pages/ServerError";

function CreateReservation() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const serviceState = useSelector((state) => state?.services);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [categoryName, setCategory] = useState("");
  const [primaryPhone, setPrimaryPhone] = useState("");
  const [secondaryPhone, setSecondaryPhone] = useState("");
  const [selectedPackage, setSelectedPackage] = useState("");
  const [guests, setGuests] = useState("");
  const [reservationDate, setReservationDate] = useState("");
  const [reservationTime, setReservationTime] = useState("");
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(false);

  const createReservation = async () => {
    try {
      setLoading(true);

      await apiRequest
        .post(
          "/reservation_api/create_reservation",
          {
            category: categoryName,
            name,
            email,
            categoryName,
            primary_phone: primaryPhone,
            secondary_phone: secondaryPhone,
            package: selectedPackage,
            num_of_ppls: guests,
            reservation_date: reservationDate,
            reservation_time: reservationTime,
          },
          {
            headers: {
              get_create_reservation_api:
                process.env.REACT_APP_GET_CREATE_RESERVATION_API,
            },
          }
        )
        .then(() => {
          setLoading(false);
          window.location.href = "/";
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setLoading(true);
            sessionStorage.clear();
            window.location.href = "/login";
          }
          setLoading(false);
          toast.error(error.response.data.Message);
        });
    } catch (error) {
      setServerError(true);
    }
  };

  useEffect(() => {
    dispatch(fetchServices());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (serverError) return <ServerError />;

  return (
    <div className="w-[90%] mx-auto my-[50px] p-2 bg-white shadow-md rounded-[10px]">
      <ToastContainer theme="light" />
      <div className="w-[90%] my-[20px] mx-auto">
        <div className="my-[20px] sm:px-0 flex items-center gap-[10px]">
          <IoArrowBack
            className="text-[24px] hover:text-[#039674]  cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <span className="text-base font-bold leading-7 text-gray-900">
            Customer information
          </span>
        </div>

        <div className="w-[100%] my-[50px]">
          <div className="my-[20px] flex flex-col gap-[10px]">
            <span className="font-bold text-[14px]">
              Name <span className="text-red-600">*</span>
            </span>
            <input
              type="text"
              onChange={(e) => setName(e.target.value)}
              className="py-2 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px]"
            />
          </div>

          <div className="flex justify-between items-center gap-[10px]">
            <div className="w-[50%] flex flex-col gap-[10px]">
              <span className="font-bold text-[14px]">
                Email <span className="text-red-600">*</span>
              </span>
              <input
                type="text"
                onChange={(e) => setEmail(e.target.value)}
                className="py-2 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px]"
              />
            </div>
            <div className="w-[50%] flex flex-col gap-[10px]">
              <span className="font-bold text-[14px]">
                Category <span className="text-red-600">*</span>
              </span>
              <select
                onChange={(e) => setCategory(e.target.value)}
                className="py-2 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px]"
              >
                <option></option>
                <option value={"individual"}>individual</option>
                <option value={"organization"}>organization</option>
              </select>
            </div>
          </div>
          <div className="my-[20px] flex justify-between items-center gap-[10px]">
            <div className="w-[50%] flex flex-col gap-[10px]">
              <span className="font-bold text-[14px]">
                Primary phone <span className="text-red-600">*</span>
              </span>
              <input
                type="text"
                onChange={(e) => setPrimaryPhone(e.target.value)}
                className="py-2 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px]"
              />
            </div>
            <div className="w-[50%] flex flex-col gap-[10px]">
              <span className="font-bold text-[14px]">Secondary phone</span>
              <input
                type="text"
                onChange={(e) => setSecondaryPhone(e.target.value)}
                className="py-2 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px]"
              />
            </div>
          </div>

          <div className="flex justify-between items-center gap-[10px]">
            <div className="w-[50%] flex flex-col gap-[10px]">
              <span className="font-bold text-[14px]">
                Package <span className="text-red-600">*</span>
              </span>
              <select
                onChange={(e) => setSelectedPackage(e.target.value)}
                className="py-2 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px]"
              >
                <option></option>
                {serviceState?.services?.map((service, index) => {
                  return (
                    <option key={index} value={service?._id}>
                      {service?.name_en}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="w-[50%] my-[20px] flex flex-col gap-[10px]">
              <span className="font-bold text-[14px]">
                Guests <span className="text-red-600">*</span>
              </span>
              <input
                type="number"
                min={1}
                onChange={(e) => setGuests(e.target.value)}
                className="py-2 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px]"
              />
            </div>
          </div>
          <div className="flex justify-between items-center gap-[10px]">
            <div className="w-[50%] flex flex-col gap-[10px]">
              <span className="font-bold text-[14px]">
                Reservation Date <span className="text-red-600">*</span>
              </span>
              <input
                type="date"
                min={1}
                onChange={(e) => setReservationDate(e.target.value)}
                className="py-2 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px]"
              />
            </div>
            <div className="w-[50%] my-[20px] flex flex-col gap-[10px]">
              <span className="font-bold text-[14px]">
                Reservation Time <span className="text-red-600">*</span>
              </span>
              <input
                type="time"
                min={1}
                onChange={(e) => setReservationTime(e.target.value)}
                className="py-2 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px]"
              />
            </div>
          </div>

          <div className="w-[100%] flex justify-end items-center gap-[20px]">
            <button onClick={() => navigate(-1)} className="text-[14px]">
              Cancel
            </button>
            <button
              onClick={createReservation}
              disabled={loading}
              className={
                loading
                  ? "py-2 px-4 bg-[#039674] text-white flex items-center gap-[5px] rounded-[5px] text-[14px] cursor-not-allowed"
                  : "py-2 px-4 bg-[#039674] text-white flex items-center gap-[5px] rounded-[5px] text-[14px]"
              }
            >
              <span className="text-[14px]">Make reservation</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateReservation;
