import React, { useEffect, useState } from "react";
import { apiRequest } from "../utils/request";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GiHamburgerMenu } from "react-icons/gi";
import ServerError from "../pages/ServerError";
import { useDispatch, useSelector } from "react-redux";
import { applicationStateAction } from "../REDUX/Slices/applicationStateSlice";

function DashboardNavbar() {
  const userID = sessionStorage.getItem("uID");
  const token = sessionStorage.getItem("tID");
  const dispatch = useDispatch();
  const applicationState = useSelector((state) => state.application);
  const [user, setUser] = useState({});
  const [serverError, setServerError] = useState(false);
  useEffect(() => {
    try {
      apiRequest
        .get(`/user_api/get_user/${userID}`, {
          headers: {
            "Content-Type": "application/json",
            get_detail_users_api: process.env.REACT_APP_GET_DETAIL_USERS_API,
            Authorization: `Bearer ${token}`,
          },
        })
        .then((res) => {
          setUser(res.data);
        })
        .catch((error) => {
          if (error) {
            if (error?.response?.status === 401) {
              sessionStorage.clear();
              window.location.href = "/login";
            }

            toast.error(error.response?.data.Message);
          }
        });
    } catch (error) {
      setServerError(true);
    }
  }, [userID, token]);

  if (serverError) return <ServerError />;
  return (
    <div className="w-[100%] h-[80px] bg-white shadow-lg sticky top-0 z-[1000]">
      <ToastContainer theme="light" />
      <div className="w-[90%] h-[100%] mx-auto flex justify-between items-center">
        <div className="flex items-center gap-[10px]">
          <GiHamburgerMenu
            className="text-[25px] hidden text-[#4DB27D] font-bold cursor-pointer max-lg1:block"
            onClick={() =>
              dispatch(
                applicationStateAction.setIsNavbarOpened(
                  !applicationState.isNavbarOpened
                )
              )
            }
          />
          <span className="font-bold  max-lg2:text-[14px] max-md1:hidden">
            Welcome Back,{" "}
            <b className="text-[#4DB27D]">{user?.full_name?.split(" ")[0]}</b>
          </span>
        </div>

        <div className="flex flex-col gap-[5px] items-center max-md2:hidden">
          <span className=" text-[#4DB27D] uppercase font-bold max-lg2:text-[12px]">
            Totot Traditional Restaurant
          </span>
          <span className="text-gray-500 uppercase text-[12px] max-lg2:text-[10px]">
            Administration Dashboard
          </span>
        </div>
        <div className="flex items-center gap-[10px]">
          <div className="w-[50px] h-[50px] bg-black rounded-full max-lg2:w-[30px] max-lg2:h-[30px]">
            <img
              src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}/UserImages/${user?.picture}`}
              className="w-[100%] h-[100%] object-cover rounded-full"
              alt=""
            />
          </div>
          <div className="flex flex-col">
            {user ? (
              <span className="font-bold text-[14px] text-[#4DB27D] max-lg2:text-[12px]">
                {user?.full_name}
              </span>
            ) : (
              "-"
            )}

            {user ? (
              <span className="text-[12px] text-gray-500 max-lg2:text-[10px]">
                {user?.role === "Super_Admin" && "Super Admin"}
                {user?.role === "Admin" && "Admin"}
              </span>
            ) : (
              "-"
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardNavbar;
