import React, { useEffect, useState } from "react";
import { IoIosArrowBack, IoIosArrowForward, IoIosSearch } from "react-icons/io";
import { FaPhoneAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchReservations,
  reservationAction,
} from "../../REDUX/Slices/reservationSlice";
import { fetchServices } from "../../REDUX/Slices/serviceSlice";
import ReservationJustification from "./ReservationJustification";
import { apiRequest } from "../../utils/request";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ServerError from "../../pages/ServerError";
import { MdOutlineDateRange } from "react-icons/md";
import Loading from "../Loading";
import { useDebounce } from "use-debounce";

function ReservationTable() {
  const token = sessionStorage?.getItem("tID");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reservationState = useSelector((state) => state?.reservations);
  const serviceState = useSelector((state) => state?.services);
  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [stateFilter, setStateFilter] = useState("");
  const [date, setDate] = useState("");
  const [pageNum, setPageNum] = useState(1);
  const [sortingNum, setSortingNum] = useState(1);
  const [allButton, setAllbutton] = useState(true);
  const [pendingButton, setPendingButton] = useState(false);
  const [ongoingButton, setOngoingButton] = useState(false);
  const [acceptedButton, setAcceptedButton] = useState(false);
  const [rejectedButton, setRejectedButton] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [debouncedCustomerName] = useDebounce(customerName, 500);
  const [debouncedCustomerPhone] = useDebounce(customerPhone, 500);

  useEffect(() => {
    dispatch(fetchServices());
    dispatch(
      fetchReservations({
        page: pageNum,
        customer_name: debouncedCustomerName,
        customer_phone: debouncedCustomerPhone,
        reservation_date: date,
        status: stateFilter,
        sort: sortingNum,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    pageNum,
    debouncedCustomerName,
    debouncedCustomerPhone,
    date,
    stateFilter,
    sortingNum,
  ]);

  const changeStatus = async (e, id) => {
    const status = e?.target?.value;

    try {
      if (status === "rejected") {
        dispatch(
          reservationAction.setRejectReservation({
            reservationModal: true,
            reservationID: id,
          })
        );
      }
      if (status === "ongoing" || status === "accepted") {
        await apiRequest
          ?.put(
            `/reservation_api/update_reservation/${id}`,
            {
              status,
            },
            {
              headers: {
                get_update_reservation_api:
                  process.env.REACT_APP_GET_UPDATE_RESERVATION_API,
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            dispatch(
              fetchReservations({
                page: pageNum,
                customer_name: customerName,
                customer_phone: customerPhone,
                reservation_date: date,
                status: stateFilter,
              })
            );
          })
          .catch((error) => {
            if (error.response.status === 401) {
              sessionStorage.clear();
              window.location.href = "/login";
            }

            toast.error(error?.response?.data?.Message);
          });
      }
    } catch (error) {
      setServerError(true);
    }
  };

  const allButtonState = () => {
    setStateFilter("");
    setAllbutton(true);
    setPendingButton(false);
    setOngoingButton(false);
    setAcceptedButton(false);
    setRejectedButton(false);
  };
  const pendingButtonState = () => {
    setStateFilter("pending");
    setAllbutton(false);
    setPendingButton(true);
    setOngoingButton(false);
    setAcceptedButton(false);
    setRejectedButton(false);
  };

  const ongoingButtonState = () => {
    setStateFilter("ongoing");
    setAllbutton(false);
    setPendingButton(false);
    setOngoingButton(true);
    setAcceptedButton(false);
    setRejectedButton(false);
  };

  const acceptedButtonState = () => {
    setStateFilter("accepted");
    setAllbutton(false);
    setPendingButton(false);
    setOngoingButton(false);
    setAcceptedButton(true);
    setRejectedButton(false);
  };
  const rejectedButtonState = () => {
    setStateFilter("rejected");
    setAllbutton(false);
    setPendingButton(false);
    setOngoingButton(false);
    setAcceptedButton(false);
    setRejectedButton(true);
  };

  const handlePrevious = () => {
    if (pageNum <= 1) {
      setPageNum(1);
    } else {
      setPageNum(pageNum - 1);
    }
  };

  const handleNext = () => {
    if (pageNum >= reservationState?.reservations?.totalPages) {
      setPageNum(reservationState?.reservations?.totalPages);
    } else {
      setPageNum(pageNum + 1);
    }
  };

  if (serverError) return <ServerError />;

  return (
    <div className="w-[100%] max-h-[700px]   my-[30px] bg-white rounded-[10px] shadow-md overflow-y-auto hide-scroll-bar">
      <ToastContainer theme="light" />
      {reservationState?.rejectReservation && (
        <ReservationJustification editPage={false} />
      )}

      <div className="w-[95%] my-[20px] mx-auto flex justify-between items-center flex-wrap">
        <div className="w-[50%] flex items-center gap-[10px] max-md2:w-[100%] max-md1:flex-col">
          <div className="w-[50%] flex px-4 items-center gap-[10px] border border-gray-300 rounded-[5px] max-md1:w-[100%]">
            <IoIosSearch className="text-[24px] text-gray-500  max-[1500px]:text-[14px]" />
            <input
              type="text"
              placeholder="Search by customer name..."
              onChange={(e) => setCustomerName(e?.target?.value)}
              className="py-2 flex-1 text-[14px] outline-none  max-lg2:text-[10px] max-md1:py-3"
            />
          </div>
          <div className="w-[50%] flex px-4 items-center gap-[10px] border border-gray-300 rounded-[5px] max-md1:w-[100%]">
            <FaPhoneAlt className="text-[18px] text-gray-500  max-lg2:text-[14px]" />
            <input
              type="text"
              placeholder="Search by customer phone"
              onChange={(e) => setCustomerPhone(e?.target?.value)}
              className="py-2 flex-1 text-[14px] outline-none  max-lg2:text-[10px]  max-md1:py-3"
            />
          </div>
          <div className="w-[50%] flex px-4 items-center gap-[10px] border border-gray-300 rounded-[5px] max-md1:w-[100%] max-md1:py-2">
            <MdOutlineDateRange className="text-[18px] text-gray-500  max-[1500px]:text-[14px]  max-md1:py-3" />
            <input
              type="date"
              placeholder="Search by date"
              onChange={(e) => setDate(e?.target?.value)}
              className="py-2 flex-1 text-[14px] outline-none  max-lg2:text-[10px]  max-md1:py-3"
            />
          </div>
        </div>
      </div>
      <div className="w-[95%] mx-auto flex justify-between items-center max-md1:flex-col max-md1:gap-[20px]">
        <div className="w-[50%]  flex justify-start items-center gap-[15px] text-[14px]  max-lg2:text-[12px] max-lg2:w-[100%]">
          <button
            onClick={allButtonState}
            className={
              allButton
                ? "bg-[#039674] py-2 px-4 rounded-[5px] text-white"
                : "py-2 px-4 rounded-[5px] text-gray-500"
            }
          >
            All
          </button>
          <button
            className={
              pendingButton
                ? "bg-gray-300 py-2 px-4 rounded-[5px]"
                : "text-gray-500"
            }
            onClick={pendingButtonState}
          >
            Pending
          </button>
          <button
            className={
              ongoingButton
                ? "bg-black py-2 px-4 rounded-[5px] text-white"
                : "text-gray-500"
            }
            onClick={ongoingButtonState}
          >
            Ongoing
          </button>
          <button
            className={
              acceptedButton
                ? "bg-[#039674] py-2 px-4 rounded-[5px] text-white"
                : "text-gray-500"
            }
            onClick={acceptedButtonState}
          >
            Accepted
          </button>
          <button
            className={
              rejectedButton
                ? "bg-red-600 py-2 px-4 rounded-[5px] text-white"
                : "text-gray-500"
            }
            onClick={rejectedButtonState}
          >
            Rejected
          </button>
        </div>

        {reservationState?.loading ? (
          <div></div>
        ) : (
          reservationState?.reservations?.reservations?.length > 0 &&
          reservationState?.reservations?.totalPages && (
            <div className="w-[95%] flex justify-end items-center  gap-5">
              <button
                onClick={handlePrevious}
                className=" w-[30px] h-[30px] flex justify-center items-center cursor-pointer text-white border border-[#039674] bg-[#039674] rounded-[50%] hover:bg-transparent hover:text-[#039674] transition duration-500 max-lg2:w-[25px] max-lg2:h-[25px] max-sm1:px-2 outline-none"
              >
                <IoIosArrowBack />
              </button>
              <span className="text-gray-600 font-semibold max-lg2:text-[14px]">
                {reservationState?.reservations?.currentPage} of{" "}
                {reservationState?.reservations?.totalPages}
              </span>

              <button
                onClick={handleNext}
                className={
                  "mx-1 w-[30px] h-[30px] flex justify-center items-center cursor-pointer text-white border border-[#039674] bg-[#039674] rounded-[50%] hover:bg-transparent hover:text-[#039674] transition duration-500 max-lg2:w-[25px] max-lg2:h-[25px]   max-sm1:px-2 outline-none"
                }
              >
                <IoIosArrowForward />
              </button>

              <div className="flex items-center gap-2">
                <select
                  value={sortingNum}
                  onChange={(e) => setSortingNum(e?.target?.value)}
                  className="py-2 px-4 border border-gray-300 rounded-[5px] outline-none text-[12px] font-medium"
                >
                  <option value={1}>Oldest</option>
                  <option value={-1}>Latest</option>
                </select>
              </div>
            </div>
          )
        )}
      </div>

      {reservationState?.loading ? (
        <Loading
          additionalStyle={"my-[30px] flex justify-center items-center"}
        />
      ) : (
        <div className="my-[30px] relative overflow-x-auto hide-scroll-bar">
          {reservationState?.reservations?.reservations?.length !== 0 ? (
            <table className="w-full  text-left rtl:text-right text-gray-500 ">
              <thead className="text-[14px] text-gray-700  border-b sticky top-0 z-10 bg-white h-[60px] max-lg2:text-[12px]">
                <tr>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    #
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Customer Name
                  </th>

                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Phone Number
                  </th>

                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Package
                  </th>

                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    No of Guests
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Category
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3 whitespace-nowrap">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {reservationState?.reservations?.reservations?.map(
                  (reservation, index) => {
                    const serviceName = serviceState?.services?.find(
                      (service) => service?._id === reservation?.package
                    )?.name_en;
                    return (
                      <tr
                        className="border-b text-[14px] cursor-pointer whitespace-nowrap  max-lg2:text-[12px]"
                        key={index}
                        onClick={() =>
                          navigate(`/reservations/${reservation?._id}`)
                        }
                      >
                        <td className="px-6 py-4 whitespace-nowrap">
                          {index + 1}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {reservation?.name ? reservation?.name : "-"}
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          {reservation?.primary_phone
                            ? reservation?.primary_phone
                            : "-"}
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          {serviceName ? serviceName : "-"}
                        </td>

                        <td className="px-6 py-4 whitespace-nowrap">
                          {reservation?.num_of_ppls
                            ? reservation?.num_of_ppls
                            : "-"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {reservation?.category ? reservation?.category : "-"}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap">
                          {reservation?.status === "pending" && (
                            <span className="p-2 rounded-[5px] bg-gray-300 text-black">
                              Pending
                            </span>
                          )}

                          {reservation?.status === "accepted" && (
                            <span className="p-2 rounded-[5px] bg-[#039674] text-white">
                              Accepted
                            </span>
                          )}

                          {reservation?.status === "rejected" && (
                            <span className="p-2 rounded-[5px] bg-red-600 text-white">
                              Rejected
                            </span>
                          )}
                          {reservation?.status === "ongoing" && (
                            <span className="p-2 rounded-[5px] bg-black text-white">
                              Ongoing
                            </span>
                          )}
                        </td>
                        <td
                          onClick={(e) => e.stopPropagation()}
                          className="px-6 py-4  whitespace-nowrap flex items-center gap-[20px]"
                        >
                          {reservation?.status !== "accepted" &&
                            reservation?.status !== "rejected" && (
                              <select
                                onClick={(e) => e.stopPropagation()}
                                onChange={(e) =>
                                  changeStatus(e, reservation?._id)
                                }
                                className="py-2 px-4  border border-gray-300 rounded-[5px] outline-none text-[12px]"
                              >
                                <option></option>
                                {reservation?.status !== "ongoing" && (
                                  <option value={"ongoing"}>Ongoing</option>
                                )}
                                {reservation?.status !== "accepted" && (
                                  <option value={"accepted"}>Accepted</option>
                                )}
                                {reservation?.status !== "rejected" && (
                                  <option value={"rejected"}>Rejected</option>
                                )}
                              </select>
                            )}
                        </td>
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          ) : (
            <div className="w-[90%] mx-auto my-5 flex justify-center items-center">
              <span className="text-[20px] text-gray-500 font-medium">
                No result found
              </span>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default ReservationTable;
