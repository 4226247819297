import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./Slices/userSlice";
import menuSlice from "./Slices/menuSlice";
import categorySlice from "./Slices/categorySlice";
import gallerySlice from "./Slices/gallerySlice";
import eventSlice from "./Slices/eventSlice";
import serviceSlice from "./Slices/serviceSlice";
import reservationSlice from "./Slices/reservationSlice";
import applicationStateSlice from "./Slices/applicationStateSlice";

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    menu: menuSlice.reducer,
    category: categorySlice.reducer,
    gallery: gallerySlice.reducer,
    event: eventSlice.reducer,
    services: serviceSlice.reducer,
    reservations: reservationSlice.reducer,
    application: applicationStateSlice.reducer,
  },
});

export default store;
