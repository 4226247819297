import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import { apiRequest } from "../../utils/request";
import ServerError from "../../pages/ServerError";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function CreateEvent() {
  const token = sessionStorage.getItem("tID");
  const navigate = useNavigate();
  const [titleEn, setTitleEn] = useState("");
  const [titleAm, setTitleAm] = useState("");
  const [descriptionEn, setDescriptionEn] = useState("");
  const [descriptionAm, setDescriptionAm] = useState("");
  const [videoURL, setVideoURL] = useState("");
  const [thumbnail, setThumbnail] = useState(null);
  const [imageOne, setImageOne] = useState(null);
  const [imageTwo, setImageTwo] = useState(null);
  const [imageThree, setImageThree] = useState(null);
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(false);

  const handleCreateEvent = async () => {
    try {
      const eventData = new FormData();

      eventData.append("title_en", titleEn);
      eventData.append("title_am", titleAm);
      eventData.append("description_en", descriptionEn);
      eventData.append("description_am", descriptionAm);
      eventData.append("video_url", videoURL);
      eventData.append("thumbnail", thumbnail);
      eventData.append("image_one", imageOne);
      eventData.append("image_two", imageTwo);
      eventData.append("image_three", imageThree);

      setLoading(true);

      await apiRequest
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/event_api/create_event`,
          eventData,
          {
            headers: {
              get_create_event_api: process.env.REACT_APP_GET_CREATE_EVENT_API,
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          setLoading(false);
          window.location.href = "/events";
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setLoading(true);
            sessionStorage.clear();
            window.location.href = "/login";
          }
          setLoading(false);
          toast.error(error.response.data.Message);
        });
    } catch (error) {
      setServerError(true);
    }
  };

  if (serverError) return <ServerError />;
  return (
    <div className="w-[90%] mx-auto my-[30px] p-2 bg-white shadow-md rounded-[10px]">
      <ToastContainer theme="light" />
      <div className="w-[90%] my-[20px] mx-auto">
        <div className="my-[20px]  flex items-center gap-[10px] text-[#039674]">
          <IoArrowBack
            className="text-[24px]   cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <span className="text-base font-bold">Enter event information</span>
        </div>

        <div className="w-[100%] my-[50px]">
          <div className="flex justify-between items-center gap-[10px] max-md2:flex-col">
            <div className="w-[50%] my-[20px] flex flex-col gap-[10px] max-md2:w-[100%]">
              <span className="font-bold text-[14px] text-[#039674]">
                Title in english <span className="text-red-600">*</span>
              </span>
              <input
                type="text"
                onChange={(e) => setTitleEn(e.target.value)}
                className="py-2 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px]"
              />
            </div>
            <div className="w-[50%] my-[20px] flex flex-col gap-[10px] max-md2:w-[100%]">
              <span className="font-bold text-[14px] text-[#039674]">
                Title in amharic <span className="text-red-600">*</span>
              </span>
              <input
                type="text"
                onChange={(e) => setTitleAm(e.target.value)}
                className="py-2 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px]"
              />
            </div>
          </div>
          <div className="flex justify-between items-center gap-[10px] max-md2:flex-col">
            <div className="w-[50%] my-[20px] flex flex-col gap-[10px] max-md2:w-[100%]">
              <span className="font-bold text-[14px] text-[#039674]">
                Description in english <span className="text-red-600">*</span>
              </span>
              <textarea
                rows={20}
                onChange={(e) => setDescriptionEn(e.target.value)}
                className=" py-2 px-4 border border-gray-300 rounded-[5px] outline-none resize-none text-[14px]"
              ></textarea>
            </div>
            <div className="w-[50%] my-[20px] flex flex-col gap-[10px] max-md2:w-[100%]">
              <span className="font-bold text-[14px] text-[#039674]">
                Description in amharic <span className="text-red-600">*</span>
              </span>
              <textarea
                rows={20}
                onChange={(e) => setDescriptionAm(e.target.value)}
                className="py-2 px-4 border border-gray-300 rounded-[5px] outline-none resize-none text-[14px]"
              ></textarea>
            </div>
          </div>
          <div className="w-[100%] my-[20px] flex flex-col gap-[10px]">
            <span className="font-bold text-[14px] text-[#039674]">
              Video URL (optional)
            </span>
            <input
              type="text"
              onChange={(e) => setVideoURL(e.target.value)}
              className="py-2 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px]"
            />
          </div>

          {videoURL && (
            <div className="w-[100%]">
              <iframe
                height="315"
                src={videoURL}
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                sandbox="allow-scripts allow-same-origin allow-popups"
              ></iframe>
            </div>
          )}
          <div className="w-[100%] my-[30px]  mx-auto flex justify-between items-center gap-[20px] max-md2:flex-col">
            <div className="w-[50%] mx-auto flex flex-col gap-[10px] max-md2:w-[100%]">
              <span className="font-bold text-[14px] text-[#039674]">
                Thumbnail <span className="text-red-600">*</span>
              </span>
              <label>
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={(e) => setThumbnail(e.target.files[0])}
                  hidden
                />
                <div className="[w-[100%] min-h-[300px] flex flex-col justify-center gap-[10px] text-[#039674]  items-center p-4 mx-auto border border-gray-400 rounded border-dashed cursor-pointer text-center">
                  <div className="flex items-center gap-[10px]">
                    {/* <BsFillCloudUploadFill /> */}
                    <span className="text-[14px] font-bold ">Upload File</span>
                  </div>

                  <span className="w-[80%] text-[12px] max-lg:w-[70%]">
                    Please ensure images are of high resolution in format jpg,
                    or png. Only upload media that you have the legal rights to.
                  </span>
                  <span className="w-[50%] text-[12px] max-lg:w-[70%]">
                    File size max <b>2MB</b>
                  </span>
                </div>
              </label>
            </div>
            <div className="w-[50%] mt-[20px] mx-auto flex gap-[20px] max-md2:w-[100%]">
              {thumbnail && (
                <img
                  className="w-[100%] h-[300px] object-cover"
                  src={URL.createObjectURL(thumbnail)}
                  alt=""
                />
              )}
            </div>
          </div>
          <div className="w-[100%] my-[30px]  mx-auto flex justify-between items-center gap-[20px] max-md2:flex-col">
            <div className="w-[50%] mx-auto flex flex-col gap-[10px] max-md2:w-[100%]">
              <span className="font-bold text-[14px] text-[#039674]">
                Image one (optional)
              </span>
              <label>
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={(e) => setImageOne(e.target.files[0])}
                  hidden
                />
                <div className="[w-[100%] min-h-[300px] flex flex-col justify-center gap-[10px] text-[#039674]  items-center p-4 mx-auto border border-gray-400 rounded border-dashed cursor-pointer text-center">
                  <div className="flex items-center gap-[10px]">
                    {/* <BsFillCloudUploadFill /> */}
                    <span className="text-[14px] font-bold ">Upload File</span>
                  </div>

                  <span className="w-[80%] text-[12px] max-lg:w-[70%]">
                    Please ensure images are of high resolution in format jpg,
                    or png. Only upload media that you have the legal rights to.
                  </span>
                  <span className="w-[50%] text-[12px] max-lg:w-[70%]">
                    File size max <b>2MB</b>
                  </span>
                </div>
              </label>
            </div>
            <div className="w-[50%] mt-[20px] mx-auto flex gap-[20px] max-md2:w-[100%]">
              {imageOne && (
                <img
                  className="w-[100%] h-[300px] object-cover"
                  src={URL.createObjectURL(imageOne)}
                  alt=""
                />
              )}
            </div>
          </div>
          <div className="w-[100%] my-[30px]  mx-auto flex justify-between items-center gap-[20px] max-md2:flex-col">
            <div className="w-[50%] mx-auto flex flex-col gap-[10px] max-md2:w-[100%]">
              <span className="font-bold text-[14px] text-[#039674]">
                Image two (optional)
              </span>
              <label>
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={(e) => setImageTwo(e.target.files[0])}
                  hidden
                />
                <div className="[w-[100%] min-h-[300px] flex flex-col justify-center gap-[10px] text-[#039674]  items-center p-4 mx-auto border border-gray-400 rounded border-dashed cursor-pointer text-center">
                  <div className="flex items-center gap-[10px]">
                    <span className="text-[14px] font-bold ">Upload File</span>
                  </div>

                  <span className="w-[80%] text-[12px] max-lg:w-[70%]">
                    Please ensure images are of high resolution in format jpg,
                    or png. Only upload media that you have the legal rights to.
                  </span>
                  <span className="w-[50%] text-[12px] max-lg:w-[70%]">
                    File size max <b>2MB</b>
                  </span>
                </div>
              </label>
            </div>
            <div className="w-[50%] mt-[20px] mx-auto flex gap-[20px] max-md2:w-[100%]">
              {imageTwo && (
                <img
                  className="w-[100%] h-[300px] object-cover"
                  src={URL.createObjectURL(imageTwo)}
                  alt=""
                />
              )}
            </div>
          </div>
          <div className="w-[100%] my-[30px]  mx-auto flex justify-between items-center gap-[20px] max-md2:flex-col">
            <div className="w-[50%] mx-auto flex flex-col gap-[10px] max-md2:w-[100%]">
              <span className="font-bold text-[14px] text-[#039674]">
                Image three (optional)
              </span>
              <label>
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={(e) => setImageThree(e.target.files[0])}
                  hidden
                />
                <div className="w-[100%] min-h-[300px] flex flex-col justify-center gap-[10px] text-[#039674]  items-center p-4 mx-auto border border-gray-400 rounded border-dashed cursor-pointer text-center">
                  <div className="flex items-center gap-[10px]">
                    <span className="text-[14px] font-bold ">Upload File</span>
                  </div>

                  <span className="w-[80%] text-[12px] max-lg:w-[70%]">
                    Please ensure images are of high resolution in format jpg,
                    or png. Only upload media that you have the legal rights to.
                  </span>
                  <span className="w-[50%] text-[12px] max-lg:w-[70%]">
                    File size max <b>2MB</b>
                  </span>
                </div>
              </label>
            </div>
            <div className="w-[50%] mt-[20px] mx-auto flex gap-[20px] max-md2:w-[100%]">
              {imageThree && (
                <img
                  className="w-[100%] h-[300px] object-cover"
                  src={URL.createObjectURL(imageThree)}
                  alt=""
                />
              )}
            </div>
          </div>

          {loading ? (
            <span className="text-[14px]">Loading...</span>
          ) : (
            <div className="w-[100%] my-[30px] flex justify-end items-center gap-[20px]">
              <button onClick={() => navigate(-1)} className="text-[14px]">
                Cancel
              </button>
              <button
                onClick={handleCreateEvent}
                className="py-2 px-4 bg-[#039674] text-white flex items-center gap-[5px] rounded-[5px] text-[14px]"
              >
                <span className="text-[14px]">Create Event</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CreateEvent;
