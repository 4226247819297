import React, { useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { apiRequest } from "../../utils/request";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ServerError from "../../pages/ServerError";
import Loading from "../Loading";

function CreateCatageory() {
  const token = sessionStorage.getItem("tID");
  const navigate = useNavigate();
  const [categoryNameEn, setCategoryNameEn] = useState("");
  const [categoryNameAm, setCategoryNameAm] = useState("");
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(false);

  const handleCreateCategory = async () => {
    try {
      setLoading(true);
      await apiRequest
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/gallery_category_api/create_gallery_category`,
          { name_en: categoryNameEn, name_am: categoryNameAm },
          {
            headers: {
              get_create_gallery_category_api:
                process.env.REACT_APP_GET_CREATE_GALLERY_CATEGORY_API,
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          setLoading(false);
          window.location.href = "/gallery";
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setLoading(true);
            sessionStorage.clear();
            window.location.href = "/login";
          }
          setLoading(false);
          toast.error(error.response.data.Message);
        });
    } catch (error) {
      setServerError(true);
    }
  };

  if (serverError) return <ServerError />;
  return (
    <div className="w-[90%] mx-auto my-[30px] p-2 bg-white shadow-md rounded-[10px]">
      <ToastContainer theme="light" />
      <div className="w-[90%] my-[20px] mx-auto">
        <div className="my-[20px] sm:px-0 flex items-center gap-[10px] text-[#039674]">
          <IoArrowBack
            className="text-[24px]   cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <span className="text-base font-bold">
            Enter category information
          </span>
        </div>
        {/* form */}
        <div className="w-[100%] my-[50px]">
          <div className="flex flex-col justify-between  gap-[10px]">
            <div className="w-[50%] my-[20px] flex flex-col gap-[10px] max-md1:w-[100%]">
              <span className="font-bold text-[14px] text-[#039674]">
                English Name <span className="text-red-600">*</span>
              </span>
              <input
                type="text"
                onChange={(e) => setCategoryNameEn(e.target.value)}
                className="py-2 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px]"
              />
            </div>

            <div className="w-[50%] my-[20px] flex flex-col gap-[10px] max-md1:w-[100%]">
              <span className="font-bold text-[14px] text-[#039674]">
                Amharic Name <span className="text-red-600">*</span>
              </span>
              <input
                type="text"
                onChange={(e) => setCategoryNameAm(e.target.value)}
                className="py-2 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px]"
              />
            </div>
          </div>

          {loading ? (
            <Loading
              additionalStyle={"flex justify-between items-center my-[20px]"}
            />
          ) : (
            <div className="w-[100%] my-[30px] flex justify-end items-center gap-[20px]">
              <button onClick={() => navigate(-1)} className="text-[14px]">
                Cancel
              </button>
              <button
                onClick={handleCreateCategory}
                className="py-2 px-4 bg-[#039674] text-white flex items-center gap-[5px] rounded-[5px] text-[14px]"
              >
                <span className="text-[14px]">Create category</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CreateCatageory;
