import React from "react";
import { useNavigate } from "react-router-dom";
import { MdEdit } from "react-icons/md";

function ServiceList({ service }) {
  const navigate = useNavigate();

  return (
    <div className="w-[100%] bg-white mx-auto my-[30px]  max-[1200px]:w-[90%] rounded-[10px]">
      <div className="w-[100%] h-[100%] shadow-lg rounded-[10px] ">
        <div className="w-[95%] min-h-[200px]  mx-auto my-[20px] py-4 flex gap-[30px] max-[1000px]:flex-col max-[1000x]:w-[80%]">
          <div className="w-[30%]  max-lg:w-[100%] max-[1000px]:mx-auto">
            <img
              src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}/PackageImages/${service?.package_img}`}
              className="rounded-[10px] w-[100%]  max-h-[300px] object-cover max-[600px]:h-[250px]"
              alt=""
            />
          </div>

          <div className="flex-1">
            <div className="w-[100%] my-[20px] flex justify-between items-center max-lg:flex-col max-lg:gap-[10px] max-lg:items-start">
              <div className="flex items-center gap-[10px] ">
                <span className="text-[24px] capitalize service-header font-bold text-[#4DB27D] max-[600px]:text-[16px]">
                  {service?.name_en}
                </span>
              </div>
              <div className="flex items-center gap-[10px]">
                <div className="flex items-center gap-[5px]">
                  {service?.status === "active" && (
                    <span className="py-2 px-4 text-[12px] text-white rounded-[5px] bg-[#039674]">
                      {service?.status}
                    </span>
                  )}
                  {service?.status === "inactive" && (
                    <span className="py-2 px-4 text-[12px] text-white rounded-[5px] bg-red-600">
                      {service?.status}
                    </span>
                  )}
                  <button
                    onClick={() =>
                      navigate(`/services/edit-service/${service?._id}`, {
                        state: {
                          service: service,
                        },
                      })
                    }
                    className="py-2 px-4 bg-[#039674] text-white flex items-center gap-[5px] rounded-[5px] max-lg2:py-1"
                  >
                    <MdEdit className="text-[14px]" />
                    <span className="text-[14px]">Edit</span>
                  </button>
                </div>
              </div>
            </div>

            <div>
              <div className="my-[10px] flex flex-col gap-[10px]">
                <span className="font-bold">Menu</span>
                <span className="text-[14px] text-gray-500">
                  {service?.menu_en}
                </span>

                {service?.additional_package_en && (
                  <div className="w-[70%] my-[10px] flex flex-col gap-[10px] max-lg2:w-[100%]">
                    <span className="font-bold">Additional Packages</span>
                    <span className="text-[14px] text-gray-500">
                      {service?.additional_package_en}
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceList;
