import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { IoIosSearch } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import ServiceList from "./ServiceList";
import { useDispatch, useSelector } from "react-redux";
import { fetchServices } from "../../REDUX/Slices/serviceSlice";
import Loading from "../Loading";
import ServerError from "../../pages/ServerError";

function Services() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const serviceState = useSelector((state) => state.services);
  const [searchByName, setSearchByname] = useState("");

  useEffect(() => {
    dispatch(fetchServices());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterServices = serviceState?.services?.filter((service) => {
    const englishName = service?.name_en
      ?.toLowerCase()
      ?.includes(searchByName?.toLowerCase());
    const amharicName = service?.name_am
      ?.toLowerCase()
      ?.includes(searchByName?.toLowerCase());

    return englishName || amharicName;
  });

  if (serviceState?.error) return <ServerError />;

  return (
    <div className="w-[90%] my-[50px] mx-auto">
      <div className="w-[100%] flex justify-between items-center">
        <span className="text-[#039674] font-bold text-[24px] max-lg2:text-[20px]">
          Services
        </span>
        <div className="w-[50%] bg-white flex px-4 items-center gap-[10px] border border-gray-300 rounded-[5px] max-md1:hidden">
          <IoIosSearch className="text-[24px] text-gray-500  max-lg2:text-[14px]" />
          <input
            type="text"
            placeholder="Search by service name..."
            onChange={(e) => setSearchByname(e.target.value)}
            className="py-3 flex-1 bg-transparent text-[14px] outline-none  max-lg2:text-[12px]"
          />
        </div>
        <button
          onClick={() => navigate("/services/create-service")}
          className="py-2 px-4 bg-[#039674] text-white flex items-center gap-[5px] rounded-[5px]"
        >
          <FaPlus className="text-[14px]" />
          <span className="text-[14px]">Service</span>
        </button>
      </div>
      <div className="w-[100%] my-[20px] bg-white  px-4 items-center gap-[10px] border border-gray-300 rounded-[5px] hidden max-md1:flex">
        <IoIosSearch className="text-[24px] text-gray-500  max-lg2:text-[14px]" />
        <input
          type="text"
          placeholder="Search by service name..."
          onChange={(e) => setSearchByname(e.target.value)}
          className="py-3 flex-1 bg-transparent text-[14px] outline-none  max-lg2:text-[12px]"
        />
      </div>

      {serviceState?.loading ? (
        <Loading additionalStyle={"flex justify-center items-center"} />
      ) : (
        <>
          {searchByName === "" ? (
            serviceState?.services?.map((service, index) => {
              return (
                <ServiceList
                  key={index}
                  service={service}
                  searchByName={searchByName}
                />
              );
            })
          ) : filterServices?.length !== 0 ? (
            filterServices?.map((service, index) => {
              return (
                <ServiceList
                  key={index}
                  service={service}
                  searchByName={searchByName}
                />
              );
            })
          ) : (
            <div className="w-[90%] bg-white min-h-[100px] rounded-[10px] mx-auto my-5 flex justify-center items-center">
              <span className="text-[20px] text-gray-500 font-medium">
                No result found
              </span>
            </div>
          )}
        </>
      )}
    </div>
  );
}

export default Services;
