import React, { useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiRequest } from "../../utils/request";
import ServerError from "../../pages/ServerError";
function CreateService() {
  const navigate = useNavigate();
  const token = sessionStorage.getItem("tID");
  const [name_en, setNameEn] = useState("");
  const [name_am, setnameAm] = useState("");
  const [menu_en, setMenuEn] = useState("");
  const [menu_am, setMenuAm] = useState("");
  const [additional_package_en, setAddtionalPackageEn] = useState("");
  const [additional_package_am, setAddtionalPackageAM] = useState("");
  const [packagePolicyEn, setPackagepolicyEn] = useState("");
  const [packagePolicyAm, setPackagepolicyAm] = useState("");
  const [serviceImg, setServiceImg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(false);

  const createService = async () => {
    try {
      const serviceData = new FormData();
      serviceData.append("name_en", name_en);
      serviceData.append("name_am", name_am);
      serviceData.append("menu_en", menu_en);
      serviceData.append("menu_am", menu_am);
      serviceData.append("has_level", "no");
      serviceData.append("package_policy_en", packagePolicyEn);
      serviceData.append("package_policy_am", packagePolicyAm);
      serviceData.append("additional_package_en", additional_package_en);
      serviceData.append("additional_package_am", additional_package_am);
      serviceData.append("package_img", serviceImg);

      setLoading(true);

      await apiRequest
        .post(`/package_api/create_package`, serviceData, {
          headers: {
            get_create_package_api:
              process.env.REACT_APP_GET_CREATE_PACKAGE_API,
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          window.location.href = "/services";
          setLoading(false);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setLoading(true);
            sessionStorage.clear();
            window.location.href = "/login";
          }
          setLoading(false);
          toast.error(error.response.data.Message);
        });
    } catch (error) {
      setServerError(true);
    }
  };

  if (serverError) return <ServerError />;

  return (
    <div className="w-[90%] my-[20px] p-2 mx-auto bg-white rounded-[10px] shadow-lg">
      <ToastContainer theme="light" />
      <div className="w-[90%] my-[20px] mx-auto">
        <div className="px-8 my-[20px] sm:px-0 flex items-center gap-[10px]">
          <IoArrowBack
            className="text-[24px] text-[#039674]  cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <span className="text-base font-bold leading-7 text-[#039674]">
            Enter service information
          </span>
        </div>

        <div className="w-[100%] my-[50px]">
          <div className="my-[20px] flex flex-col gap-[10px]">
            <span className="font-bold text-[14px] text-[#039674]">
              Name (English) <span className="text-red-600">*</span>
            </span>
            <input
              type="text"
              onChange={(e) => setNameEn(e.target.value)}
              className="py-3 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px]"
            />
          </div>
          <div className="my-[20px] flex flex-col gap-[10px]">
            <span className="font-bold text-[14px] text-[#039674]">
              Name (Amharic) <span className="text-red-600">*</span>
            </span>
            <input
              type="text"
              onChange={(e) => setnameAm(e.target.value)}
              className="py-3 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px]"
            />
          </div>

          <div>
            <div className="my-[20px] flex flex-col gap-[10px]">
              <span className="font-bold text-[14px] text-[#039674]">
                Menu (English) <span className="text-red-600">*</span>
              </span>
              <input
                type="text"
                onChange={(e) => setMenuEn(e.target.value)}
                className="py-3 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px]"
              />
            </div>
            <div className="my-[20px] flex flex-col gap-[10px]">
              <span className="font-bold text-[14px] text-[#039674]">
                Menu (Amharic) <span className="text-red-600">*</span>
              </span>
              <input
                type="text"
                onChange={(e) => setMenuAm(e.target.value)}
                className="py-3 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px]"
              />
            </div>
            <div className="w-[100%] flex justify-between gap-[20px]">
              <div className="w-[50%] my-[20px] flex flex-col gap-[10px]">
                <span className="font-bold text-[14px] text-[#039674]">
                  Additional Package (english)
                </span>
                <textarea
                  rows={25}
                  onChange={(e) => setAddtionalPackageEn(e.target.value)}
                  className="w-[100%] py-2 px-4 border border-gray-300 rounded-[5px] outline-none resize-none text-[14px]"
                ></textarea>
              </div>
              <div className="w-[50%] my-[20px] flex flex-col gap-[10px]">
                <span className="font-bold text-[14px] text-[#039674]">
                  Additional Package (amharic)
                </span>
                <textarea
                  rows={25}
                  onChange={(e) => setAddtionalPackageAM(e.target.value)}
                  className="w-[100%] py-2 px-4 border border-gray-300 rounded-[5px] outline-none resize-none text-[14px]"
                ></textarea>
              </div>
            </div>
          </div>

          <div className="w-[100%] flex items-center gap-[10px]">
            <div className="w-[50%] my-[20px] flex flex-col gap-[10px]">
              <span className="font-bold text-[14px] text-[#039674]">
                Package Policy (English) <span className="text-red-600">*</span>
              </span>
              <textarea
                rows={25}
                onChange={(e) => setPackagepolicyEn(e.target.value)}
                className="w-[100%] py-2 px-4 border border-gray-300 rounded-[5px] outline-none resize-none text-[14px]"
              ></textarea>
            </div>
            <div className="w-[50%] my-[20px] flex flex-col gap-[10px]">
              <span className="font-bold text-[14px] text-[#039674]">
                Package Policy (Amharic) <span className="text-red-600">*</span>
              </span>
              <textarea
                rows={25}
                onChange={(e) => setPackagepolicyAm(e.target.value)}
                className="w-[100%] py-2 px-4 border border-gray-300 rounded-[5px] outline-none resize-none text-[14px]"
              ></textarea>
            </div>
          </div>

          <div className="w-[100%] my-[30px]  mx-auto flex justify-between items-center gap-[20px] max-[800px]:flex-col">
            <div className="w-[100%] mx-auto flex flex-col gap-[10px]">
              <span className="font-bold text-[14px] text-[#039674]">
                Service Image <span className="text-red-600">*</span>
              </span>
              <label>
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={(e) => setServiceImg(e.target.files[0])}
                  hidden
                />
                <div className="[w-[100%] min-h-[300px] flex flex-col justify-center gap-[10px] text-[#039674]  items-center p-4 mx-auto border border-gray-400 rounded border-dashed cursor-pointer text-center">
                  <div className="flex items-center gap-[10px]">
                    <span className="text-[14px] font-bold ">Upload File</span>
                  </div>

                  <span className="w-[50%] text-[12px] max-[1000px]:w-[70%]">
                    Please ensure images are of high resolution in format jpg,
                    or png. Only upload media that you have the legal rights to.
                  </span>
                  <span className="w-[50%] text-[12px] max-[1000px]:w-[70%]">
                    File size max <b>2MB</b>
                  </span>
                </div>
              </label>
            </div>
            <div className="w-[50%] mt-[20px] mx-auto flex gap-[20px] max-[800px]:w-[80%]">
              {serviceImg && (
                <img
                  className="w-[100%] h-[300px] object-cover"
                  src={URL.createObjectURL(serviceImg)}
                  alt=""
                />
              )}
            </div>
          </div>
        </div>

        <div className="w-[100%] flex justify-end items-center gap-[20px]">
          <button onClick={() => navigate(-1)} className="text-[14px]">
            Cancel
          </button>
          <button
            onClick={createService}
            className={
              loading
                ? "py-2 px-4 bg-[#039674] text-white flex items-center gap-[5px] rounded-[5px] text-[14px] cursor-not-allowed"
                : "py-2 px-4 bg-[#039674] text-white flex items-center gap-[5px] rounded-[5px] text-[14px]"
            }
          >
            <span className="text-[14px]">Create Service</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default CreateService;
