import React, { useState } from "react";
import { FaLock } from "react-icons/fa6";
import { useParams } from "react-router-dom";
import { apiRequest } from "../utils/request";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ServerError from "./ServerError";
import Loading from "../components/Loading";

function ResetPassword() {
  const { id, token } = useParams();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(false);

  const resetPassword = async () => {
    try {
      if (password === confirmPassword) {
        setLoading(true);
        await apiRequest
          .put(
            `/user_api/reset_password_user/${id}/${token}`,
            { password },
            {
              headers: {
                "Content-Type": "application/json",
                get_users_reset_api: process.env.REACT_APP_GET_USERS_RESET_API,
              },
            }
          )
          .then(() => {
            setLoading(false);
            window.location.href = "/login";
          })
          .catch((error) => {
            setLoading(false);

            toast.error(error.response.data.Message);
          });
      } else {
        toast.error("password and confirm password don't match");
      }
    } catch (error) {
      setLoading(false);

      setServerError(true);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      resetPassword();
    }
  };

  if (serverError) return <ServerError />;
  return (
    <div className="bg-[#F6FBFF] w-[100%] min-h-[100vh] flex items-center justify-center rounded-[10px] shadow-xl">
      <ToastContainer theme="light" />
      <div className="w-[30%] bg-white min-h-[400px] flex flex-col justify-center items-center  max-lg2:w-[50%] max-lg:w-[70%] max-md1:w-[90%] max-sm1:w-[95%]">
        <div className="w-[150px] h-[150px]">
          <img src={require("../Images/logo2.png")} alt="" />
        </div>
        <div className="w-[80%] mx-auto flex flex-col">
          <span className="text-[#03014C] text-[20px] font-bold">
            Reset Password
          </span>
        </div>
        <div className="w-[80%] my-[30px] mx-auto flex flex-col gap-[20px]">
          <div className="w-[100%] bg-white flex px-4 items-center gap-[10px] border border-gray-300 rounded-[5px]">
            <FaLock className=" text-gray-500  max-[1500px]:text-[14px]" />
            <input
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={handleKeyPress}
              className="py-4 flex-1 bg-transparent text-[14px] outline-none  max-[1500px]:text-[10px]"
            />
          </div>

          <div className="w-[100%] bg-white flex px-4 items-center gap-[10px] border border-gray-300 rounded-[5px]">
            <FaLock className=" text-gray-500  max-[1500px]:text-[14px]" />
            <input
              type="password"
              placeholder="Confirm password"
              onChange={(e) => setConfirmPassword(e.target.value)}
              onKeyPress={handleKeyPress}
              className="py-4 flex-1 bg-transparent text-[14px] outline-none  max-[1500px]:text-[10px]"
            />
          </div>

          <div className="w-[100%]">
            <button
              onClick={resetPassword}
              disabled={loading}
              className={
                loading
                  ? "w-[100%] h-[100%] bg-[#039674] p-4 text-white text-[14px]  shadow-xl rounded-[5px] cursor-not-allowed"
                  : "w-[100%] h-[100%] bg-[#039674] p-4 text-white text-[14px]  shadow-xl rounded-[5px]"
              }
            >
              {loading ? (
                <Loading
                  additionalStyle={
                    "flex justify-center  items-center my-[10px]"
                  }
                />
              ) : (
                "Reset Password"
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;
