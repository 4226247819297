import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import { MdEdit } from "react-icons/md";
import { apiRequest } from "../../utils/request";
import ServerError from "../../pages/ServerError";
import Loading from "../Loading";

function ReservationDetail() {
  const { id } = useParams();
  const token = sessionStorage.getItem("tID");
  const navigate = useNavigate();
  const [findReservation, setFindReservation] = useState({});
  const [packageReservation, setPackageReservation] = useState({});
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(false);

  useEffect(() => {
    setLoading(true);
    apiRequest
      .get(`/reservation_api/get_reservation/${id}`, {
        headers: {
          get_detail_reservation_api:
            process.env.REACT_APP_GET_DETAIL_RESERVATION_API,
          Authorization: `Token ${token}`,
        },
      })
      .then((res) => {
        setLoading(false);
        setFindReservation(res.data);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.status === 401) {
          sessionStorage.clear();
          window.location.href = "/login";
        }
        setServerError(true);
      });
  }, [id, token]);

  useEffect(() => {
    try {
      if (findReservation?.package) {
        apiRequest
          .get(`/package_api/get_package/${findReservation?.package}`, {
            headers: {
              Authorization: `bearer ${token}`,
              get_detail_package_api:
                process.env.REACT_APP_GET_DETAIL_PACKAGE_API,
            },
          })
          .then((res) => {
            setPackageReservation(res.data);
          })
          .catch((error) => {
            if (error?.response?.status === 500) {
              setServerError(true);
            }
          });
      }
    } catch (error) {
      setServerError(true);
    }
  }, [findReservation?.package, token]);

  const handleTimeConvert = (timeString) => {
    if (!timeString) {
      return "";
    }
    const [hours, minutes] = timeString?.split(":");

    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);

    const formattedTime = date?.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });

    return formattedTime;
  };

  if (serverError) return <ServerError />;
  return loading ? (
    <Loading additionalStyle={"flex justify-center items-center my-[20px]"} />
  ) : (
    <div className="w-[90%] my-[50px] mx-auto bg-white p-4">
      <div className="w-[100%]  my-[20px] sm:px-0 flex justify-between items-center">
        <div className="flex items-center gap-[10px]">
          <IoArrowBack
            className="text-[24px] hover:text-[#039674]  cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <span className="text-base font-semibold leading-7 text-gray-900">
            Reservation Information
          </span>
        </div>

        {findReservation?.status !== "accepted" &&
          findReservation?.status !== "rejected" && (
            <button
              onClick={() =>
                navigate(`/reservations/edit-reservation/${id}`, {
                  state: {
                    reservation: findReservation,
                  },
                })
              }
              className="py-2 px-4 max-sm1:hidden bg-[#039674] text-white flex items-center gap-[5px] rounded-[5px]"
            >
              <MdEdit className="text-[14px]" />
              <span className="text-[14px]">Edit Reservation</span>
            </button>
          )}
      </div>

      <div className="mt-6 border-t border-gray-100">
        <dl className="divide-y divide-gray-100">
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-[16px] max-lg2:text-sm font-bold leading-6 text-gray-900">
              Full name
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {findReservation?.name}
            </dd>
          </div>

          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-[16px] max-lg2:text-sm font-bold leading-6 text-gray-900">
              Category
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {findReservation?.category}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-[16px] max-lg2:text-sm font-bold leading-6 text-gray-900">
              Email address
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {findReservation?.email}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-[16px] max-lg2:text-sm font-bold leading-6 text-gray-900">
              Primary Number
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {findReservation?.primary_phone}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-[16px] max-lg2:text-sm font-bold leading-6 text-gray-900">
              Secondary Number
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {findReservation?.secondary_phone
                ? findReservation?.secondary_phone
                : "-"}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-[16px] max-lg2:text-sm font-bold leading-6 text-gray-900">
              Guests
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {findReservation?.num_of_ppls}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-[16px] max-lg2:text-sm font-bold leading-6 text-gray-900">
              Package
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {packageReservation?.name_en}
            </dd>
          </div>

          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-[16px] max-lg2:text-sm font-bold leading-6 text-gray-900">
              Additional Packages
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {packageReservation?.additional_package_en
                ? packageReservation?.additional_package_en
                : "-"}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-[16px] max-lg2:text-sm font-bold leading-6 text-gray-900">
              Reservation Date
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {new Date(
                findReservation?.reservation_date
              )?.toLocaleDateString()}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-[16px] max-lg2:text-sm font-bold leading-6 text-gray-900">
              Reservation Time
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {handleTimeConvert(findReservation?.reservation_time)}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-[16px] max-lg2:text-sm font-bold leading-6 text-gray-900">
              Created Date
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {new Date(findReservation?.timestamp)?.toLocaleDateString()}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-[16px] max-lg2:text-sm font-bold leading-6 text-gray-900">
              Created Time
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {new Date(findReservation?.timestamp)?.toLocaleTimeString()}
            </dd>
          </div>
          <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
            <dt className="text-[16px] max-lg2:text-sm font-bold leading-6 text-gray-900">
              Status
            </dt>
            <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
              {findReservation?.status}
            </dd>
          </div>
          {findReservation?.rejection_reason && (
            <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
              <dt className="text-sm font-medium leading-6 text-gray-900">
                Rejection Reason
              </dt>
              <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">
                {findReservation?.rejection_reason}
              </dd>
            </div>
          )}
        </dl>
      </div>
      <div className="w-[100%] h-[1px] bg-gray-100 my-[20px]" />
      {findReservation?.status !== "accepted" &&
        findReservation?.status !== "rejected" && (
          <button
            onClick={() =>
              navigate(`/reservations/edit-reservation/${id}`, {
                state: {
                  reservation: findReservation,
                },
              })
            }
            className="w-[100%] py-3 px-4 text-center bg-[#039674] text-white  justify-center font-bold items-center gap-[5px] rounded-[5px] hidden max-sm1:flex"
          >
            <MdEdit className="text-[14px]" />
            <span className="text-[14px]">Edit Reservation</span>
          </button>
        )}
    </div>
  );
}

export default ReservationDetail;
