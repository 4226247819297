import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequest } from "../../utils/request";

// const token = sessionStorage.getItem("tID");

export const fetchServices = createAsyncThunk("services/fetchServices", () => {
  return apiRequest
    .get(`/package_api/get_package`, {
      headers: {
        "Content-Type": "application/json",
        get_package_api: process.env.REACT_APP_GET_PACKAGE_API,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      if (error.response.status === 401) {
        sessionStorage.clear();
        window.location.href = "/login";
      }
    });
});

const initialState = {
  services: [],
  loading: false,
  error: false,
};

const serviceSlice = createSlice({
  name: "SERVICES",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchServices.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchServices.fulfilled, (state, action) => {
      state.loading = false;
      state.services = action.payload;
      state.error = false;
    });
    builder.addCase(fetchServices.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const serviceAction = serviceSlice.actions;

export default serviceSlice;
