import React from "react";
import { FaPlus } from "react-icons/fa6";
import ReservationTable from "./ReservationTable";
import { useNavigate } from "react-router-dom";
function Reservation() {
  const navigate = useNavigate();
  return (
    <div className="w-[90%] my-[50px] mx-auto z-[-1]">
      <div className="w-[100%] flex justify-between items-center">
        <span className="text-[#039674] font-bold text-[18px] max-lg2:text-[18px]">
          Reservations
        </span>
        <button
          onClick={() => navigate("/reservations/create-reservation")}
          className="py-2 px-4 bg-[#039674] text-white flex items-center gap-[5px] rounded-[5px]"
        >
          <FaPlus className="text-[14px]" />
          <span className="text-[14px] max-lg2:text-[10px]">Reservation</span>
        </button>
      </div>
      <ReservationTable />
    </div>
  );
}

export default Reservation;
