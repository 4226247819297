import React, { useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiRequest } from "../../utils/request";
import ServerError from "../../pages/ServerError";

function EditServices() {
  const location = useLocation();
  const { service } = location.state;
  const token = sessionStorage.getItem("tID");
  const { id } = useParams();
  const navigate = useNavigate();

  const [name_en, setNameEn] = useState(service ? service?.name_en : "");
  const [name_am, setnameAm] = useState(service ? service?.name_am : "");
  const [menu_en, setMenuEn] = useState(service ? service?.menu_en : "");
  const [menu_am, setMenuAm] = useState(service ? service?.menu_am : "");
  const [additional_package_en, setAddtionalPackageEn] = useState(
    service ? service?.additional_package_en : ""
  );
  const [additional_package_am, setAddtionalPackageAM] = useState(
    service ? service?.additional_package_am : ""
  );
  const [packagePolicyEn, setPackagepolicyEn] = useState(
    service ? service?.package_policy_en : ""
  );
  const [packagePolicyAm, setPackagepolicyAm] = useState(
    service ? service?.package_policy_am : ""
  );
  const [status, setStatus] = useState("");
  const [serviceImg, setServiceImg] = useState(null);
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [editFiled, setEditField] = useState("");

  const editService = async () => {
    try {
      const serviceData = new FormData();
      serviceData.append("name_en", name_en);
      serviceData.append("name_am", name_am);
      serviceData.append("menu_en", menu_en);
      serviceData.append("menu_am", menu_am);
      serviceData.append("has_level", "no");
      serviceData.append("package_policy_en", packagePolicyEn);
      serviceData.append("package_policy_am", packagePolicyAm);
      serviceData.append("additional_package_en", additional_package_en);
      serviceData.append("additional_package_am", additional_package_am);
      serviceData.append("package_img", serviceImg);
      serviceData.append("status", status);

      setLoading(true);

      await apiRequest
        .put(`/package_api/update_package/${id}`, serviceData, {
          headers: {
            get_update_package_api:
              process.env.REACT_APP_GET_UPDATE_PACKAGE_API,
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          window.location.href = "/services";
          setLoading(false);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setLoading(true);
            sessionStorage.clear();
            window.location.href = "/login";
          }
          setLoading(false);
          toast.error(error.response.data.Message);
        });
    } catch (error) {
      setServerError(true);
    }
  };

  if (serverError) return <ServerError />;
  return (
    <div className="w-[90%] my-[20px] mx-auto p-2">
      <ToastContainer theme="light" />
      <div className="w-[100%]  p-2">
        <div className="w-[100%] p-2 mx-auto bg-white shadow-lg rounded-[20px] sm:w-[95%]">
          <div className="w-[90%] mt-[50px] mx-auto flex justify-between items-center max-md1:flex-col max-md1:items-start max-md1:gap-[20px]">
            <div>
              <span className="text-[20px] text-[#039674] font-bold">
                Update Service Information
              </span>
            </div>
            <div className="flex items-center gap-[20px] justify-end">
              <button className="text-[14px]" onClick={() => navigate(-1)}>
                Cancel
              </button>
              <button
                onClick={editService}
                className={
                  loading
                    ? "bg-[#039674] text-white py-1 px-4 text-[14px] rounded-[5px] cursor-not-allowed"
                    : "bg-[#039674] text-white py-1 px-4 text-[14px] rounded-[5px]"
                }
              >
                Update
              </button>
            </div>
          </div>
          <div>
            <div className="w-[100%] mx-auto p-2 min-h-[70vh]  rounded-[10px]">
              <div className="w-[95%] flex flex-col justify-between h-[100%]  mx-auto">
                <div className="w-[50%] mt-[30px]  flex flex-col gap-[10px] max-md1:w-[100%]">
                  <span className="font-bold text-[#039674]">
                    Which filed do you want update?
                  </span>
                  <select
                    onChange={(e) => setEditField(e.target.value)}
                    className="py-3 px-4 border border-gray-400 rounded-[5px] text-[14px] outline-none"
                  >
                    <option>Select field to edit?</option>
                    <option>English name</option>
                    <option>Amharic name</option>
                    <option>Menu (English)</option>
                    <option>Menu (Amharic)</option>
                    <option>Additional Package (English)</option>
                    <option>Additional Package (Amharic)</option>
                    <option>Package Policy (English)</option>
                    <option>Package Policy (Amharic)</option>
                    <option>Service Image</option>
                    <option>Status</option>
                  </select>
                </div>
                {editFiled === "English name" && (
                  <div className="w-[50%] my-[30px]  flex flex-col gap-[10px]  max-md1:w-[100%]">
                    <span className="font-bold text-[#039674] text-[14px]">
                      English Name
                    </span>
                    <input
                      onChange={(e) => setNameEn(e.target.value)}
                      type="text"
                      value={name_en}
                      className="py-3 px-4 border border-gray-400 rounded-[5px]  text-[14px] outline-none"
                    />
                  </div>
                )}
                {editFiled === "Amharic name" && (
                  <div className="w-[50%] my-[30px]  flex flex-col gap-[10px]  max-md1:w-[100%]">
                    <span className="font-bold text-[#039674] text-[14px]">
                      Amharic Name
                    </span>
                    <input
                      onChange={(e) => setnameAm(e.target.value)}
                      type="text"
                      value={name_am}
                      className="py-3 px-4 border border-gray-400 rounded-[5px]  text-[14px] outline-none"
                    />
                  </div>
                )}
                {editFiled === "Menu (English)" && (
                  <div className="w-[50%] my-[30px]  flex flex-col gap-[10px]  max-md1:w-[100%]">
                    <span className="font-bold text-[#039674] text-[14px]">
                      Menu (English)
                    </span>
                    <textarea
                      value={menu_en}
                      onChange={(e) => setMenuEn(e.target.value)}
                      className="py-3 px-4 border border-gray-400 rounded-[5px]  text-[14px] outline-none resize-none"
                      rows={10}
                    ></textarea>
                  </div>
                )}
                {editFiled === "Menu (Amharic)" && (
                  <div className="w-[50%]  my-[30px]  flex flex-col gap-[10px]  max-md1:w-[100%]">
                    <span className="font-bold text-[#039674] text-[14px]">
                      Menu (Amharic)
                    </span>
                    <textarea
                      value={menu_am}
                      onChange={(e) => setMenuAm(e.target.value)}
                      className="py-3 px-4 border border-gray-400 rounded-[5px]  text-[14px] outline-none resize-none"
                      rows={10}
                    ></textarea>
                  </div>
                )}
                {editFiled === "Additional Package (English)" && (
                  <div className="w-[50%]  my-[30px] mt-[30px]  flex flex-col gap-[10px]  max-md1:w-[100%]">
                    <span className="font-bold text-[#039674] text-[14px]">
                      Additional Package (English)
                    </span>
                    <textarea
                      value={additional_package_en}
                      onChange={(e) => setAddtionalPackageEn(e.target.value)}
                      className="py-3 px-4 border border-gray-400 rounded-[5px]  text-[14px] outline-none resize-none"
                      rows={10}
                    ></textarea>
                  </div>
                )}
                {editFiled === "Additional Package (Amharic)" && (
                  <div className="w-[50%]  my-[30px] mt-[30px]  flex flex-col gap-[10px]  max-md1:w-[100%]">
                    <span className="font-bold text-[#039674] text-[14px]">
                      Additional Package (Amharic)
                    </span>
                    <textarea
                      value={additional_package_am}
                      onChange={(e) => setAddtionalPackageAM(e.target.value)}
                      className="py-3 px-4 border border-gray-400 rounded-[5px]  text-[14px] outline-none resize-none"
                      rows={10}
                    ></textarea>
                  </div>
                )}
                {editFiled === "Package Policy (English)" && (
                  <div className="w-[50%]  my-[30px] mt-[30px]  flex flex-col gap-[10px]  max-md1:w-[100%]">
                    <span className="font-bold text-[#039674] text-[14px]">
                      Package Policy (English)
                    </span>
                    <textarea
                      value={packagePolicyEn}
                      onChange={(e) => setPackagepolicyEn(e.target.value)}
                      className="py-3 px-4 border border-gray-400 rounded-[5px]  text-[14px] outline-none resize-none"
                      rows={15}
                    ></textarea>
                  </div>
                )}
                {editFiled === "Package Policy (Amharic)" && (
                  <div className="w-[50%]  my-[30px] mt-[30px]  flex flex-col gap-[10px]  max-md1:w-[100%]">
                    <span className="font-bold text-[#039674] text-[14px]">
                      Package Policy (Amharic)
                    </span>
                    <textarea
                      value={packagePolicyAm}
                      onChange={(e) => setPackagepolicyAm(e.target.value)}
                      className="py-3 px-4 border border-gray-400 rounded-[5px]  text-[14px] outline-none resize-none"
                      rows={15}
                    ></textarea>
                  </div>
                )}

                {editFiled === "Service Image" && (
                  <div className="w-[100%] my-[30px]  mx-auto flex justify-between items-center gap-[20px] max-md2:flex-col">
                    <div className="w-[100%] mx-auto flex flex-col gap-[10px]">
                      <span className="font-bold text-[14px] text-[#039674]">
                        Service Image
                      </span>
                      <label>
                        <input
                          type="file"
                          accept="image/png, image/jpeg"
                          onChange={(e) => setServiceImg(e.target.files[0])}
                          hidden
                        />
                        <div className="[w-[100%] min-h-[300px] flex flex-col justify-center gap-[10px] text-[#039674]  items-center p-4 mx-auto border border-gray-400 rounded border-dashed cursor-pointer text-center">
                          <div className="flex items-center gap-[10px]">
                            {/* <BsFillCloudUploadFill /> */}
                            <span className="text-[14px] font-bold ">
                              Upload File
                            </span>
                          </div>

                          <span className="w-[50%] text-[12px] max-[1000px]:w-[70%]">
                            Please ensure images are of high resolution in
                            format jpg, or png. Only upload media that you have
                            the legal rights to.
                          </span>
                        </div>
                      </label>
                    </div>
                    <div className="w-[50%] mt-[20px] mx-auto flex gap-[20px] max-[800px]:w-[80%]">
                      {serviceImg && (
                        <img
                          className="w-[100%] h-[300px] object-cover"
                          src={URL.createObjectURL(serviceImg)}
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                )}

                {editFiled === "Status" && (
                  <div className="w-[50%]  my-[30px] mt-[30px] mx-6 flex flex-col gap-[10px]">
                    <span className="font-bold text-[#039674] text-[14px]">
                      Status
                    </span>
                    <select
                      onChange={(e) => setStatus(e.target.value)}
                      className="py-3 px-4 border border-gray-400 rounded-[5px]  text-[14px] outline-none"
                    >
                      <option>Change status</option>
                      <option value={"active"}>active</option>
                      <option value={"inactive"}>inactive</option>
                    </select>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditServices;
