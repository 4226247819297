import React, { useEffect, useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchCatagories } from "../../REDUX/Slices/categorySlice";
import { apiRequest } from "../../utils/request";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ServerError from "../../pages/ServerError";
import Loading from "../Loading";

function EditGallery() {
  const location = useLocation();
  const { image } = location.state;
  const token = sessionStorage.getItem("tID");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const categoryState = useSelector((state) => state.category);
  const [editField, setEditFiled] = useState("");
  const [galleryImage, setGalleryImage] = useState(null);
  const [categoryField, setCategoryField] = useState(
    image ? image?.category?._id : ""
  );
  const [status, setStatus] = useState("");
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(false);

  useEffect(() => {
    dispatch(fetchCatagories());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditGallery = async () => {
    try {
      const galleryData = new FormData();
      galleryData.append("image", galleryImage);
      galleryData.append("category", categoryField);
      galleryData.append("status", status);

      setLoading(true);
      await apiRequest
        .put(
          `${process.env.REACT_APP_BACKEND_URL}/gallery_api/update_gallery/${id}`,
          galleryData,
          {
            headers: {
              get_update_gallery_api:
                process.env.REACT_APP_GET_UPDATE_GALLERY_API,
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          setLoading(false);
          window.location.href = "/gallery";
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setLoading(true);
            sessionStorage.clear();
            window.location.href = "/login";
          }
          setLoading(false);
          toast.error(error.response.data.Message);
        });
    } catch (error) {
      setServerError(true);
    }
  };

  const filterCategory = categoryState?.categories?.filter(
    (category) => category?.name_en !== "All"
  );

  if (serverError) return <ServerError />;
  return (
    <div className="w-[90%] my-[30px] mx-auto   gap-[10px]">
      <div className="w-[100%] mx-auto p-2 bg-white shadow-md rounded-[10px]">
        <ToastContainer theme="light" />
        <div className="w-[90%] my-[20px] mx-auto">
          <div className="px-8 my-[20px] sm:px-0 flex justify-between items-center gap-[10px] text-[#039674]">
            <div className="flex items-center gap-[10px]">
              <IoArrowBack
                className="text-[24px]   cursor-pointer"
                onClick={() => navigate(-1)}
              />
              <span className="text-base font-bold">Edit Gallery</span>
            </div>
            <div></div>
          </div>

          <div className="w-[100%] my-[50px]">
            <div className="flex flex-col justify-between  gap-[10px]">
              <div className="w-[50%] my-[20px] flex flex-col gap-[10px]">
                <span className="font-bold text-[14px] text-[#039674]">
                  Which field do you want to edit?{" "}
                  <span className="text-red-600">*</span>
                </span>
                <select
                  onChange={(e) => setEditFiled(e.target.value)}
                  className="py-2 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px]"
                >
                  <option></option>
                  <option value={"category"}>Category</option>
                  <option value={"image"}>Image</option>
                  <option value={"status"}>Status</option>
                </select>
              </div>

              {editField === "category" && (
                <div className="w-[50%] my-[20px] flex flex-col gap-[10px]">
                  <div className="my-[20px] flex flex-col gap-[10px]">
                    <span className="font-bold text-[14px] text-[#039674]">
                      Change Category <span className="text-red-600">*</span>
                    </span>
                    <select
                      value={categoryField}
                      onChange={(e) => setCategoryField(e.target.value)}
                      className="py-2 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px]"
                    >
                      <option></option>
                      {filterCategory?.map((category, index) => {
                        return (
                          <option key={index} value={category?._id}>
                            {category?.name_en}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              )}

              {editField === "image" && (
                <div className="w-[100%] my-[30px]  mx-auto flex   items-center gap-[20px] max-[800px]:flex-col">
                  <div className="w-[100%] mx-auto flex flex-col gap-[10px]">
                    <span className="font-bold text-[14px] text-[#039674]">
                      Change Image <span className="text-red-600">*</span>
                    </span>
                    <label>
                      <input
                        type="file"
                        accept="image/png, image/jpeg"
                        onChange={(e) => setGalleryImage(e.target.files[0])}
                        hidden
                      />
                      <div className="[w-[100%] min-h-[300px] flex flex-col justify-center gap-[10px] text-[#039674]  items-center p-4 mx-auto border border-gray-400 rounded border-dashed cursor-pointer text-center">
                        <div className="flex items-center gap-[10px]">
                          <span className="text-[14px] font-bold ">
                            Upload File
                          </span>
                        </div>

                        <span className="w-[50%] text-[12px] max-[1000px]:w-[70%]">
                          Please ensure images are of high resolution in format
                          jpg, or png. Only upload media that you have the legal
                          rights to.
                        </span>
                        <span className="w-[50%] text-[12px] max-[1000px]:w-[70%]">
                          File size max <b>2MB</b>
                        </span>
                      </div>
                    </label>
                  </div>
                  <div className="w-[50%] mt-[20px] mx-auto flex gap-[20px] max-[800px]:w-[80%]">
                    {galleryImage && (
                      <img
                        className="w-[100%] h-[300px] object-cover"
                        src={URL.createObjectURL(galleryImage)}
                        alt=""
                      />
                    )}
                  </div>
                </div>
              )}

              {editField === "status" && (
                <div className="w-[50%] my-[20px] flex flex-col gap-[10px]">
                  <span className="font-bold text-[14px] text-[#039674]">
                    Status <span className="text-red-600">*</span>
                  </span>
                  <select
                    onChange={(e) => setStatus(e.target.value)}
                    className="py-2 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px]"
                  >
                    <option value={""}></option>
                    <option value={"active"}>Active</option>
                    <option value={"inactive"}>Inactive</option>
                  </select>
                </div>
              )}
            </div>

            {loading ? (
              <Loading
                additionalStyle={"my-[20px] flex justify-center items-center"}
              />
            ) : (
              <div className="w-[100%] my-[30px] flex justify-end items-center gap-[20px]">
                <button onClick={() => navigate(-1)} className="text-[14px]">
                  Cancel
                </button>
                <button
                  onClick={handleEditGallery}
                  className="py-2 px-4 bg-[#039674] text-white flex items-center gap-[5px] rounded-[5px] text-[14px]"
                >
                  <span className="text-[14px]">Update</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditGallery;
