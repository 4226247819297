import React from "react";
import { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ServerError from "./ServerError";
import { apiRequest } from "../utils/request";
import { useNavigate } from "react-router-dom";
import Loading from "../components/Loading";

function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(false);

  const handleForgotPassword = async () => {
    try {
      setLoading(true);
      await apiRequest
        .post(
          "/user_api/forgot_password_user",
          { email },
          {
            headers: {
              "Content-Type": "application/json",
              get_users_forgot_api: process.env.REACT_APP_GET_USERS_FORGOT_API,
            },
          }
        )
        .then(() => {
          setLoading(false);
          toast.success("A reset link has been sent to your email.");
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error.response.data.Message);
        });
    } catch (error) {
      setLoading(false);
   
      setServerError(true);
    }
  };

  if (serverError) return <ServerError />;
  return (
    <div className="w-[100%] min-h-[100vh]">
      <div className="bg-[#F6FBFF] w-[100%] min-h-[100vh] flex items-center justify-center rounded-[10px] shadow-xl">
        <ToastContainer theme="light" />
        <div className="w-[30%] bg-white min-h-[400px] flex flex-col justify-center items-center max-lg2:w-[50%] max-lg:w-[70%] max-md1:w-[90%] max-sm1:w-[95%]">
          <div className="w-[150px] h-[150px]">
            <img src={require("../Images/logo2.png")} alt="" />
          </div>
          <div className="w-[80%] mx-auto flex flex-col">
            <span className="text-[#03014C] text-[20px] font-bold">
              Forgot your password?
            </span>
            <span className="my-[5px] text-[12px] text-gray-500">
              Enter your email to recieve password reset link
            </span>
          </div>
          <div className="w-[80%] my-[30px] mx-auto flex flex-col gap-[20px]">
            <div className="w-[100%] bg-white flex px-4 items-center gap-[10px] border border-gray-300 rounded-[5px]">
              <input
                type="text"
                placeholder="Enter email"
                onChange={(e) => setEmail(e.target.value)}
                className="py-4 flex-1 bg-transparent text-[14px] outline-none  max-[1500px]:text-[10px]"
              />
            </div>

            <div className="w-[100%]">
              <button
                onClick={handleForgotPassword}
                disabled={loading}
                className={
                  loading
                    ? "w-[100%] h-[100%] bg-[#039674] p-4 text-white text-[14px]  flex justify-center items-center shadow-xl rounded-[5px] cursor-not-allowed"
                    : "w-[100%] h-[100%] bg-[#039674] p-4 text-white text-[14px] flex justify-center items-center  shadow-xl rounded-[5px]"
                }
              >
                {loading ? (
                  <Loading
                    additionalStyle={
                      "flex justify-center items-center my-[10px]"
                    }
                  />
                ) : (
                  "Recieve reset link"
                )}
              </button>
            </div>
            <div className="w-[100%] my-[20px] h-[1px] bg-gray-300" />

            <div className="w-[100%]">
              <button
                onClick={() => navigate("/login")}
                disabled={loading}
                className={
                  "w-[100%] h-[100%] bg-white border border-[#039674] p-4 text-[#039674] text-[14px]  shadow-md rounded-[5px]"
                }
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
