import React, { useState } from "react";
import { contentManagementLinks } from "../utils/data";
import { MdGroups2, MdOutlineLogout } from "react-icons/md";
import { apiRequest } from "../utils/request";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ServerError from "../pages/ServerError";
import { PiUsersFourLight } from "react-icons/pi";

function Sidebar() {
  const role = sessionStorage?.getItem("role");
  const [serverError, setServerError] = useState(false);

  const handleLogout = async () => {
    try {
      await apiRequest
        ?.get("/user_api/logout")
        .then(() => {
          sessionStorage.clear();
          window.location.href = "/login";
        })
        .catch((error) => {
          toast.error(error.response.data.Message);
        });
    } catch (error) {
      setServerError(true);
    }
  };
  if (serverError) return <ServerError />;
  return (
    <div className="w-[17%] min-h-[100vh] h-[100vh] overflow-y-auto hide-scroll-bar bg-[#131313] fixed left-0 top-0 max-lg1:hidden">
      <ToastContainer theme="light" />
      <div className="w-[90%] my-[20px] mx-auto">
        <div className="w-[100%]  flex flex-col justify-center items-center gap-[10px]">
          <img
            src={require("../Images/logo2.png")}
            className="w-[80px]"
            alt=""
          />
          <div className="text-[12px] flex items-center gap-[5px] font-bold">
            <span className="text-white">TOTOT</span>
            <span className="text-[#4DB27D]">ADMINISTRATION</span>
          </div>
        </div>
        <div className="w-[90%] my-[10px] mx-auto min-h-[85vh] flex flex-col justify-between">
          <div className="w-[100%] mt-[30px] flex flex-col overflow-y-auto">
            <div>
              <span className="text-[#4DB27D] font-bold  max-lg2:text-[14px]">
                Management
              </span>
              <div className="w-[80%] my-[20px] mx-auto flex flex-col gap-[2px]">
                <a
                  href={"/"}
                  className="w-[100%] text-[#969696] p-2 flex items-center gap-[10px] hover:bg-gray-800"
                >
                  <MdGroups2 className="text-[24px]" />
                  <span className="text-[14px]  max-lg2:text-[12px]">
                    Reservations
                  </span>
                </a>

                {role === "Super_Admin" && (
                  <a
                    href={"/users"}
                    className="w-[100%] text-[#969696] p-2 flex items-center gap-[10px] hover:bg-gray-800"
                  >
                    <PiUsersFourLight className="text-[24px]" />
                    <span className="text-[14px]  max-lg2:text-[12px]">
                      Users
                    </span>
                  </a>
                )}
              </div>
            </div>
            <div className="overflow-y-auto">
              <span className="text-[#4DB27D] font-bold  max-lg2:text-[14px]">
                CMS
              </span>
              <div className="w-[80%] my-[20px] mx-auto flex flex-col gap-[5px]">
                {contentManagementLinks.map((link, index) => {
                  return (
                    <a
                      href={link.path}
                      key={index}
                      className="text-[#969696] p-2 flex items-center gap-[10px] hover:bg-gray-800"
                    >
                      {link.icon}

                      <span className="text-[14px]  max-lg2:text-[12px]">
                        {link.name}
                      </span>
                    </a>
                  );
                })}
              </div>
            </div>
          </div>

          <div className="w-[80%] my-[10px] mx-auto flex flex-col gap-[10px] py-4">
            <button
              onClick={handleLogout}
              className="text-[#969696] p-2 flex items-center gap-[10px] hover:bg-gray-800"
            >
              <MdOutlineLogout className="text-[24px] max-lg2:text-[16px]" />
              <span className="text-[14px] max-lg2:text-[12px]">Logout</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
