import React, { useEffect } from "react";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import UserTable from "./UserTable";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers } from "../../REDUX/Slices/userSlice";
function Users() {
  const userID = sessionStorage.getItem("uID");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userList = useSelector((state) => state.user);
  useEffect(() => {
    dispatch(fetchUsers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const findUser = userList?.users?.find((user) => user?._id === userID);
  return (
    <div className="w-[90%] my-[50px] mx-auto">
      <div className="w-[100%] flex justify-between items-center">
        <span className="text-[#039674] font-bold text-[24px]">Users</span>

        {findUser?.role === "Super_Admin" && (
          <button
            onClick={() => navigate("/users/createuser")}
            className="py-2 px-4 bg-[#039674] text-white flex items-center gap-[5px] rounded-[5px]"
          >
            <FaPlus className="text-[14px]" />
            <span className="text-[14px]">User</span>
          </button>
        )}
      </div>
      <UserTable />
    </div>
  );
}

export default Users;
