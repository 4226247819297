import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiRequest } from "../../utils/request";
import { reservationAction } from "../../REDUX/Slices/reservationSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ServerError from "../../pages/ServerError";
import { useParams } from "react-router-dom";
import Loading from "../Loading";

function ReservationJustification({ editPage }) {
  const dispatch = useDispatch();
  const { id } = useParams();
  const token = sessionStorage.getItem("tID");
  const reservationState = useSelector((state) => state.reservations);
  const [justificationReason, setJustificationReason] = useState("");
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(false);

  const rejectReservation = async () => {
    try {
      setLoading(true);
      await apiRequest
        .put(
          `/reservation_api/update_reservation/${reservationState?.reservationID}`,
          {
            status: "rejected",
            rejection_reason: justificationReason,
          },
          {
            headers: {
              get_update_reservation_api:
                process.env.REACT_APP_GET_UPDATE_RESERVATION_API,
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          setLoading(false);
          dispatch(
            reservationAction.setRejectReservation({
              reservationModal: false,
              reservationID: null,
            })
          );
          editPage
            ? (window.location.href = `/reservations/${id}`)
            : window.location.reload();
        })
        .catch((error) => {
          setLoading(false);
          if (error.response.status === 401) {
            sessionStorage.clear();
            window.location.href = "/login";
          }

          toast.error(error.response.data.Message);
        });
    } catch (error) {
      setServerError(true);
    }
  };

  if (serverError) return <ServerError />;
  return (
    <div className="relative z-[20]">
      <ToastContainer theme="light" />
      <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity"></div>

      <div className="fixed flex justify-center items-center inset-0 z-10 w-[100%] overflow-y-auto">
        <div className="w-[50%]  flex  items-end justify-center p-4 text-center sm:items-center sm:p-0  max-lg2:w-[70%] max-lg:w-[80%] max-md1:w-[90%] max-sm1:w-[95%]">
          <div className="w-[100%] relative transform overflow-hidden rounded-lg text-left shadow-xl transition-all sm:my-8 sm:w-full ">
            <div className=" min-h-[300px] bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
              <div className="sm:flex sm:items-start">
                <div className="w-[100%] mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                  <h3
                    className="text-base font-bold leading-6 text-[#039674]"
                    id="modal-title"
                  >
                    Reject Reservation
                  </h3>

                  <div className="w-[100%]">
                    <div className="w-[100%] my-[20px] flex flex-col gap-[10px]">
                      <span className="font-bold text-left text-[14px]">
                        Reason <span className="text-red-600">*</span>
                      </span>
                      <textarea
                        rows={10}
                        onChange={(e) => setJustificationReason(e.target.value)}
                        className="w-[100%] py-2 px-4 border border-gray-300 rounded-[5px] outline-none resize-none text-[14px]"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray-50 px-4 py-3 flex items-center justify-end gap-[10px] sm:flex-row sm:px-6">
              <button
                onClick={() =>
                  dispatch(
                    reservationAction.setRejectReservation({
                      reservationModal: false,
                      reservationID: null,
                    })
                  )
                }
                className="inline-flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
              >
                Cancel
              </button>

              {loading ? (
                <Loading additionalStyle={"flex justify-end items-center"} />
              ) : (
                <button
                  onClick={rejectReservation}
                  className="py-2 px-4 bg-[#039674] text-white flex items-center gap-[5px] rounded-[5px]"
                >
                  <span className="text-[14px]">Reject reservation</span>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReservationJustification;
