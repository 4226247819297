import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import { apiRequest } from "../../utils/request";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ServerError from "../../pages/ServerError";
import Loading from "../Loading";

function CreateMenu() {
  const navigate = useNavigate();
  const token = sessionStorage.getItem("tID");
  const [menuImage, setMenuImage] = useState(null);
  const [nameEn, setNameEn] = useState("");
  const [nameAm, setNameAm] = useState("");
  const [descriptionEn, setDescriptionEn] = useState("");
  const [descriptionAm, setDescriptionAm] = useState("");
  const [loading, setLoading] = useState("");
  const [serverError, setServerError] = useState(false);

  const handleCreateMenu = async () => {
    try {
      const menuData = new FormData();
      menuData.append("name_en", nameEn);
      menuData.append("name_am", nameAm);
      menuData.append("description_am", descriptionAm);
      menuData.append("description_en", descriptionEn);
      menuData.append("menu_image", menuImage);

      setLoading(true);

      await apiRequest
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/menu_api/create_menu`,
          menuData,
          {
            headers: {
              get_create_menu_api: process.env.REACT_APP_GET_CREATE_MENU_API,
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          window.location.href = "/menu";
          setLoading(false);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setLoading(true);
            sessionStorage.clear();
            window.location.href = "/login";
          }
          setLoading(false);
          toast.error(error.response.data.Message);
        });
    } catch (error) {
      setServerError(true);
    }
  };

  if (serverError) return <ServerError />;
  return (
    <div className="w-[90%] mx-auto my-[30px] p-2 bg-white shadow-md rounded-[10px]">
      <ToastContainer theme="light" />
      <div className="w-[90%] my-[20px] mx-auto">
        <div className="my-[20px] sm:px-0 flex items-center gap-[10px] text-[#039674]">
          <IoArrowBack
            className="text-[24px]   cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <span className="text-base font-bold">Enter menu information</span>
        </div>

        <div className="w-[100%] my-[50px]">
          <div className="flex justify-between items-center gap-[10px] max-md2:flex-col max-md2:items-start">
            <div className="w-[50%] my-[20px] flex flex-col gap-[10px] max-md2:w-[100%]">
              <span className="font-bold text-[14px] text-[#039674]">
                Name in english <span className="text-red-600">*</span>
              </span>
              <input
                type="text"
                onChange={(e) => setNameEn(e.target.value)}
                className="py-2 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px]"
              />
            </div>
            <div className="w-[50%] my-[20px] flex flex-col gap-[10px] max-md2:w-[100%]">
              <span className="font-bold text-[14px] text-[#039674]">
                Name in amharic <span className="text-red-600">*</span>
              </span>
              <input
                type="text"
                onChange={(e) => setNameAm(e.target.value)}
                className="py-2 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px]"
              />
            </div>
          </div>
          <div className="flex justify-between items-center gap-[10px] max-md2:flex-col">
            <div className="w-[50%] my-[20px] flex flex-col gap-[10px] max-md2:w-[100%]">
              <span className="font-bold text-[14px] text-[#039674]">
                Description in english <span className="text-red-600">*</span>
              </span>
              <textarea
                rows={10}
                onChange={(e) => setDescriptionEn(e.target.value)}
                className=" py-2 px-4 border border-gray-300 rounded-[5px] outline-none resize-none text-[14px]"
              ></textarea>
            </div>
            <div className="w-[50%] my-[20px] flex flex-col gap-[10px] max-md2:w-[100%]">
              <span className="font-bold text-[14px] text-[#039674]">
                Description in amharic <span className="text-red-600">*</span>
              </span>
              <textarea
                rows={10}
                onChange={(e) => setDescriptionAm(e.target.value)}
                className="py-2 px-4 border border-gray-300 rounded-[5px] outline-none resize-none text-[14px]"
              ></textarea>
            </div>
          </div>

          <div className="w-[100%] my-[30px]  mx-auto flex justify-between items-center gap-[20px] max-md2:flex-col">
            <div className="w-[50%] mx-auto flex flex-col gap-[10px] max-md2:w-[100%]">
              <span className="font-bold text-[14px] text-[#039674]">
                Menu Image <span className="text-red-600">*</span>
              </span>
              <label>
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={(e) => setMenuImage(e.target.files[0])}
                  hidden
                />
                <div className="[w-[100%] min-h-[300px] flex flex-col justify-center gap-[10px] text-[#039674]  items-center p-4 mx-auto border border-gray-400 rounded border-dashed cursor-pointer text-center">
                  <div className="flex items-center gap-[10px]">
                    <span className="text-[14px] font-bold ">Upload File</span>
                  </div>

                  <span className="w-[80%] text-[12px] max-[1000px]:w-[70%]">
                    Please ensure images are of high resolution in format jpg,
                    or png. Only upload media that you have the legal rights to.
                  </span>
                  <span className="w-[50%] text-[12px] max-[1000px]:w-[70%]">
                    File size max <b>2MB</b>
                  </span>
                </div>
              </label>
            </div>
            <div className="w-[50%] mt-[20px] mx-auto flex gap-[20px] max-md2:w-[100%]">
              {menuImage && (
                <img
                  className="w-[100%] h-[300px] object-contain"
                  src={URL.createObjectURL(menuImage)}
                  alt=""
                />
              )}
            </div>
          </div>

          {loading ? (
            <Loading
              additionalStyle={"flex justify-center  items-center my-[20px]"}
            />
          ) : (
            <div className="w-[100%] my-[30px] flex justify-end items-center gap-[20px]">
              <button onClick={() => navigate(-1)} className="text-[14px]">
                Cancel
              </button>
              <button
                onClick={handleCreateMenu}
                className="py-2 px-4 bg-[#039674] text-white flex items-center gap-[5px] rounded-[5px] text-[14px]"
              >
                <span className="text-[14px]">Create Menu</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CreateMenu;
