import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import EventList from "./EventList";
import { IoIosArrowBack, IoIosArrowForward, IoIosSearch } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { fetchEvents } from "../../REDUX/Slices/eventSlice";
import { useDebounce } from "use-debounce";
import ServerError from "../../pages/ServerError";

function Events() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const eventState = useSelector((state) => state?.event);
  const [pageNum, setPageNum] = useState(1);
  const [sortingNum, setSortingNum] = useState(-1);
  const [eventName, setEventName] = useState("");
  const [debouncedTitle] = useDebounce(eventName, 500);

  useEffect(() => {
    dispatch(
      fetchEvents({
        page: pageNum,
        sort: sortingNum,
        title: debouncedTitle,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNum, sortingNum, debouncedTitle]);

  const handlePrevious = () => {
    if (pageNum <= 1) {
      setPageNum(1);
    } else {
      setPageNum(pageNum - 1);
    }
  };

  const handleNext = () => {
    if (pageNum >= eventState?.events?.totalPages) {
      setPageNum(eventState?.events?.totalPages);
    } else {
      setPageNum(pageNum + 1);
    }
  };

  if (eventState?.error) return <ServerError />;
  return (
    <div className="w-[90%] my-[50px] mx-auto">
      {eventState?.loading ? (
        <div></div>
      ) : (
        eventState?.events?.events?.length > 0 &&
        eventState?.events?.totalPages && (
          <div className="w-[100%] flex justify-end items-center mb-[20px] gap-5">
            <button
              onClick={handlePrevious}
              className="mx-1 w-[30px] h-[30px] flex justify-center items-center cursor-pointer text-white border border-[#039674] bg-[#039674] rounded-[50%] hover:bg-transparent hover:text-[#039674] transition duration-500 max-[400px]:px-2 outline-none"
            >
              <IoIosArrowBack />
            </button>
            <span className="text-gray-600 font-semibold">
              {eventState?.events?.currentPage} of{" "}
              {eventState?.events?.totalPages}
            </span>

            <button
              onClick={handleNext}
              className={
                "mx-1 w-[30px] h-[30px] flex justify-center items-center cursor-pointer text-white border border-[#039674] bg-[#039674] rounded-[50%] hover:bg-transparent hover:text-[#039674] transition duration-500 max-[400px]:px-2 outline-none"
              }
            >
              <IoIosArrowForward />
            </button>

            <div className="flex items-center gap-2">
              <select
                value={sortingNum}
                onChange={(e) => setSortingNum(e?.target?.value)}
                className="py-2 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px] font-medium"
              >
                <option value={-1}>Latest</option>
                <option value={1}>Oldest</option>
              </select>
            </div>
          </div>
        )
      )}
      <div className="w-[100%] flex justify-between items-center">
        <span className="text-[#039674] font-bold text-[18px]">Events</span>
        <div className="w-[50%] bg-white flex px-4 items-center gap-[10px] border border-gray-300 rounded-[5px] max-md1:hidden">
          <IoIosSearch className="text-[24px] text-gray-500  max-[1500px]:text-[14px]" />
          <input
            type="text"
            placeholder="Search by event title..."
            onChange={(e) => setEventName(e?.target?.value)}
            className="py-2 flex-1 bg-transparent text-[14px] outline-none  max-[1500px]:text-[10px]"
          />
        </div>
        <button
          onClick={() => navigate("/events/create-event")}
          className="py-2 px-4 bg-[#039674] text-white flex items-center gap-[5px] rounded-[5px]"
        >
          <FaPlus className="text-[14px]" />
          <span className="text-[14px]">Event</span>
        </button>
      </div>
      <div className="w-[100%] my-[20px] bg-white hidden px-4 items-center gap-[10px] border border-gray-300 rounded-[5px] max-md1:flex">
        <IoIosSearch className="text-[24px] text-gray-500  max-[1500px]:text-[14px]" />
        <input
          type="text"
          placeholder="Search by event title..."
          onChange={(e) => setEventName(e?.target?.value)}
          className="py-2 flex-1 bg-transparent text-[14px] outline-none  max-[1500px]:text-[10px]"
        />
      </div>
      <EventList
        filteredEvents={eventState?.events?.events}
        page={pageNum}
        sort={sortingNum}
        title={debouncedTitle}
      />
    </div>
  );
}

export default Events;
