import React, { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import MenuList from "./MenuList";
import { IoIosSearch } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { fetchMenus } from "../../REDUX/Slices/menuSlice";
import ServerError from "../../pages/ServerError";

function Menus() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const menuList = useSelector((state) => state.menu);
  const [menuName, setMenuName] = useState("");
  useEffect(() => {
    dispatch(fetchMenus());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredMenu = menuList?.menus?.filter((menu) => {
    return menu?.name_en?.toLowerCase().includes(menuName?.toLowerCase());
  });

  if (menuList?.error) return <ServerError />;
  return (
    <div className="w-[90%] my-[50px] mx-auto">
      <div className="w-[100%] flex justify-between items-center">
        <span className="text-[#039674] font-bold text-[18px]">Menus</span>
        <div className="w-[50%] bg-white flex px-4 items-center gap-[10px] border border-gray-300 rounded-[5px] max-md1:hidden">
          <IoIosSearch className="text-[24px] text-gray-500  max-[1500px]:text-[14px]" />
          <input
            type="text"
            placeholder="Search by menu name..."
            onChange={(e) => setMenuName(e.target.value)}
            className="py-3 flex-1 bg-transparent text-[14px] outline-none  max-[1500px]:text-[10px]"
          />
        </div>
        <button
          onClick={() => navigate("/menu/create-menu")}
          className="py-2 px-4 bg-[#039674] text-white flex items-center gap-[5px] rounded-[5px]"
        >
          <FaPlus className="text-[14px]" />
          <span className="text-[14px]">Menu</span>
        </button>
      </div>

      <div className="w-[50%] my-[20px] bg-white hidden px-4 items-center gap-[10px] border border-gray-300 rounded-[5px] max-md1:flex max-md1:w-[100%]">
        <IoIosSearch className="text-[24px] text-gray-500  max-[1500px]:text-[14px]" />
        <input
          type="text"
          placeholder="Search by menu name..."
          onChange={(e) => setMenuName(e.target.value)}
          className="py-3 flex-1 bg-transparent text-[14px] outline-none  max-[1500px]:text-[10px]"
        />
      </div>
      <MenuList filteredMenu={filteredMenu} menuName={menuName} />
    </div>
  );
}

export default Menus;
