import React, { useEffect, useState } from "react";
import { IoArrowBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchCatagories } from "../../REDUX/Slices/categorySlice";
import ServerError from "../../pages/ServerError";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiRequest } from "../../utils/request";
import Loading from "../Loading";

function CreateGalleryImage() {
  const token = sessionStorage.getItem("tID");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const categoryState = useSelector((state) => state.category);
  const [galleryImage, setGalleryImage] = useState(null);
  const [categoryField, setCategoryField] = useState("");
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(false);

  useEffect(() => {
    dispatch(fetchCatagories());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCreateGallery = async () => {
    try {
      const galleryData = new FormData();
      galleryData.append("image", galleryImage);
      galleryData.append("category", categoryField);

      setLoading(true);
      await apiRequest
        .post(
          `${process.env.REACT_APP_BACKEND_URL}/gallery_api/create_gallery`,
          galleryData,
          {
            headers: {
              get_create_gallery_api:
                process.env.REACT_APP_GET_CREATE_GALLERY_API,
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          setLoading(false);
          window.location.href = "/gallery";
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setLoading(true);
            sessionStorage.clear();
            window.location.href = "/login";
          }
          setLoading(false);
          toast.error(error.response.data.Message);
        });
    } catch (error) {
      setServerError(true);
    }
  };

  const filterCategory = categoryState?.categories?.filter(
    (category) => category?.name_en !== "All"
  );
  if (serverError) return <ServerError />;

  return (
    <div className="w-[90%] mx-auto my-[30px] p-2 bg-white shadow-md rounded-[10px]">
      <ToastContainer theme="light" />
      <div className="w-[90%] my-[20px] mx-auto">
        <div className="my-[20px] sm:px-0 flex items-center gap-[10px] text-[#039674]">
          <IoArrowBack
            className="text-[24px]   cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <span className="text-base font-bold">Upload Gallery</span>
        </div>

        <div className="w-[100%] my-[50px]">
          <div className="w-[50%] my-[20px] flex flex-col gap-[10px] max-md1:w-[100%]">
            <span className="font-bold text-[14px] text-[#039674]">
              Category <span className="text-red-600">*</span>
            </span>
            <select
              onChange={(e) => setCategoryField(e.target.value)}
              className="py-2 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px]"
            >
              <option value={""}></option>
              {filterCategory?.map((category, index) => {
                return (
                  <option key={index} value={category?._id}>
                    {category?.name_en}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="w-[100%] my-[30px]  mx-auto flex justify-between items-center gap-[20px] max-md1:flex-col">
            <div className="w-[50%] mx-auto flex flex-col gap-[10px] max-md1:w-[100%]">
              <span className="font-bold text-[14px] text-[#039674]">
                Gallery Image <span className="text-red-600">*</span>
              </span>
              <label>
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={(e) => setGalleryImage(e.target.files[0])}
                  hidden
                />
                <div className="[w-[100%] min-h-[300px] flex flex-col justify-center gap-[10px] text-[#039674]  items-center p-4 mx-auto border border-gray-400 rounded border-dashed cursor-pointer text-center">
                  <div className="flex items-center gap-[10px]">
                    <span className="text-[14px] font-bold ">Upload File</span>
                  </div>

                  <span className="w-[50%] text-[12px] max-lg:w-[70%]">
                    Please ensure images are of high resolution in format jpg,
                    or png. Only upload media that you have the legal rights to.
                  </span>
                  <span className="w-[50%] text-[12px] max-lg:w-[70%]">
                    File size max <b>2MB</b>
                  </span>
                </div>
              </label>
            </div>
            <div className="w-[50%] mt-[20px] mx-auto flex gap-[20px] max-md1:w-[100%] ">
              {galleryImage && (
                <img
                  className="w-[100%] h-[300px] object-contain"
                  src={URL.createObjectURL(galleryImage)}
                  alt=""
                />
              )}
            </div>
          </div>

          {loading ? (
            <Loading
              additionalStyle={"my-[20px] flex justify-center items-center"}
            />
          ) : (
            <div className="w-[100%] my-[30px] flex justify-end items-center gap-[20px]">
              <button onClick={() => navigate(-1)} className="text-[14px]">
                Cancel
              </button>
              <button
                onClick={handleCreateGallery}
                className="py-2 px-4 bg-[#039674] text-white flex items-center gap-[5px] rounded-[5px] text-[14px]"
              >
                <span className="text-[14px]">Upload</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CreateGalleryImage;
