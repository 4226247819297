import React, { useState } from "react";
import { FaLock, FaUser } from "react-icons/fa6";
import { apiRequest } from "../utils/request";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ServerError from "./ServerError";
import Loading from "../components/Loading";

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(false);

  const handleLogin = async (e) => {
    e?.preventDefault();
    try {
      setLoading(true);
      await apiRequest
        .post(
          "/user_api/login",
          { username, password },
          {
            headers: {
              "Content-Type": "application/json",
              get_detail_users_api: process.env.REACT_APP_GET_DETAIL_USERS_API,
            },
          }
        )
        .then((res) => {
          setLoading(false);
          sessionStorage.setItem("tID", res?.data?.token);
          sessionStorage.setItem("uID", res?.data?.user?._id);
          sessionStorage.setItem("role", res?.data?.user?.role);
          window.location.href = "/";
        })
        .catch((error) => {
          setLoading(false);
          toast.error(error?.response?.data?.Message);
        });
    } catch (error) {
      setLoading(false);
      setServerError(true);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  if (serverError) return <ServerError />;
  return (
    <div className="bg-[#F6FBFF] w-[100%] min-h-[100vh] flex items-center justify-center rounded-[10px] shadow-xl">
      <ToastContainer theme="light" />
      <div className="w-[30%] bg-white min-h-[400px] rounded-[10px] flex flex-col justify-center items-center max-lg1:w-[40%] max-lg:w-[60%] max-md1:w-[90%]">
        <div className="w-[150px] h-[150px] max-lg2:w-[100px] max-lg2:h-[100px]">
          <img src={require("../Images/logo2.png")} alt="" />
        </div>
        <div className="w-[80%] mx-auto flex flex-col">
          <span className="text-[#03014C] text-[20px] font-bold max-lg2:text-[16px]">
            Login to your Account
          </span>
          <span className="my-[5px] text-[12px] text-gray-500 max-lg2:text-[10px]">
            Enter to your email and password continue.
          </span>
        </div>
        <div className="w-[80%] my-[30px] mx-auto flex flex-col gap-[20px]">
          <div className="w-[100%] bg-white flex px-4 items-center gap-[10px] border border-gray-300 rounded-[5px]">
            <FaUser className=" text-gray-500  max-lg2:text-[14px]" />
            <input
              type="text"
              placeholder="Enter username"
              onChange={(e) => setUsername(e.target.value)}
              onKeyPress={handleKeyPress}
              className="py-4 flex-1 bg-transparent text-[14px] outline-none  max-lg2:text-[12px] max-lg2:py-3"
            />
          </div>

          <div className="w-[100%] bg-white flex px-4 items-center gap-[10px] border border-gray-300 rounded-[5px]">
            <FaLock className=" text-gray-500  max-[1500px]:text-[14px]" />
            <input
              type="password"
              placeholder="Enter password"
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={handleKeyPress}
              className="py-4 flex-1 bg-transparent text-[14px] outline-none  max-lg2:text-[12px] max-lg2:py-3"
            />
          </div>
          <div className="w-[100%]  flex justify-end items-end">
            <a
              href="/forgotpassword"
              className="hover:underline text-[12px] text-gray-500"
            >
              Forgot password?
            </a>
          </div>
          <div className="w-[100%]">
            {loading ? (
              <Loading
                additionalStyle={"my-[10px] flex justify-center items-center"}
              />
            ) : (
              <button
                onClick={handleLogin}
                disabled={loading}
                className={
                  "w-[100%] h-[100%] bg-[#039674] p-4 text-white text-[14px]  shadow-xl rounded-[5px] max-lg2:py-2 max-lg2:text-[10px]"
                }
              >
                Login to Continue
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
