import React from "react";
import DashboardNavbar from "./DashboardNavbar";
import { Routes, Route } from "react-router-dom";
import Reservation from "./Reservation/Reservation";
import Users from "./Users/Users";
import Events from "./Events/Events";
import CreateUser from "./Users/CreateUser";
import CreateReservation from "./Reservation/CreateReservation";
import EditUser from "./Users/EditUser";
import CreateEvent from "./Events/CreateEvent";
import EditEvents from "./Events/EditEvents";
import Menus from "./Menu/Menus";
import CreateMenu from "./Menu/CreateMenu";
import EditMenu from "./Menu/EditMenu";
import Gallery from "./Gallery/Gallery";
import CreateGalleryImage from "./Gallery/CreateGalleryImage";
import Services from "./Services/Services";
import CreateService from "./Services/CreateService";
import EditServices from "./Services/EditServices";
import CreateCategory from "./Category/Create_Catageory";
import ReservationDetail from "./Reservation/ReservationDetail";
import EventDetail from "./Events/EventDetail";
import EditGallery from "./Gallery/EditGallery";
import EditCategory from "./Category/EditCategory";
import EditReservation from "./Reservation/EditReservation";
import AddServiceLevel from "./Services/AddServiceLevel";
import PageNotFound from "./PageNotFound";
import { useSelector } from "react-redux";

function Content() {
  const role = sessionStorage.getItem("role");
  const applicationState = useSelector((state) => state.application);

  return (
    <div
      className={
        "w-[83%] min-h-[100vh]  absolute left-[17%]  max-lg1:w-[100%] max-lg1:left-0"
      }
    >
      <DashboardNavbar />
      <div
        className={
          applicationState?.isNavbarOpened
            ? "w-[100%] min-h-[100vh]  absolute left-[20%] max-lg1:w-[100%] max-lg1:left-0"
            : "w-[100%] min-h-[100vh]  absolute  max-lg1:w-[100%]"
        }
      >
        <Routes>
          <Route path="/" element={<Reservation />} />
          <Route
            path="/reservations/create-reservation"
            element={<CreateReservation />}
          />
          <Route
            path="/reservations/edit-reservation/:id"
            element={<EditReservation />}
          />
          <Route path="/reservations/:id" element={<ReservationDetail />} />
          {role === "Super_Admin" && (
            <Route path="/users" element={<Users />} />
          )}
          {role === "Super_Admin" && (
            <Route path="/users/createuser" element={<CreateUser />} />
          )}
          {role === "Super_Admin" && (
            <Route path="/users/edit-user/:id" element={<EditUser />} />
          )}
          <Route path="/events" element={<Events />} />
          <Route path="/events/create-event" element={<CreateEvent />} />
          <Route path="/events/:id" element={<EventDetail />} />
          <Route path="/events/edit-event/:id" element={<EditEvents />} />
          <Route path="/menu" element={<Menus />} />
          <Route path="/menu/create-menu" element={<CreateMenu />} />
          <Route path="/menu/edit-menu/:id" element={<EditMenu />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route
            path="/gallery/create-gallery"
            element={<CreateGalleryImage />}
          />
          <Route
            path="/gallery/create-gallery-category"
            element={<CreateCategory />}
          />

          <Route path="/gallery/edit-gallery/:id" element={<EditGallery />} />
          <Route path="/gallery/edit-gategory" element={<EditCategory />} />
          <Route path="/services" element={<Services />} />
          <Route path="/services/create-service" element={<CreateService />} />
          <Route path="/services/addlevel/:id" element={<AddServiceLevel />} />
          <Route path="/services/edit-service/:id" element={<EditServices />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </div>
    </div>
  );
}

export default Content;
