import React, { useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ServerError from "../../pages/ServerError";
import { FaTrash } from "react-icons/fa6";
import { FaCheckCircle } from "react-icons/fa";
import { apiRequest } from "../../utils/request";
import { fetchMenus } from "../../REDUX/Slices/menuSlice";
import Loading from "../Loading";

function MenuList({ filteredMenu, menuName }) {
  const token = sessionStorage.getItem("tID");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const menuList = useSelector((state) => state.menu);
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(false);

  useEffect(() => {
    dispatch(fetchMenus());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const removeMenu = async (id) => {
    try {
      setLoading(true);
      await apiRequest
        .delete(`/menu_api/delete_menu/${id}`, {
          headers: {
            "Content-Type": "application/json",
            get_delete_menu_api: process.env.REACT_APP_GET_DELETE_MENU_API,
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          setLoading(false);
          dispatch(fetchMenus());
        })
        .catch((error) => {
          setLoading(false);

          if (error.response.status === 401) {
            sessionStorage.clear();
            window.location.href = "/login";
          }
        });
    } catch (error) {
      setLoading(false);

      setServerError(true);
    }
  };

  if (menuList?.error || serverError) return <ServerError />;

  return (
    <div className="w-[100%] my-[30px] grid grid-cols-3 gap-4">
      {menuName === "" ? (
        menuList?.menus?.map((menu, index) => {
          return (
            <div
              key={index}
              className="col-span-3 p-2 bg-white shadow-md rounded-[10px] relative"
            >
              <div className="w-[90%] mx-auto py-2 flex gap-[30px] max-lg:flex-col">
                <div className="w-[30%] h-[300px] max-lg:w-[60%] max-lg:mx-auto max-md1:w-[100%]">
                  <img
                    src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}/MenuImages/${menu?.menu_image}`}
                    className="w-[100%] h-[100%] object-cover rounded-[10px]"
                    alt=""
                  />
                </div>
                <div className="w-[70%]  flex flex-col   max-lg:w-[90%] max-lg:mx-auto max-md1:w-[100%]">
                  <div className="my-[20px] flex justify-between items-center">
                    <span className=" font-bold py-2 text-[#039674] flex items-center gap-[10px]">
                      <span className="font-bold capitalize py-2 text-[#039674]">
                        {menu?.name_en} / {menu?.name_am}{" "}
                      </span>
                      <span>
                        {" "}
                        {menu?.popular_menu === "yes" && (
                          <FaCheckCircle className="text-[20px] text-blue-600" />
                        )}
                      </span>
                    </span>

                    <div className="max-lg:hidden">
                      <div className="my-[20px] flex items-center gap-[20px]">
                        <div>
                          {menu?.status === "active" && (
                            <span className="p-2 rounded-[5px] text-[12px] bg-[#039674] text-white">
                              active
                            </span>
                          )}

                          {menu?.status === "inactive" && (
                            <span className="p-2 rounded-[5px] text-[12px] bg-red-600 text-white">
                              inactive
                            </span>
                          )}
                        </div>
                        <div className="flex items-center gap-[20px]">
                          <button
                            onClick={() =>
                              navigate(`/menu/edit-menu/${menu?._id}`, {
                                state: {
                                  menu: menu,
                                },
                              })
                            }
                            className=" border border-[#039674] py-2 px-4 text-[#039674] flex items-center gap-[10px] rounded-[5px]"
                          >
                            <MdEdit className="text-[16px]" />
                            <span className="text-[14px]">Edit</span>
                          </button>
                          <button
                            onClick={() => removeMenu(menu?._id)}
                            className="border border-[#039674] py-2 px-4 text-[#039674] flex items-center gap-[10px] rounded-[5px]"
                          >
                            <FaTrash className="text-[16px]" />
                            <span className="text-[14px]">Remove</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col  gap-[20px] text-[14px] text-gray-500">
                    <span>{menu?.description_en}</span>
                    <span>{menu?.description_am}</span>
                  </div>

                  <div className="hidden max-lg:block">
                    <div className="my-[20px] flex items-center gap-[20px]">
                      <div>
                        {menu?.status === "active" && (
                          <span className="py-3 px-2 rounded-[5px] text-[12px] bg-[#039674] text-white">
                            active
                          </span>
                        )}

                        {menu?.status === "inactive" && (
                          <span className="py-3 px-2 rounded-[5px] text-[12px] bg-red-600 text-white">
                            inactive
                          </span>
                        )}
                      </div>
                      <div className="flex items-center gap-[20px]">
                        <button
                          onClick={() =>
                            navigate(`/menu/edit-menu/${menu?._id}`, {
                              state: {
                                menu: menu,
                              },
                            })
                          }
                          className=" border border-[#039674] py-2 px-4 text-[#039674] flex items-center gap-[10px] rounded-[5px]"
                        >
                          <MdEdit className="text-[16px]" />
                          <span className="text-[14px]">Edit</span>
                        </button>
                        <button
                          onClick={() => removeMenu(menu?._id)}
                          className="border border-[#039674] py-2 px-4 text-[#039674] flex items-center gap-[10px] rounded-[5px]"
                        >
                          <FaTrash className="text-[16px]" />
                          <span className="text-[14px]">Remove</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : menuList?.loading ? (
        <Loading />
      ) : filteredMenu?.length !== 0 ? (
        filteredMenu?.map((menu, index) => {
          return (
            <div
              key={index}
              className="col-span-3 p-2 bg-white shadow-md rounded-[10px] relative"
            >
              <div className="w-[90%] mx-auto py-2 flex gap-[30px]">
                <div className="w-[30%] h-[300px]">
                  <img
                    src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}/MenuImages/${menu?.menu_image}`}
                    className="w-[100%] h-[100%] object-cover rounded-[10px]"
                    alt=""
                  />
                </div>
                <div className="w-[70%]  flex flex-col  gap-[10px]">
                  <div className="my-[20px] flex justify-between items-center">
                    <span className=" font-bold p-2 text-[#039674] flex items-center gap-[10px]">
                      <span className="font-bold p-2 text-[#039674]">
                        {menu?.name_en} / {menu?.name_am}{" "}
                      </span>
                      <span>
                        {" "}
                        {menu?.popular_menu === "yes" && (
                          <FaCheckCircle className="text-[20px] text-blue-600" />
                        )}
                      </span>
                    </span>

                    <div className="max-lg:hidden">
                      <div className="my-[20px] flex items-center gap-[20px]">
                        <div>
                          {menu?.status === "active" && (
                            <span className="p-2 rounded-[5px] text-[12px] bg-[#039674] text-white">
                              active
                            </span>
                          )}

                          {menu?.status === "inactive" && (
                            <span className="p-2 rounded-[5px] text-[12px] bg-red-600 text-white">
                              inactive
                            </span>
                          )}
                        </div>
                        <div className="flex items-center gap-[20px]">
                          <button
                            onClick={() =>
                              navigate(`/menu/edit-menu/${menu?._id}`, {
                                state: {
                                  menu: menu,
                                },
                              })
                            }
                            className=" border border-[#039674] py-2 px-4 text-[#039674] flex items-center gap-[10px] rounded-[5px]"
                          >
                            <MdEdit className="text-[16px]" />
                            <span className="text-[14px]">Edit</span>
                          </button>
                          {loading ? (
                            <Loading
                              additionalStyle={
                                "flex justify-center items-center my-[20px]"
                              }
                            />
                          ) : (
                            <button
                              onClick={() => removeMenu(menu?._id)}
                              className="border border-[#039674] py-2 px-4 text-[#039674] flex items-center gap-[10px] rounded-[5px]"
                            >
                              <FaTrash className="text-[16px]" />
                              <span className="text-[14px]">Remove</span>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col mt-[20px] gap-[20px] text-[14px] text-gray-500">
                    <span>{menu?.description_en}</span>
                    <span>{menu?.description_am}</span>
                  </div>
                  <div className="hidden max-lg:block">
                    <div className="my-[20px] flex items-center gap-[20px]">
                      <div>
                        {menu?.status === "active" && (
                          <span className="p-2 rounded-[5px] text-[12px] bg-[#039674] text-white">
                            active
                          </span>
                        )}

                        {menu?.status === "inactive" && (
                          <span className="p-2 rounded-[5px] text-[12px] bg-red-600 text-white">
                            inactive
                          </span>
                        )}
                      </div>
                      <div className="flex items-center gap-[20px]">
                        <button
                          onClick={() =>
                            navigate(`/menu/edit-menu/${menu?._id}`, {
                              state: {
                                menu: menu,
                              },
                            })
                          }
                          className=" border border-[#039674] py-2 px-4 text-[#039674] flex items-center gap-[10px] rounded-[5px]"
                        >
                          <MdEdit className="text-[16px]" />
                          <span className="text-[14px]">Edit</span>
                        </button>
                        <button
                          onClick={() => removeMenu(menu?._id)}
                          className="border border-[#039674] py-2 px-4 text-[#039674] flex items-center gap-[10px] rounded-[5px]"
                        >
                          <FaTrash className="text-[16px]" />
                          <span className="text-[14px]">Remove</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <div className="w-[90%] bg-white min-h-[100px] rounded-[10px] col-span-3  mx-auto my-5 flex justify-center items-center">
          <span className="text-[20px] text-gray-500 font-medium">
            No result found
          </span>
        </div>
      )}
    </div>
  );
}

export default MenuList;
