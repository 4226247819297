import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchEvents } from "../../REDUX/Slices/eventSlice";
import ServerError from "../../pages/ServerError";
import { apiRequest } from "../../utils/request";
import Loading from "../Loading";

function EventList({ filteredEvents, page, sort, title }) {
  const token = sessionStorage?.getItem("tID");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const eventState = useSelector((state) => state?.event);
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(false);

  useEffect(() => {
    dispatch(fetchEvents({ page, sort, title }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, sort, title]);

  const removeEvent = async (id) => {
    try {
      setLoading(true);
      await apiRequest
        .delete(`/event_api/delete_event/${id}`, {
          headers: {
            get_delete_event_api: process.env.REACT_APP_GET_DELETE_EVENT_API,
            Authorization: `Bearer ${token}`,
          },
        })
        .then(() => {
          setLoading(false);
          dispatch(fetchEvents());
        })
        .catch((error) => {
          setLoading(false);

          if (error.response.status === 401) {
            sessionStorage.clear();
            window.location.href = "/login";
          }
        });
    } catch (error) {
      setLoading(false);
      setServerError(true);
    }
  };
  if (eventState?.error || serverError) return <ServerError />;
  return (
    <div className="w-[100%] my-[30px] ">
      <ToastContainer theme="light" />
      <div className="w-[90%] mx-auto grid grid-cols-3 gap-4 max-lg2:w-[100%]">
        {filteredEvents?.length !== 0 ? (
          filteredEvents?.map((event, index) => {
            return (
              <div
                key={index}
                onClick={() => navigate(`/events/${event?._id}`)}
                className="col-span-3  p-2 bg-white shadow-md rounded-[10px] cursor-pointer"
              >
                <div className="w-[90%] mx-auto py-2 flex gap-[20px] max-md2:flex-col">
                  <div className="w-[30%] h-[300px] max-lg:w-[40%] max-md2:w-[100%]">
                    <img
                      src={`${process.env.REACT_APP_BACKEND_IMAGE_URL}/EventImages/${event?.thumbnail}`}
                      className="w-[100%] h-[100%] object-cover rounded-[10px]"
                      alt=""
                    />
                  </div>
                  <div className="w-[70%] flex flex-col gap-[20px] max-md2:w-[100%]">
                    <div className="my-[10px] flex justify-between items-center gap-[20px] max-md1:flex-col max-md1:items-start">
                      <span className="font-bold capitalize text-[#039674] max-lg2:text-[18px]">
                        {event?.title_en}
                      </span>
                      <div className="flex items-center gap-[10px]">
                        {event?.status === "active" && (
                          <span className="p-2 rounded-[5px] text-[12px] bg-[#039674] text-white max-lg2:text-[10px]">
                            active
                          </span>
                        )}

                        {event?.status === "inactive" && (
                          <span className="p-2 rounded-[5px] text-[12px] bg-red-600 text-white max-lg2:text-[10px]">
                            inactive
                          </span>
                        )}

                        {loading ? (
                          <Loading
                            additionalStyle={
                              "my-[20px] flex justify-center items-center w-[100%]"
                            }
                          />
                        ) : (
                          <div>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                removeEvent(event?._id);
                              }}
                              className="bg-[#039674] py-2 px-4 rounded-[5px] text-[12px] text-white max-lg2:text-[10px]"
                            >
                              Remove
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="flex flex-col gap-[20px] text-[14px] text-gray-500 leading-[30px] max-lg2:text-[12px]">
                      <span>{event?.description_en}</span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="w-[90%] min-h-[100px] col-span-3 rounded-[10px]  bg-white mx-auto my-5 flex justify-center items-center">
            <span className="text-[20px] text-gray-500 font-medium">
              No result found
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default EventList;
