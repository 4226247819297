import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IoArrowBack } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { fetchUsers } from "../../REDUX/Slices/userSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apiRequest } from "../../utils/request";
import ServerError from "../../pages/ServerError";
import Loading from "../Loading";
function CreateUser() {
  const token = sessionStorage.getItem("tID");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userList = useSelector((state) => state.user);
  const [full_name, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [role, setRole] = useState("");
  const [gender, setGender] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [profilePicure, setProfilePicture] = useState(null);
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(false);

  useEffect(() => {
    dispatch(fetchUsers());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let checkUserRole;
  if (!userList.error) {
    checkUserRole = userList?.users?.some(
      (user) => user?.role === "Super_Admin"
    );
  }

  const handleCreateUser = async () => {
    try {
      const userData = new FormData();

      if (password === confirmPassword) {
        userData.append("full_name", full_name);
        userData.append("username", username);
        userData.append("password", password);
        userData.append("email", email);
        userData.append("phone", phoneNumber);
        userData.append("role", role);
        userData.append("gender", gender);
        userData.append("picture", profilePicure);

        setLoading(true);

        await apiRequest
          .post(
            `${process.env.REACT_APP_BACKEND_URL}/user_api/create_user`,
            userData,
            {
              headers: {
                get_user_api: process.env.REACT_APP_GET_USER_API,
                Authorization: `Bearer ${token}`,
              },
            }
          )
          .then(() => {
            setLoading(false);
            window.location.href = "/users";
          })
          .catch((error) => {
            if (error.response.status === 401) {
              setLoading(true);
              sessionStorage.clear();
              window.location.href = "/login";
            }
            setLoading(false);
            toast.error(error.response.data.Message);
          });
      } else {
        setLoading(false);
        toast.error("password and confirm password don't match");
      }
    } catch (error) {
      setLoading(false);
      setServerError(true);
    }
  };
  if (serverError) return <ServerError />;
  return (
    <div className="w-[90%] mx-auto my-[30px] p-2 bg-white shadow-md rounded-[10px]">
      <ToastContainer theme="light" />
      <div className="w-[90%] my-[20px] mx-auto">
        <div className="my-[20px] sm:px-0 flex items-center gap-[10px]">
          <IoArrowBack
            className="text-[24px] text-[#039674]  cursor-pointer"
            onClick={() => navigate(-1)}
          />
          <span className="text-base font-bold leading-7 text-[#039674]">
            Enter user information
          </span>
        </div>
        {/* form */}
        <div className="w-[100%] my-[50px]">
          <div className="my-[20px] flex flex-col gap-[10px]">
            <span className="font-bold text-[14px] text-[#039674]">
              Full Name <span className="text-red-600">*</span>
            </span>
            <input
              type="text"
              onChange={(e) => setFullName(e.target.value)}
              className="py-3 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px]"
            />
          </div>
          <div className="flex justify-between items-center gap-[10px] max-md1:flex-col max-md1:items-start">
            <div className="w-[50%] flex flex-col gap-[10px] max-md1:w-[100%]">
              <span className="font-bold text-[14px] text-[#039674]">
                Email <span className="text-red-600">*</span>
              </span>
              <input
                type="text"
                onChange={(e) => setEmail(e.target.value)}
                className="py-3 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px]"
              />
            </div>
            <div className="w-[50%] flex flex-col gap-[10px] max-md1:w-[100%]">
              <span className="font-bold text-[14px] text-[#039674]">
                Username <span className="text-red-600">*</span>
              </span>
              <input
                type="text"
                onChange={(e) => setUsername(e.target.value)}
                className="py-3 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px]"
              />
            </div>
          </div>
          <div className="my-[20px] flex justify-between items-center gap-[10px]">
            <div className="w-[100%] flex flex-col gap-[10px]">
              <span className="font-bold text-[14px] text-[#039674]">
                Phone Number
              </span>
              <input
                type="text"
                onChange={(e) => setPhoneNumber(e.target.value)}
                className="py-3 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px]"
              />
            </div>
          </div>

          <div className="flex justify-between items-center gap-[10px] max-md1:flex-col max-md1:items-start">
            <div className="w-[50%] flex flex-col gap-[10px] max-md1:w-[100%]">
              <span className="font-bold text-[14px] text-[#039674]">
                Role <span className="text-red-600">*</span>
              </span>
              <select
                onChange={(e) => setRole(e.target.value)}
                className="py-3 px-4 border border-gray-300 bg-transparent rounded-[5px] outline-none text-[14px]"
              >
                <option></option>
                {!checkUserRole && (
                  <option value={"Super_Admin"}>Super Admin</option>
                )}
                <option value={"Admin"}>Admin</option>
              </select>
            </div>

            <div className="w-[50%] flex flex-col gap-[10px] max-md1:w-[100%]">
              <span className="font-bold text-[14px] text-[#039674]">
                Gender <span className="text-red-600">*</span>
              </span>
              <select
                onChange={(e) => setGender(e.target.value)}
                className="py-3 px-4 border border-gray-300 bg-transparent rounded-[5px] outline-none text-[14px]"
              >
                <option></option>
                <option value={"Male"}>Male</option>
                <option value={"Female"}>Female</option>
              </select>
            </div>
          </div>

          <div className="w-[100%] my-[50px] h-[1px] bg-gray-300" />
          <div className="my-[20px] flex justify-between items-center gap-[10px] max-md1:flex-col max-md1:items-start">
            <div className="w-[50%] flex flex-col gap-[10px] max-md1:w-[100%]">
              <span className="font-bold text-[14px] text-[#039674]">
                Password <span className="text-red-600">*</span>
              </span>
              <input
                type="password"
                onChange={(e) => setPassword(e.target.value)}
                className="py-3 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px]"
              />
            </div>
            <div className="w-[50%] flex flex-col gap-[10px] max-md1:w-[100%]">
              <span className="font-bold text-[14px] text-[#039674]">
                Confirm password <span className="text-red-600">*</span>
              </span>
              <input
                type="password"
                onChange={(e) => setConfirmPassword(e.target.value)}
                className="py-3 px-4 border border-gray-300 rounded-[5px] outline-none text-[14px]"
              />
            </div>
          </div>
          <div className="w-[100%] my-[50px] bg-gray-300" />
          <div className="w-[100%] my-[30px]  mx-auto flex justify-between items-center gap-[20px] max-md2:flex-col">
            <div className="w-[50%] mx-auto flex flex-col gap-[10px] max-md2:w-[100%]">
              <span className="font-bold text-[14px] text-[#039674]">
                Profile Picure
              </span>
              <label>
                <input
                  type="file"
                  accept="image/png, image/jpeg"
                  onChange={(e) => setProfilePicture(e.target.files[0])}
                  hidden
                />
                <div className="[w-[100%] min-h-[300px] flex flex-col justify-center gap-[10px] text-[#039674]  items-center p-4 mx-auto border border-gray-400 rounded border-dashed cursor-pointer text-center">
                  <div className="flex items-center gap-[10px]">
                    <span className="text-[14px] font-bold ">Upload File</span>
                  </div>

                  <span className="w-[50%] text-[12px] max-[1000px]:w-[70%]">
                    Please ensure images are of high resolution in format jpg,
                    or png. Only upload media that you have the legal rights to.
                  </span>
                </div>
              </label>
            </div>
            <div className="w-[50%] mt-[20px] mx-auto flex gap-[20px] max-md2:w-[100%]">
              {profilePicure && (
                <img
                  className="w-[100%] h-[300px] object-cover"
                  src={URL.createObjectURL(profilePicure)}
                  alt=""
                />
              )}
            </div>
          </div>

          <div className="w-[100%] my-[50px] flex justify-end items-center gap-[20px]">
            <button onClick={() => navigate(-1)} className="text-[14px]">
              Cancel
            </button>

            {loading ? (
              <Loading
                additionalStyle={"my-[20px] flex justify-center items-center"}
              />
            ) : (
              <button
                onClick={handleCreateUser}
                className="py-3 px-4 bg-[#039674] text-white flex items-center gap-[5px] rounded-[5px] text-[14px]"
              >
                <span className="text-[14px]">Create user</span>
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CreateUser;
