import React from "react";
import Sidebar from "../components/Sidebar";
import Content from "../components/Content";
import { useSelector } from "react-redux";
import ResponsiveSidebar from "../components/ResponsiveSidebar";
function Home() {
  const applicationState = useSelector((state) => state.application);
  return (
    <div className="flex">
      {applicationState?.isNavbarOpened && <ResponsiveSidebar />}
      <Sidebar />
      <Content />
    </div>
  );
}

export default Home;
