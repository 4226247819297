import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequest } from "../../utils/request";

const token = sessionStorage.getItem("tID");

export const fetchUsers = createAsyncThunk("user/fetchUsers", () => {
  return apiRequest
    .get(`/user_api/get_user`, {
      headers: {
        "Content-Type": "application/json",
        get_users_api: process.env.REACT_APP_GET_USERS_API,
        Authorization: `Bearer ${token}`,
      },
    })
    .then((res) => res.data)
    .catch((error) => {
      if (error.response.status === 401) {
        sessionStorage.clear();
        window.location.href = "/login";
      }
    });
});

const initialState = {
  users: [],
  loading: false,
  error: false,
};

const userSlice = createSlice({
  name: "USER",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(fetchUsers.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchUsers.fulfilled, (state, action) => {
      state.loading = false;
      state.users = action.payload;
      state.error = false;
    });
    builder.addCase(fetchUsers.rejected, (state) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export const userAction = userSlice.actions;

export default userSlice;
