import React, { useEffect } from "react";
import { FaPlus } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import GalleryList from "./GalleryList";
import { MdEdit } from "react-icons/md";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ServerError from "../../pages/ServerError";
import {
  categoryAction,
  fetchCatagories,
} from "../../REDUX/Slices/categorySlice";
import { useDispatch, useSelector } from "react-redux";

function Gallery() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const categoryState = useSelector((state) => state.category);

  useEffect(() => {
    dispatch(fetchCatagories());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (categoryState?.error) return <ServerError />;
  return (
    <div className="w-[90%] my-[50px] mx-auto">
      <ToastContainer theme="light" />
      <div className="w-[100%] flex justify-between items-center max-sm1:flex-col max-sm1:gap-[20px]">
        <span className="text-[#039674] font-bold text-[18px]">Gallery</span>

        <div className="flex items-center gap-[10px]">
          <button
            onClick={() => navigate("/gallery/create-gallery-category")}
            className="py-2 px-4 bg-[#039674] text-white flex items-center gap-[5px] rounded-[5px] max-lg2:py-1"
          >
            <FaPlus className="text-[14px] max-lg2:text-[12px]" />
            <span className="text-[14px] max-lg2:text-[12px]">
              Add category
            </span>
          </button>
          <button
            onClick={() => navigate("/gallery/edit-gategory")}
            className="py-2 px-4 bg-[#039674] text-white flex items-center gap-[5px] rounded-[5px] max-lg2:py-1"
          >
            <MdEdit className="text-[14px] max-lg2:text-[12px]" />
            <span className="text-[14px] max-lg2:text-[12px]">
              Edit category
            </span>
          </button>
          <button
            onClick={() => navigate("/gallery/create-gallery")}
            className="py-2 px-4 bg-[#039674] text-white flex items-center gap-[5px] rounded-[5px] max-lg2:py-1"
          >
            <FaPlus className="text-[14px] max-lg2:text-[12px]" />
            <span className="text-[14px] max-lg2:text-[12px]">Upload</span>
          </button>
        </div>
      </div>
      <div className="w-[100%] my-[20px] mx-auto h-[80px]  border-b border-gray-300 flex justify-center items-center overflow-x-auto hide-scroll-bar">
        <div className="mx-auto flex justify-between items-center max-md2:w-[95%]">
          {categoryState?.categories && (
            <div className="flex justify-center items-center  max-md2:gap-[50px] text-[14px] text-gray-500">
              {categoryState?.categories?.map((category, index) => {
                return (
                  <button
                    key={index}
                    className={
                      categoryState?.category === category?._id
                        ? "font-bold py-2 px-4 bg-[#039674] rounded-[20px] text-white max-md1:text-[12px] whitespace-nowrap"
                        : "py-2 px-4 max-md1:text-[12px] whitespace-nowrap"
                    }
                    onClick={() =>
                      dispatch(categoryAction.setCategory(category?._id))
                    }
                  >
                    {category?.name_en}
                  </button>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <GalleryList />
    </div>
  );
}

export default Gallery;
