import { useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { apiRequest } from "../../utils/request";
import ServerError from "../../pages/ServerError";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../Loading";

function EditEvents() {
  const location = useLocation();
  const { event } = location.state;
  const navigate = useNavigate();
  const token = sessionStorage.getItem("tID");
  const { id } = useParams();
  const [titleEn, setTitleEn] = useState(event ? event?.title_en : "");
  const [titleAmh, setTitleAmh] = useState(event ? event?.title_am : "");
  const [descriptionEn, setDescriptionEn] = useState(
    event ? event?.description_en : ""
  );
  const [descriptionAmh, setDescriptionAmh] = useState(
    event ? event?.description_am : ""
  );
  const [thumbnail, setThumbnail] = useState(null);
  const [imageOne, setImageOne] = useState(null);
  const [imageTwo, setImageTwo] = useState(null);
  const [imageThree, setImageThree] = useState(null);
  const [videoURL, setVideoURL] = useState(event ? event?.video_url : "");
  const [status, setStatus] = useState("");
  const [editFiled, setEditField] = useState("");
  const [loading, setLoading] = useState(false);
  const [serverError, setServerError] = useState(false);

  const handleEditEvent = async () => {
    try {
      const eventData = new FormData();

      eventData.append("title_en", titleEn);
      eventData.append("title_am", titleAmh);
      eventData.append("description_en", descriptionEn);
      eventData.append("description_am", descriptionAmh);
      eventData.append("video_url", videoURL);
      eventData.append("thumbnail", thumbnail);
      eventData.append("image_one", imageOne);
      eventData.append("image_two", imageTwo);
      eventData.append("image_three", imageThree);
      eventData.append("status", status);

      setLoading(true);

      await apiRequest
        .put(
          `${process.env.REACT_APP_BACKEND_URL}/event_api/update_event/${id}`,
          eventData,
          {
            headers: {
              get_update_event_api: process.env.REACT_APP_GET_UPDATE_EVENT_API,
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then(() => {
          setLoading(false);
          window.location.href = "/events";
        })
        .catch((error) => {
          if (error.response.status === 401) {
            setLoading(true);
            sessionStorage.clear();
            window.location.href = "/login";
          }
          setLoading(false);
          toast.error(error.response.data.Message);
        });
    } catch (error) {
      setServerError(true);
    }
  };

  if (serverError) return <ServerError />;
  return (
    <div className="w-[90%] my-[20px] mx-auto p-2">
      <ToastContainer theme="light" />
      <div className="w-[100%]  p-2">
        <div className="w-[100%] p-2 mx-auto bg-white shadow-lg rounded-[20px] sm:w-[95%]">
          <div className="w-[90%] mt-[50px] mx-auto flex justify-between items-center">
            <div>
              <span className="text-[20px] text-[#039674] font-bold">
                Update Event
              </span>
            </div>

            {loading ? (
              <Loading
                additionalStyle={"flex justify-center items-center my-[20px]"}
              />
            ) : (
              <div className="flex items-center gap-[20px] justify-end">
                <button className="text-[14px]" onClick={() => navigate(-1)}>
                  Cancel
                </button>
                <button
                  onClick={handleEditEvent}
                  className="bg-[#039674] text-white py-1 px-4 text-[14px] rounded-[5px]"
                >
                  Update
                </button>
              </div>
            )}
          </div>
          <div>
            <div className="w-[100%] mx-auto p-2 min-h-[70vh]  rounded-[10px]">
              <div className="w-[95%] flex flex-col justify-between h-[100%]  mx-auto">
                <div className="w-[50%] mt-[30px]  flex flex-col gap-[10px] max-md2:w-[100%]">
                  <span className="font-bold text-[#039674]">
                    Which field do you want update?
                  </span>
                  <select
                    onChange={(e) => setEditField(e.target.value)}
                    className="py-3 px-4 border bg-transparent border-gray-400 rounded-[5px] text-[14px] outline-none"
                  >
                    <option>Select field to edit</option>
                    <option>English title</option>
                    <option>Amharic title</option>
                    <option>English description</option>
                    <option>Amharic description</option>
                    <option>Youtube Video URL</option>
                    <option>Thumbnail</option>
                    <option>Image One</option>
                    <option>Image Two</option>
                    <option>Image Three</option>
                    <option>Status</option>
                  </select>
                </div>
                {editFiled === "English title" && (
                  <div className="w-[50%] my-[30px]  flex flex-col gap-[10px] max-md2:w-[100%]">
                    <span className="font-bold text-[#039674]">
                      English title
                    </span>
                    <input
                      value={titleEn}
                      onChange={(e) => setTitleEn(e.target.value)}
                      type="text"
                      className="py-3 px-4 border border-gray-400 rounded-[5px]  text-[14px] outline-none"
                    />
                  </div>
                )}
                {editFiled === "Amharic title" && (
                  <div className="w-[50%] my-[30px]  flex flex-col gap-[10px] max-md2:w-[100%]">
                    <span className="font-bold text-[#039674]">
                      Amharic title
                    </span>
                    <input
                      value={titleAmh}
                      onChange={(e) => setTitleAmh(e.target.value)}
                      type="text"
                      className="py-3 px-4 border border-gray-400 rounded-[5px]  text-[14px] outline-none"
                    />
                  </div>
                )}
                {editFiled === "English description" && (
                  <div className="w-[50%] my-[30px]  flex flex-col gap-[10px] max-md2:w-[100%]">
                    <span className="font-bold text-[#039674]">
                      English description
                    </span>
                    <textarea
                      value={descriptionEn}
                      onChange={(e) => setDescriptionEn(e.target.value)}
                      className="py-3 px-4 border border-gray-400 rounded-[5px]  text-[14px] outline-none resize-none"
                      rows={10}
                    ></textarea>
                  </div>
                )}
                {editFiled === "Amharic description" && (
                  <div className="w-[50%]  my-[30px]  flex flex-col gap-[10px] max-md2:w-[100%]">
                    <span className="font-bold text-[#039674]">
                      Amharic description
                    </span>
                    <textarea
                      value={descriptionAmh}
                      onChange={(e) => setDescriptionAmh(e.target.value)}
                      className="py-3 px-4 border border-gray-400 rounded-[5px]  text-[14px] outline-none resize-none"
                      rows={10}
                    ></textarea>
                  </div>
                )}
                {editFiled === "Youtube Video URL" && (
                  <div className="w-[50%]  my-[30px]  flex flex-col gap-[10px] max-md2:w-[100%]">
                    <span className="font-bold text-[#039674]">
                      Insert Youtube Video URL
                    </span>
                    <input
                      value={videoURL}
                      onChange={(e) => setVideoURL(e.target.value)}
                      type="email"
                      className="py-3 px-4 border border-gray-400 rounded-[5px]  text-[14px] outline-none"
                    />
                  </div>
                )}

                {editFiled === "Thumbnail" && (
                  <div className="w-[100%] my-[30px]  mx-auto flex justify-between items-center gap-[20px] max-md2:flex-col">
                    <div className="w-[50%] mx-auto flex flex-col gap-[10px] max-md2:w-[100%]">
                      <span className="font-bold text-[14px] text-[#039674]">
                        Thumbnail
                      </span>
                      <label>
                        <input
                          type="file"
                          accept="image/png, image/jpeg"
                          onChange={(e) => setThumbnail(e.target.files[0])}
                          hidden
                        />
                        <div className="w-[100%] min-h-[300px] flex flex-col justify-center gap-[10px] text-[#039674]  items-center p-4 mx-auto border border-gray-400 rounded border-dashed cursor-pointer text-center">
                          <div className="flex items-center gap-[10px]">
                            <span className="text-[14px] font-bold ">
                              Upload File
                            </span>
                          </div>

                          <span className="w-[80%] text-[12px] max-lg:w-[70%]">
                            Please ensure images are of high resolution in
                            format jpg, or png. Only upload media that you have
                            the legal rights to.
                          </span>
                          <span className="w-[50%] text-[12px] max-lg:w-[70%]">
                            File size max <b>2MB</b>
                          </span>
                        </div>
                      </label>
                    </div>
                    <div className="w-[50%] mt-[20px] mx-auto flex gap-[20px] max-md2:w-[100%]">
                      {thumbnail && (
                        <img
                          className="w-[100%] h-[300px] object-cover"
                          src={URL.createObjectURL(thumbnail)}
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                )}

                {editFiled === "Image One" && (
                  <div className="w-[100%] my-[30px]  mx-auto flex justify-between items-center gap-[20px] max-md2:flex-col">
                    <div className="w-[50%] mx-auto flex flex-col gap-[10px] max-md2:w-[100%]">
                      <span className="font-bold text-[14px] text-[#039674]">
                        Image One
                      </span>
                      <label>
                        <input
                          type="file"
                          accept="image/png, image/jpeg"
                          onChange={(e) => setImageOne(e.target.files[0])}
                          hidden
                        />
                        <div className="w-[100%] min-h-[300px] flex flex-col justify-center gap-[10px] text-[#039674]  items-center p-4 mx-auto border border-gray-400 rounded border-dashed cursor-pointer text-center">
                          <div className="flex items-center gap-[10px]">
                            <span className="text-[14px] font-bold ">
                              Upload File
                            </span>
                          </div>

                          <span className="w-[80%] text-[12px] max-lg:w-[70%]">
                            Please ensure images are of high resolution in
                            format jpg, or png. Only upload media that you have
                            the legal rights to.
                          </span>
                          <span className="w-[50%] text-[12px] max-lg:w-[70%]">
                            File size max <b>2MB</b>
                          </span>
                        </div>
                      </label>
                    </div>
                    <div className="w-[50%] mt-[20px] mx-auto flex gap-[20px] max-md2:w-[100%]">
                      {imageOne && (
                        <img
                          className="w-[100%] h-[300px] object-cover"
                          src={URL.createObjectURL(imageOne)}
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                )}

                {editFiled === "Image Two" && (
                  <div className="w-[100%] my-[30px]  mx-auto flex justify-between items-center gap-[20px] max-md2:flex-col">
                    <div className="w-[50%] mx-auto flex flex-col gap-[10px] max-md2:w-[100%]">
                      <span className="font-bold text-[14px] text-[#039674]">
                        Image Two
                      </span>
                      <label>
                        <input
                          type="file"
                          accept="image/png, image/jpeg"
                          onChange={(e) => setImageTwo(e.target.files[0])}
                          hidden
                        />
                        <div className="w-[100%] min-h-[300px] flex flex-col justify-center gap-[10px] text-[#039674]  items-center p-4 mx-auto border border-gray-400 rounded border-dashed cursor-pointer text-center">
                          <div className="flex items-center gap-[10px]">
                            <span className="text-[14px] font-bold ">
                              Upload File
                            </span>
                          </div>

                          <span className="w-[80%] text-[12px] max-lg:w-[70%]">
                            Please ensure images are of high resolution in
                            format jpg, or png. Only upload media that you have
                            the legal rights to.
                          </span>
                          <span className="w-[50%] text-[12px] max-lg:w-[70%]">
                            File size max <b>2MB</b>
                          </span>
                        </div>
                      </label>
                    </div>
                    <div className="w-[50%] mt-[20px] mx-auto flex gap-[20px] max-md2:w-[100%]">
                      {imageTwo && (
                        <img
                          className="w-[100%] h-[300px] object-cover"
                          src={URL.createObjectURL(imageTwo)}
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                )}

                {editFiled === "Image Three" && (
                  <div className="w-[100%] my-[30px]  mx-auto flex justify-between items-center gap-[20px] max-md2:flex-col">
                    <div className="w-[50%] mx-auto flex flex-col gap-[10px] max-md2:w-[100%]">
                      <span className="font-bold text-[14px] text-[#039674]">
                        Image Three
                      </span>
                      <label>
                        <input
                          type="file"
                          accept="image/png, image/jpeg"
                          onChange={(e) => setImageThree(e.target.files[0])}
                          hidden
                        />
                        <div className="w-[100%] min-h-[300px] flex flex-col justify-center gap-[10px] text-[#039674]  items-center p-4 mx-auto border border-gray-400 rounded border-dashed cursor-pointer text-center">
                          <div className="flex items-center gap-[10px]">
                            <span className="text-[14px] font-bold ">
                              Upload File
                            </span>
                          </div>

                          <span className="w-[80%] text-[12px] max-lg:w-[70%]">
                            Please ensure images are of high resolution in
                            format jpg, or png. Only upload media that you have
                            the legal rights to.
                          </span>
                          <span className="w-[50%] text-[12px] max-lg:w-[70%]">
                            File size max <b>2MB</b>
                          </span>
                        </div>
                      </label>
                    </div>
                    <div className="w-[50%] mt-[20px] mx-auto flex gap-[20px] max-md2:w-[100%]">
                      {imageThree && (
                        <img
                          className="w-[100%] h-[300px] object-cover"
                          src={URL.createObjectURL(imageThree)}
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                )}

                {editFiled === "Status" && (
                  <div className="w-[50%]  my-[30px] mt-[30px]  flex flex-col gap-[10px] max-md2:w-[100%]">
                    <span className="font-bold text-[#039674]">Status</span>
                    <select
                      onChange={(e) => setStatus(e.target.value)}
                      className="py-3 px-4 border bg-transparent border-gray-400 rounded-[5px]  text-[14px] outline-none"
                    >
                      <option>Change status</option>
                      <option>active</option>
                      <option>inactive</option>
                    </select>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditEvents;
